import React from 'react';
import PropTypes from 'prop-types';
import Input from './Inputs/Input';
import moment from 'moment';

class CapacityForInsured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.from,
      until: props.until,
    };
  }

  handleChangeLegally(date) {
    if (this.props.onChange) {
      this.props.onChange({
        from: date,
        until: this.state.until,
      });
    }
    this.setState({ from: date });
  }

  handleChangePrivate(date) {
    if (this.props.onChange) {
      this.props.onChange({
        from: this.state.from,
        until: date,
      });
    }
    this.setState({ until: date });
  }

  render() {
    return (
      <div name={this.props.name} className="CapacityForInsured">
        <div className="CapacityForInsured-label">{this.props.label}</div>
        <div className="CI-Input">
          <Input
            type="date"
            name="from"
            label="Von"
            defaultValue={moment(this.state.from, 'DD.MM.YYYY')?.format(
              'YYYY-MM-DD',
            )}
            onChange={(value) => {
              this.handleChangeLegally(moment(value)?.format('DD.MM.YYYY'));
            }}
            isDisabled={this.props.isDisabled}
          />
          <Input
            type="date"
            name="until"
            label="Bis:"
            defaultValue={moment(this.state.until, 'DD.MM.YYYY')?.format(
              'YYYY-MM-DD',
            )}
            onChange={(value) => {
              this.handleChangePrivate(moment(value)?.format('DD.MM.YYYY'));
            }}
            isDisabled={this.props.isDisabled}
          />
        </div>
      </div>
    );
  }
}

CapacityForInsured.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  from: PropTypes.string,
  until: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CapacityForInsured.defaultProps = {
  name: '',
  label: '',
  from: '',
  until: '',
  isDisabled: false,
  onChange: null,
};

export default CapacityForInsured;
