import React from 'react';
import { Redirect } from 'react-router-dom';

import reqwest from 'reqwest';

import settings from '../../settings';
import LoginForm from '../components/LoginForm';

const LoginRoute = () => {
  if (localStorage.getItem('token')) {
    reqwest({
      method: 'get',
      url: settings.general.checkToken,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res === true) {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }
      localStorage.clear();
    });
  }
  return <LoginForm />;
};

export default LoginRoute;
