import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import SelectAmbulatoryPsychotherapyOverview from './SelectAmbulatoryPsychotherapyOverview';
import SelectAmbulatoryPsychotherapyDetail from './SelectAmbulatoryPsychotherapyDetail';
// import SelectClinicsOverview from './SelectClinicsOverview';
// import SelectClinicsDetail from './SelectClinicsDetail';
import Print from './Print';
import { AmbPsychFilterProvider } from '../../../../../hooks/AmbPsychFilterContext';

const SelectCounselling = (props) => {
  return (
    <AmbPsychFilterProvider>
      <Switch>
        <Route
          path={`${props.match.path}/SelectAmbulatoryPsychotherapyOverview/:clientGuid/:clientName?`}
          component={SelectAmbulatoryPsychotherapyOverview}
        />
        <Route
          path={`${props.match.path}/SelectAmbulatoryPsychotherapyDetail/:guid/:clientGuid/:psychName?/:clientName?`}
          component={SelectAmbulatoryPsychotherapyDetail}
        />
        {/* Not needed for now:
      <Route
        path={`${props.match.path}/SelectClinicsOverview/:clientGuid`}
        component={SelectClinicsOverview}
      />
      <Route
        path={`${props.match.path}/SelectClinicsDetail/:guid/:clientGuid`}
        component={SelectClinicsDetail}
      />
      */}
        <Route
          path={`${props.match.path}/print/:clientGuid/:type/:guid/:psychName?/:clientName?`}
          component={Print}
        />
      </Switch>
    </AmbPsychFilterProvider>
  );
};

export default withRouter(SelectCounselling);
