import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Inputs/Dropdown';
import getConstants from '../logic/constants';

class DisordersMainFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainFocus: props.mainFocus,
      Constants: [],
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      Constants: await getConstants('Disorders'),
    });
  }

  handleChangeMainFocus(values = []) {
    if (this.props.onChange) {
      this.props.onChange({
        mainFocus: values,
        exclusion: this.state.exclusion,
      });
    }
    this.setState({ mainFocus: values });
  }

  render() {
    return (
      <Dropdown
        name={this.props.name}
        label={this.props.label}
        info={this.props.infoFocus}
        fullWidth
        isMulti
        isClearable
        isSearchable
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.mainFocus}
        options={this.state.Constants}
        getSelected={(values) => {
          this.handleChangeMainFocus(values);
        }}
      />
    );
  }
}

DisordersMainFocus.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  infoFocus: PropTypes.string,
  mainFocus: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

DisordersMainFocus.defaultProps = {
  name: '',
  label: '',
  infoFocus: '',
  mainFocus: [],
  isDisabled: false,
  onChange: null,
};

export default DisordersMainFocus;
