import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataContainer from '../../../logic/dataContainer';
import Form from '../../../components/Form';
import ContentBox from '../../../components/FormArea/ContentBox';
import Dropdown from '../../../components/Inputs/Dropdown';
import AvailabilityChecker from '../../../components/AvailabilityChecker';
import CapacityForInsured from '../../../components/CapacityForInsured';
import OfficeHours from '../../../components/OfficeHours';
import Switch from '../../../components/Inputs/Switch';
import Input from '../../../components/Inputs/Input';
import Treatments from '../../../components/Treatments';
import Disorders from '../../../components/Disorders';
import getConstants from '../../../logic/constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import AddressMapAutofill from '../../../components/AddressMapAutofill';

const newDataContainer = () => {
  const dataContainer = new DataContainer();
  dataContainer.add('AmbulantPsychotherapy', {
    Gender: '',
    Title: '',
    TitleComment: '',
    Firstname: '',
    Lastname: '',
    Praxisname: '',
    BirthYear: '',
    JobTitle: '',
    Comment: '',
    MailToCare: '',
    MailToPatient: '',
    AbsentFrom: '',
    LastTherapyRequest: '',
    LastTherapyRequestBy: '',
    AbsentUntil: '',
    AbsentReason: '',
    AbsentEditedBy: '',
    AvailableSlots: '000000000000000000',
    AvailabilityTimes: '',
    OfficeHours: '',
    AmountAvailable: '',
    AmountAvailableEditedBy: '',
    AmountAvailableEditedDate: '',
    BlockLegallyInsuredFrom: '',
    BlockLegallyInsuredUntil: '',
    BlockLegallyInsuredEditedBy: '',
    BlockPrivateInsuredFrom: '',
    BlockPrivateInsuredUntil: '',
    BlockPrivateInsuredEditedBy: '',
    TherapyProcedures: '',
    PossibleGroupTherapyOffers: '',
    ApprovalForGroupPsychotherapy: false,
    OfferVideoTherapy: false,
    ExclusionCriteria: '',
    Treatments: [],
    DisordersMainFocus: [],
    DisordersExclusion: [],
  });

  dataContainer.add('FirstOffice', {
    Street: '',
    ZIP: '',
    City: '',
    Lat: '',
    Lon: '',
    ZipLat: '',
    ZipLon: '',
    PhoneNumber1: '',
    PhoneNumber2: '',
    Website: '',
    BarrierFreeAccess: false,
    ParkingSpaces: false,
    LPTWalkingDistance: false,
    InfoToReachbility: '',
    CashRegisterApproval: '',
    ReimbursementProcedure: '',
    BillingWithIncreasedRateOfIncrease: '',
    SettlementWithIncreasedRateOfIncrease: '',
  });

  dataContainer.add('SecondOffice', {
    Street: '',
    ZIP: '',
    City: '',
    Lat: '',
    Lon: '',
    ZipLat: '',
    ZipLon: '',
    PhoneNumber3: '',
    PhoneNumber4: '',
    Website: '',
    BarrierFreeAccess: false,
    ParkingSpaces: false,
    LPTWalkingDistance: false,
    InfoToReachbility: '',
    CashRegisterApproval: '',
    ReimbursementProcedure: '',
    AdditionalComment: '',
  });

  dataContainer.add('TransferDb', {
    TitleExtend: '',
    Consent: false,
    Status: '',
    Review: '',
    Date: '',
  });
  return dataContainer;
};
const convertBooleanToString = (booleanString) => {
  return booleanString === true ? 'true' : 'false';
};

class AmbPsychForm extends Component {
  dataContainer;
  constructor(props) {
    super(props);

    this.userdata = JSON.parse(localStorage.getItem('userdata'));

    this.state = {
      Salutations: [],
      Genders: [],
      Titles: [],
      JobTitles: [],
      Disorders: [],
      TherapyProcedures: [],
      PreferredContactsCare: [],
      PreferredContactsClient: [],
      CashRegisterApprovals: [],
      ReimbursementProcedures: [],
      SettlementWithIncreasedRateOfIncreases: [],
      Users: [],
      isAbsent: false,
      showConsent: false,
    };
    this.dataContainer = props.dataContainer
      ? props.dataContainer
      : newDataContainer();
    this.currentDate = new Date().toJSON().slice(0, 10);
  }
  componentDidMount() {
    this.initConstants();
    this.checkAbsent();
  }

  async getConstantsPreferredContactCare() {
    return [
      {
        value: 'emailCARE',
        label: 'E-Mail-Adresse für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: 'phoneNumber1',
        label: 'Telefonnummer für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: 'emailCareOrPhoneNumber1',
        label: 'E-Mail-Adresse/Telefonnummer für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: '',
        label: 'Keine Auswahl',
        kkz: '',
      },
    ];
  }

  async getConstantsPreferredContactClient() {
    return [
      {
        value: 'emailClient',
        label: 'E-Mail-Adresse für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: 'phoneNumber2',
        label: 'Telefonnummer für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: 'emailClientAndPhoneNumber2',
        label: 'E-Mail-Adresse/Telefonnummer für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: '',
        label: 'Keine Auswahl',
        kkz: '',
      },
    ];
  }

  async getReimbursementProcedures() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  async getOption() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  async initConstants() {
    this.setState({
      Salutations: await getConstants('Salutation'),
      Genders: await getConstants('Gender'),
      Titles: await getConstants('Title'),
      JobTitles: await getConstants('JobTitle'),
      Disorders: await getConstants('Disorder'),
      TherapyProcedures: await getConstants('TherapyProcedures'),
      PreferredContactsCare: await this.getConstantsPreferredContactCare(),
      PreferredContactsClient: await this.getConstantsPreferredContactClient(),
      CashRegisterApprovals: await getConstants('CashRegisterApproval'),
      ReimbursementProcedures: await this.getReimbursementProcedures(),
      SettlementWithIncreasedRateOfIncreases: await this.getOption(),
      Users: await getConstants('CarePsych'),
    });
  }
  // updateZipCoordinates = async (
  //   value,
  //   /** 'FirstOffice' or 'SecondOffice' */
  //   office,
  // ) => {
  //   if (!value || value.length < 4) return;
  //   return await reqwest({
  //     method: 'GET',
  //     url: settings.supplynetwork.getCoordsToZip,
  //     data: {
  //       Token: localStorage.getItem('token'),
  //       zip: value,
  //     },
  //   }).then((result) => {
  //     if (result === 'No Data') {
  //       this.dataContainer.set(office, 'ZipLat', '');
  //       this.dataContainer.set(office, 'ZipLon', '');
  //       return;
  //     }
  //     result = [JSON.parse(result)];
  //     const coords = new DataContainer(result);
  //     if (coords) {
  //       this.dataContainer.set(
  //         office,
  //         'ZipLat',
  //       )(coords.get('Coordinates', 'ZipLat'));
  //       this.dataContainer.set(
  //         office,
  //         'ZipLon',
  //       )(coords.get('Coordinates', 'ZipLon'));
  //     } else {
  //       // This will set ZipLat and ZipLon to '', effectively clearing their values in the dataContainer.
  //       this.dataContainer.set(office, 'ZipLat', '');
  //       this.dataContainer.set(office, 'ZipLon', '');
  //     }
  //   });
  // };

  validate = async () => {
    return this.form.validate();
  };

  checkAbsent = () => {
    const from = this.dataContainer.get('AmbulantPsychotherapy', 'AbsentFrom');
    const until = this.dataContainer.get(
      'AmbulantPsychotherapy',
      'AbsentUntil',
    );
    const fromMoment = moment(from, 'DD.MM.YYYY');
    fromMoment.subtract(1, 'day');
    const untilMoment = moment(until, 'DD.MM.YYYY');
    untilMoment.add(1, 'day');
    const absent = moment().isBetween(fromMoment, untilMoment, 'day');
    this.setState({ isAbsent: absent });
  };

  render() {
    return (
      <Form
        ref={(node) => {
          return (this.form = node);
        }}
      >
        <br />
        <ContentBox title="Laufende Therapieplatzanfragen">
          <Input
            type="date"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'LastTherapyRequest',
              )(moment(value)?.format('DD.MM.YYYY'));
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'LastTherapyRequestBy',
              )(this.userdata.userGuid);
            }}
            defaultValue={moment(
              this.dataContainer.get(
                'AmbulantPsychotherapy',
                'LastTherapyRequest',
              ),
              'DD.MM.YYYY',
            )?.format('YYYY-MM-DD')}
            label="Letzte Therapieanfrage:"
            isDisabled={this.props.isDisabled}
            name="LastTherapyRequest"
          />
          <Dropdown
            type="text"
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'LastTherapyRequestBy',
            )}
            options={this.state.Users}
            label="Anfragende Person:"
            placeholder="wird automatisch eingetragen"
            isDisabled
            name="LastTherapyRequestBy"
          />
        </ContentBox>
        <ContentBox>
          {/* KEINE Aufnahmekapazitäten Warnung */}
          {this.dataContainer.get(
            'AmbulantPsychotherapy',
            'BlockLegallyInsuredUntil',
          ) &&
            moment(
              this.dataContainer.get(
                'AmbulantPsychotherapy',
                'BlockLegallyInsuredUntil',
              ),
              'DD.MM.YYYY',
            ).isAfter(moment(this.currentDate, 'YYYY-MM-DD')) && (
              <div className="Absent-Info">
                <div className="Label">
                  Keine Aufnahmekapazitäten für gesetzlich Versicherte bis
                  &nbsp;
                  {this.dataContainer.get(
                    'AmbulantPsychotherapy',
                    'BlockLegallyInsuredUntil',
                  )}
                  <FontAwesomeIcon
                    className="Icon"
                    icon={faExclamationCircle}
                  />
                </div>
              </div>
            )}

          {this.dataContainer.get(
            'AmbulantPsychotherapy',
            'BlockPrivateInsuredUntil',
          ) &&
            moment(
              this.dataContainer.get(
                'AmbulantPsychotherapy',
                'BlockPrivateInsuredUntil',
              ),
              'DD.MM.YYYY',
            ).isAfter(moment(this.currentDate, 'YYYY-MM-DD')) && (
              <div className="Absent-Info">
                <div className="Label">
                  Keine Aufnahmekapazitäten für privat Versicherte bis &nbsp;
                  {this.dataContainer.get(
                    'AmbulantPsychotherapy',
                    'BlockPrivateInsuredUntil',
                  )}
                  <FontAwesomeIcon
                    className="Icon"
                    icon={faExclamationCircle}
                  />
                </div>
              </div>
            )}

          {/* Therapeut/in abwesend Warnung */}
          {this.state.isAbsent && (
            <div className="Absent-Info">
              <div className="Label">
                Therapeut/in abwesend
                <FontAwesomeIcon className="Icon" icon={faExclamationCircle} />
              </div>
            </div>
          )}

          {/* Abrechnung mit erhöhtem Steigerungssatz  Warnung */}
          {this.dataContainer.get(
            'FirstOffice',
            'SettlementWithIncreasedRateOfIncrease',
          ) === 'yes' && (
            <div className="Absent-Info">
              <div className="Label">
                Abrechnung mit erhöhtem Steigerungssatz
                <FontAwesomeIcon className="Icon" icon={faExclamationCircle} />
              </div>
            </div>
          )}

          <p className="FormArea-ContentBox-title">Stammdaten</p>
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'Praxisname',
              )(value);
            }}
            defaultValue={
              this.dataContainer.get('AmbulantPsychotherapy', 'Praxisname')
                ? this.dataContainer.get('AmbulantPsychotherapy', 'Praxisname')
                : 'Psychotherapiepraxis'
            }
            label="Name der Einrichtung:"
            isDisabled={this.props.isDisabled}
            name="Praxisname"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Input
            type="text"
            validator={[
              {
                required: true,
                error: 'Pflichtfeld ist nicht ausgefüllt.',
              },
            ]}
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'Firstname',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Firstname',
            )}
            label="Vorname:"
            isDisabled={this.props.isDisabled}
            name="Firstname"
          />
          <Input
            type="text"
            validator={[
              {
                required: true,
                error: 'Pflichtfeld ist nicht ausgefüllt.',
              },
            ]}
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'Lastname',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Lastname',
            )}
            label="Nachname:"
            isDisabled={this.props.isDisabled}
            name="Lastname"
          />
          <Dropdown
            label="Titel:"
            getSelected={(value) => {
              this.dataContainer.set('AmbulantPsychotherapy', 'Title')(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Title',
            )}
            options={this.state.Titles}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="Title"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'TitleComment',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'TitleComment',
            )}
            label="Bemerkungen zum Titel"
            info="Falls die Titelauswahl ihre genaue Titelbezeichnung nicht abbildet, können Sie hier die Angabe ergänzen."
            isDisabled={this.props.isDisabled}
            name="TitleComment"
          />
          <Dropdown
            label="Berufsbezeichnung:"
            getSelected={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'JobTitle',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'JobTitle',
            )}
            options={this.state.JobTitles}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="JobTitle"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Dropdown
            label="Geburtsjahr:"
            info="Bitte geben Sie Ihr Geburtsjahr an. Dies erleichtert uns die Suche, wenn KlientInnen die Behandlung durch eine/n etwa gleichaltrige/n BehandlerIn wünschen."
            isDisabled={this.props.isDisabled}
            name="BirthYear"
            options={[
              { label: ' - ', value: '' },
              ...[...Array(100).keys()].map((v, i) => {
                const y = new Date().getFullYear() - v;
                return { label: y + ' (' + i + ')', value: '' + y };
              }),
            ]}
            getSelected={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BirthYear',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BirthYear',
            )}
          />
          <Dropdown
            label="Geschlecht:"
            getSelected={(value) => {
              this.dataContainer.set('AmbulantPsychotherapy', 'Gender')(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Gender',
            )}
            options={this.state.Genders}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="Gender"
          />
          <Input
            type="textarea"
            fullWidth
            onChange={(value) => {
              this.dataContainer.set('AmbulantPsychotherapy', 'Comment')(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Comment',
            )}
            label="Bemerkungen:"
            isDisabled={this.props.isDisabled}
            name="Comment"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('FirstOffice', 'PhoneNumber1')(value);
            }}
            defaultValue={this.dataContainer.get('FirstOffice', 'PhoneNumber1')}
            label="Telefonnummer für Kontakt mit CARE:"
            info="Über diese Telefonnummer nimmt das CARE-Team Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="PhoneNumber1"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('FirstOffice', 'PhoneNumber2')(value);
            }}
            defaultValue={this.dataContainer.get('FirstOffice', 'PhoneNumber2')}
            label="Telefonnummer für Kontakt mit KlientInnen:"
            info="Über diese Telefonnummer nehmen KlientInnen Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="PhoneNumber2"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'MailToCare',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'MailToCare',
            )}
            label="E-Mail-Adresse für Kontakt mit CARE:"
            info="Über diese E-Mail-Adresse nimmt das CARE-Team Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="MailToCare"
            mailTo="E-Mail senden"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'MailToPatient',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'MailToPatient',
            )}
            label="E-Mail-Adresse für KlientInnen:"
            info="Über diese E-Mail-Adresse nehmen KlientInnen Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="MailToPatient"
            mailTo="E-Mail senden"
          />
          <Dropdown
            label="Bevorzugte Art der Kontaktaufnahme für die CARE-Beratung:"
            info="Wählen Sie bitte aus, über welches Medium Sie bevorzugt kontaktiert werden möchten."
            getSelected={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'PreferredContactCare',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'PreferredContactCare',
            )}
            options={this.state.PreferredContactsCare}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="PreferredContactCare"
          />
          <Dropdown
            label="Bevorzugte Art der Kontaktaufnahme für KlientInnen:"
            info="Wählen Sie bitte aus, über welches Medium Sie bevorzugt kontaktiert werden möchten."
            getSelected={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'PreferredContactPatient',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'PreferredContactPatient',
            )}
            options={this.state.PreferredContactsClient}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="PreferredContactPatient"
          />
          <Input
            label="Internetauftritt:"
            type="text"
            onChange={(value) => {
              this.dataContainer.set('FirstOffice', 'Website')(value);
            }}
            defaultValue={this.dataContainer.get('FirstOffice', 'Website')}
            isDisabled={this.props.isDisabled}
            name="FirstOfficeWebsite"
            linkTo="Klicke, um die Internetadresse in einem neuen Fenster zu öffnen."
          />
        </ContentBox>
        <br />
        <ContentBox title="Fachliche Angaben">
          <Dropdown
            label="Therapieverfahren:"
            isMulti
            isClearable
            isSearchable
            getSelected={(values) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'TherapyProcedures',
              )(values || []);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'TherapyProcedures',
            )}
            options={this.state.TherapyProcedures}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="TherapyProcedures"
          />
          <Switch
            label="Zulassung für Gruppenpsychotherapie:"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'ApprovalForGroupPsychotherapy',
              )(value);
            }}
            isDisabled={this.props.isDisabled}
            defaultChecked={
              !!this.dataContainer.get(
                'AmbulantPsychotherapy',
                'ApprovalForGroupPsychotherapy',
              )
            }
            labelActive="Ja"
            labelInactive="Nein"
            validator={[]}
            name="ApprovalForGroupPsychotherapy"
          />
          <div>
            <Switch
              label="Angebot von Videotherapie über ein zertifiziertes System:"
              onChange={(value) => {
                this.dataContainer.set(
                  'AmbulantPsychotherapy',
                  'OfferVideoTherapy',
                )(value);
              }}
              isDisabled={this.props.isDisabled}
              defaultChecked={
                !!this.dataContainer.get(
                  'AmbulantPsychotherapy',
                  'OfferVideoTherapy',
                )
              }
              labelActive="Ja"
              labelInactive="Nein"
              validator={[]}
              name="OfferVideoTherapy"
            />
            <Switch
              label="An AGIL-Fortbildung teilgenommen:"
              onChange={(value) => {
                this.dataContainer.set(
                  'AmbulantPsychotherapy',
                  'AgilTraining',
                )(value);
              }}
              isDisabled={this.props.isDisabled}
              defaultChecked={
                !!this.dataContainer.get(
                  'AmbulantPsychotherapy',
                  'AgilTraining',
                )
              }
              labelActive="Ja"
              labelInactive="Nein"
              validator={[]}
              name="Schulung"
            />
          </div>
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'PossibleGroupTherapyOffers',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'PossibleGroupTherapyOffers',
            )}
            label="Ggf. Gruppentherapie-Angebote:"
            info="Bitte beschreiben Sie kurz, ob und ggfs. welche Gruppentherapieangebote Sie anbieten."
            isDisabled={this.props.isDisabled}
            name="PossibleGroupTherapyOffers"
          />
          <Dropdown
            label="Kassenzulassung für den Standort:"
            getSelected={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'CashRegisterApproval',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'FirstOffice',
              'CashRegisterApproval',
            )}
            options={this.state.CashRegisterApprovals}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="CashRegisterApproval"
          />
          <Dropdown
            label="Kostenerstattungsverfahren:"
            getSelected={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'ReimbursementProcedure',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'FirstOffice',
              'ReimbursementProcedure',
            )}
            options={this.state.ReimbursementProcedures}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="ReimbursementProcedure"
          />
          <Dropdown
            label="Abrechnung mit erhöhtem Steigerungssatz:"
            getSelected={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'SettlementWithIncreasedRateOfIncrease',
              )(value);
            }}
            defaultValue={(() => {
              const value = this.dataContainer.get(
                'FirstOffice',
                'SettlementWithIncreasedRateOfIncrease',
              );
              return value === '' || !value ? 'no' : value;
            })()}
            options={this.state.SettlementWithIncreasedRateOfIncreases}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="SettlementWithIncreasedRateOfIncrease"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'BillingWithIncreasedRateOfIncrease',
              )(value);
            }}
            defaultValue={(() => {
              const value = this.dataContainer.get(
                'FirstOffice',
                'BillingWithIncreasedRateOfIncrease',
              );
              return value === '' || !value ? 'Nein' : value;
            })()}
            label="Bei CARE bekannter Steigerungssatz:"
            isDisabled={this.props.isDisabled}
            name="BillingWithIncreasedRateOfIncrease"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <p className="FormArea-ContentBox-title">
            Fachliche und methodische Schwerpunkte:
          </p>
          <Treatments
            isDisabled={this.props.isDisabled}
            name="Treatments"
            label="Behandlungsschwerpunkte in der praktischen Arbeit:"
            info="Bitte wählen Sie die auf Sie zutreffenden Behandlungsschwerpunkte aus. Mehrfachauswahl ist möglich."
            values={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'Treatments',
            )}
            onChange={(values) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'Treatments',
              )(values);
            }}
          />
          <Disorders
            isDisabled={this.props.isDisabled}
            name="Disorders"
            infoFocus="Bitte wählen Sie aus, mit welchen Störungsbildern Sie Erfahrungen haben. Mehrfachauswahl ist möglich."
            infoExclusion="Bitte wählen Sie aus, welche Störungsbilder Sie ausschließen möchten. Mehrfachauswahl ist möglich."
            label="Erfahrungen mit bestimmten Störungsbilder:"
            mainFocus={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'DisordersMainFocus',
            )}
            exclusion={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'DisordersExclusion',
            )}
            onChange={(values) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'DisordersMainFocus',
              )(values.mainFocus);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'DisordersExclusion',
              )(values.exclusion);
            }}
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'ExclusionCriteria',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'ExclusionCriteria',
            )}
            label="Persönliche Ausschlusskriterien:"
            info="Geben Sie bitte an, ob und ggfs. welche persönlichen Ausschlussgründe Sie haben. Bsp.: Keine Lehrkräfte von Schule X, da ihr Kind diese Schule besucht."
            isDisabled={this.props.isDisabled}
            name="ExclusionCriteria"
            fullWidth
          />
        </ContentBox>
        <br />
        <ContentBox title="Angaben zu den Therapiezeiten">
          <Input
            type="date"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentFrom',
              )(moment(value)?.format('DD.MM.YYYY'));
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentEditedBy',
              )(this.userdata.userGuid);
              this.checkAbsent();
            }}
            defaultValue={moment(
              this.dataContainer.get('AmbulantPsychotherapy', 'AbsentFrom'),
              'DD.MM.YYYY',
            )?.format('YYYY-MM-DD')}
            label="Therapeut/in abwesend von:"
            isDisabled={this.props.isDisabled}
            name="AbsentFrom"
          />
          <Input
            type="date"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentUntil',
              )(moment(value)?.format('DD.MM.YYYY'));
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentEditedBy',
              )(this.userdata.userGuid);
              this.checkAbsent();
            }}
            defaultValue={moment(
              this.dataContainer.get('AmbulantPsychotherapy', 'AbsentUntil'),
              'DD.MM.YYYY',
            )?.format('YYYY-MM-DD')}
            label="bis voraussichtlich:"
            isDisabled={this.props.isDisabled}
            name="AbsentUntil"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentReason',
              )(value);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AbsentEditedBy',
              )(this.userdata.userGuid);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AbsentReason',
            )}
            label="Grund der Abwesenheit (optional):"
            name="AbsentReason"
            isDisabled={this.props.isDisabled}
          />
          <Dropdown
            type="text"
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AbsentEditedBy',
            )}
            options={this.state.Users}
            label="Erfasst durch:"
            placeholder="wird automatisch eingetragen"
            isDisabled
            name="AbsentEditedBy"
          />
          <div className="Input-wrapper spaceer-dummy"></div>

          <p className="FormArea-ContentBox-title">
            Generelle Arbeits- und Erreichbarkeitszeiten:
          </p>
          <AvailabilityChecker
            isDisabled={this.props.isDisabled}
            name="AvailableSlots"
            label="Übersicht der angebotenen Therapiezeiten:"
            info="Bitte markieren Sie die Zeiträume, in denen Sie regelmäßig Behandlungen anbieten.   "
            availableSlots={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AvailableSlots',
            )}
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AvailableSlots',
              )(value.slots);
            }}
          />
          <OfficeHours
            isDisabled={this.props.isDisabled}
            name="OfficeHours"
            label="Telefonische Sprechzeiten:"
            info="Hier können die kassenärztlichen Sprechstunden eingetragen werden, die wir an KlientInnen weitergeben. "
            officeHours={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'OfficeHours',
            )}
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'OfficeHours',
              )(value);
            }}
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AvailabilityTimes',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AvailabilityTimes',
            )}
            label="Anmerkungen zu Arbeits- und Erreichbarkeitszeiten:"
            isDisabled={this.props.isDisabled}
            name="AvailabilityTimes"
            fullWidth
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <p className="FormArea-ContentBox-title">
            Verfügbare Therapieplätze:
          </p>
          <Input
            type="number"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AmountAvailable',
              )(value);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AmountAvailableEditedBy',
              )(this.userdata.userGuid);
            }}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AmountAvailable',
            )}
            label="Anzahl verfügbarer Therapieplätze:"
            isDisabled={this.props.isDisabled}
            name="AmountAvailable"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Dropdown
            type="text"
            options={this.state.Users}
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'AmountAvailableEditedBy',
            )}
            label="Erfasst durch:"
            placeholder="wird automatisch eingetragen"
            name="AmountAvailableEditedBy"
            isDisabled
          />
          <Input
            type="date"
            onChange={(value) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'AmountAvailableEditedDate',
              )(moment(value)?.format('DD-MM.YYYY'));
            }}
            defaultValue={
              this.dataContainer.get(
                'AmbulantPsychotherapy',
                'AmountAvailableEditedDate',
              ) !== ''
                ? moment(
                    this.dataContainer.get(
                      'AmbulantPsychotherapy',
                      'AmountAvailableEditedDate',
                    ),
                    'DD-MM.YYYY',
                  )?.format('YYYY-MM-DD')
                : this.currentDate
            }
            label="Erfasst am:"
            isDisabled={this.props.isDisabled}
            name="AmountAvailableEditedDate"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <p className="FormArea-ContentBox-title">
            Keine Aufnahmekapazitäten:
          </p>
          <CapacityForInsured
            isDisabled={this.props.isDisabled}
            name="CapacityForInsured"
            label="Für gesetzlich Versicherte:"
            from={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockLegallyInsuredFrom',
            )}
            until={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockLegallyInsuredUntil',
            )}
            onChange={(values) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockLegallyInsuredFrom',
              )(values.from);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockLegallyInsuredUntil',
              )(values.until);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockLegallyInsuredEditedBy',
              )(this.userdata.userGuid);
            }}
          />
          <Dropdown
            type="text"
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockLegallyInsuredEditedBy',
            )}
            options={this.state.Users}
            label="Eingetragen durch:"
            placeholder="wird automatisch eingetragen"
            isDisabled
            name="BlockLegallyInsuredEditedBy"
          />
          <CapacityForInsured
            isDisabled={this.props.isDisabled}
            name="CapacityForInsured"
            label="Für privat Versicherte:"
            from={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockPrivateInsuredFrom',
            )}
            until={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockPrivateInsuredUntil',
            )}
            onChange={(values) => {
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockPrivateInsuredFrom',
              )(values.from);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockPrivateInsuredUntil',
              )(values.until);
              this.dataContainer.set(
                'AmbulantPsychotherapy',
                'BlockPrivateInsuredEditedBy',
              )(this.userdata.userGuid);
            }}
          />
          <Dropdown
            type="text"
            defaultValue={this.dataContainer.get(
              'AmbulantPsychotherapy',
              'BlockPrivateInsuredEditedBy',
            )}
            options={this.state.Users}
            label="Eingetragen durch:"
            placeholder="wird automatisch eingetragen"
            isDisabled
            name="BlockPrivateInsuredEditedBy"
          />
        </ContentBox>
        <br />
        <ContentBox title="Angaben zum Praxisstandort">
          <AddressMapAutofill
            addressObject={{
              Address: this.dataContainer.get('FirstOffice', 'Street'),
              Postcode: this.dataContainer.get('FirstOffice', 'ZIP'),
              City: this.dataContainer.get('FirstOffice', 'City'),
            }}
            onChange={(val) => {
              this.dataContainer.set('FirstOffice', 'Street')(val.Address);
              this.dataContainer.set('FirstOffice', 'ZIP')(val.Postcode);
              this.dataContainer.set('FirstOffice', 'City')(val.City);
            }}
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'BarrierFreeAccess',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'FirstOffice',
              'BarrierFreeAccess',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Barrierefreier Zugang zur Praxis:"
            isDisabled={this.props.isDisabled}
            name="FirstOfficeBarrierFreeAccess"
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set('FirstOffice', 'InfoToReachbility')(value);
            }}
            defaultValue={this.dataContainer.get(
              'FirstOffice',
              'InfoToReachbility',
            )}
            label="Besonderheiten zur örtlichen Erreichbarkeit:"
            info="Hier können Sie Hinweise zum Zugang Ihrer Praxis eintragen, die wir an KlientInnen weitergeben. Bsp.: Zugang über den Hof"
            isDisabled={this.props.isDisabled}
            name="InfoToReachbility"
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'ParkingSpaces',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'FirstOffice',
              'ParkingSpaces',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Parkplätze in der Nähe der Praxis vorhanden:"
            isDisabled={this.props.isDisabled}
            name="FirstOfficeParkingSpaces"
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'FirstOffice',
                'LPTWalkingDistance',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'FirstOffice',
              'LPTWalkingDistance',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Öffentliche Verkehrsmittel fußläufig (ca. 5 min) in der Nähe der Praxis erreichbar:"
            isDisabled={this.props.isDisabled}
            name="FirstOfficeLPTWalkingDistance"
          />
        </ContentBox>
        <br />
        <ContentBox title="Angaben zum zweiten Praxisstandort">
          <AddressMapAutofill
            addressObject={{
              Address: this.dataContainer.get('SecondOffice', 'Street'),
              Postcode: this.dataContainer.get('SecondOffice', 'ZIP'),
              City: this.dataContainer.get('SecondOffice', 'City'),
            }}
            onChange={(val) => {
              this.dataContainer.set('SecondOffice', 'Street')(val.Address);
              this.dataContainer.set('SecondOffice', 'ZIP')(val.Postcode);
              this.dataContainer.set('SecondOffice', 'City')(val.City);
            }}
          />
          <Switch
            type="text"
            onChange={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'BarrierFreeAccess',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SecondOffice',
              'BarrierFreeAccess',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Barrierefreier Zugang zur Praxis:"
            isDisabled={this.props.isDisabled}
            name="SecondOfficeBarrierFreeAccess"
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'InfoToReachbility',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'InfoToReachbility',
            )}
            label="Besonderheiten zur örtlichen Erreichbarkeit:"
            info="Hier können Sie Hinweise zum Zugang Ihrer Praxis eintragen, die wir an KlientInnen weitergeben. Bsp.: Zugang über den Hof"
            isDisabled={this.props.isDisabled}
            name="InfoToReachbility"
            fullWidth
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'ParkingSpaces',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SecondOffice',
              'ParkingSpaces',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Parkplätze in der Nähe der Praxis vorhanden:"
            isDisabled={this.props.isDisabled}
            name="SecondOfficeParkingSpaces"
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'LPTWalkingDistance',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SecondOffice',
              'LPTWalkingDistance',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Öffentliche Verkehrsmittel fußläufig (ca. 5 min) in der Nähe der Praxis erreichbar:"
            isDisabled={this.props.isDisabled}
            name="SecondOfficeLPTWalkingDistance"
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'AdditionalComment',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'AdditionalComment',
            )}
            label="Ergänzende Angaben zu telefonischen Sprechzeiten und Terminvergabe:"
            info="Hier können Sie abweichende Angaben zu den telefonischen Sprechzeiten und der Terminvergabe am zweiten Praxisstandort ergänzen."
            isDisabled={this.props.isDisabled}
            name="AdditionalComment"
            fullWidth
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <p className="FormArea-ContentBox-title">
            Abweichende Informationen zum zweiten Praxissitz:
          </p>
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SecondOffice', 'PhoneNumber3')(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'PhoneNumber3',
            )}
            label="Telefonnummer für Kontakt mit CARE am zweiten Praxisstandort:"
            info="Über diese Telefonnummer nimmt das CARE-Team am zweiten Praxisstandort Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="PhoneNumber3"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SecondOffice', 'PhoneNumber4')(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'PhoneNumber4',
            )}
            label="Telefonnummer für KlientInnen am zweiten Praxisstandort:"
            info="Über diese Telefonnummer nehmen KlientInnen am zweiten Praxisstandort Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="PhoneNumber4"
          />
          <Input
            label="Internetauftritt:"
            info="Diese Angabe bezieht sich auf Ihren zweiten Praxisstandort."
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SecondOffice', 'Website')(value);
            }}
            defaultValue={this.dataContainer.get('SecondOffice', 'Website')}
            isDisabled={this.props.isDisabled}
            name="SecondOfficeWebsite"
            linkTo="Klicke, um die Internetadresse in einem neuen Fenster zu öffnen."
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Dropdown
            label="Kassenzulassung für den Standort:"
            info="Diese Angabe bezieht sich auf Ihren zweiten Praxisstandort."
            getSelected={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'CashRegisterApproval',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'CashRegisterApproval',
            )}
            options={this.state.CashRegisterApprovals}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="CashRegisterApproval"
          />
          <Dropdown
            label="Kostenerstattungsverfahren:"
            info="Diese Angabe bezieht sich auf Ihren zweiten Praxisstandort."
            getSelected={(value) => {
              this.dataContainer.set(
                'SecondOffice',
                'ReimbursementProcedure',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SecondOffice',
              'ReimbursementProcedure',
            )}
            options={this.state.ReimbursementProcedures}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="ReimbursementProcedure"
          />
        </ContentBox>
        <br />
      </Form>
    );
  }
}

AmbPsychForm.propTypes = {
  dataContainer: PropTypes.shape(),
  isDisabled: PropTypes.bool,
};

AmbPsychForm.defaultProps = {
  dataContainer: null,
  isDisabled: false,
};

export default AmbPsychForm;
