import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table';

// import customHistory from './js/history';

import PrivateRoute from './js/components/PrivateRoute';

import Login from './js/routes/Login';
import Forgot from './js/routes/Forgot/index';
import ChangePassword from './js/routes/ChangePassword';
import LoggedInArea from './js/LoggedInArea';
import Impressum from './js/routes/Impressum';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-table/react-table.css';
import './css/flexboxgrid.min.css';
import './css/main.css';
import { UserProvider } from './js/hooks/UserContext';
import appInfo from '../package';

Sentry.init({
  dsn: 'https://a7b298f2b9fb46eea9dd35db9d0b8e3f@sentry.mpa-web.de/19',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['caredb.mpa-web.de'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: 'caredb-react-js@' + appInfo.version,
});

Object.assign(ReactTableDefaults, {
  showPagination: false,
  resizable: false,
});

render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Router>
      <Switch>
        <Route exact path="/Login" component={Login} />
        <Route path="/Forgot" component={Forgot} />
        <Route path="/ChangePassword" component={ChangePassword} />
        <Route exact path="/Impressum" component={Impressum} />
        <UserProvider>
          <PrivateRoute component={LoggedInArea} />
        </UserProvider>
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);
