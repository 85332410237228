import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

class Searchbar extends Component {
  render() {
    const { onRef, onChange, onBlur, disabled, icon, ...props } = this.props;
    return (
      <div
        className={`Searchbar-wrapper ${
          this.props.size === 'small' ? 'Searchbar-wrapper--small' : ''
        }`}
      >
        <div className="Searchbar">
          {this.props.size !== 'small' && (
            <FontAwesomeIcon
              icon={icon ? icon : faSearch}
              className="Searchbar-icon"
            />
          )}
          <input
            className="Searchbar-Field"
            type="text"
            disabled={disabled}
            {...props}
            onChange={(evt) => {
              const { value } = evt.target;
              if (onChange) {
                onChange(value, evt);
              }
            }}
            onBlur={(evt) => {
              const { value } = evt.target;
              if (onBlur) {
                onBlur(value, evt);
              }
            }}
            ref={onRef}
          />
        </div>
      </div>
    );
  }
}

Searchbar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  size: PropTypes.oneOf(['small', 'normal']),
  disabled: PropTypes.bool,
  icon: PropTypes.shape(),
};

Searchbar.defaultProps = {
  size: 'normal',
  placeholder: 'Suchen...',
  onBlur: () => {},
  disabled: false,
};

export default Searchbar;
