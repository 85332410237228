import React from 'react';

import Dropdown from './Inputs/Dropdown';

const SearchDropdown = (props) => {
  return (
    <div className="SearchDropdown">
      <Dropdown
        fullWidth
        isClearable
        name="id"
        validator={[]}
        label={props.label}
        getSelected={props.onChange}
        options={props.options}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

export default SearchDropdown;
