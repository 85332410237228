import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import mapboxgl from 'mapbox-gl';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import mbxDirections from '@mapbox/mapbox-sdk/services/directions';
import settings from '../../../settings';

import Loader from '../Loader';

import markerImg from '../../../images/Marker.svg';
import markerImgBlue from '../../../images/MarkerBlue.svg';

mapboxgl.accessToken = settings.external.mapbox_key;

var createGeoJSONCircle = function (center, radiusInKm, points) {
  if (!points) points = 64;

  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = radiusInKm;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret],
          },
        },
      ],
    },
  };
};

class Map extends React.Component {
  map;
  markers = [];
  streetPointMarker;
  constructor(props) {
    super(props);

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.diretionsClient = mbxDirections({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapcontainer,
      style: 'mapbox://styles/marktplatzgmbh/cka87hn5q046p1is4lzi88rsx',
      center: [10.4515, 51.1657],
      zoom: 5,
      language: 'de',
    });
    this.map.on('load', () => {
      this.map.addSource('circle', createGeoJSONCircle([0, 0], 0));
      this.map.addLayer({
        id: 'circle',
        type: 'fill',
        source: 'circle',
        layout: {},
        paint: {
          'fill-color': '#339af0',
          'fill-opacity': 0.3,
        },
      });
      this._addressToLatLon();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.markers !== prevProps.markers) {
      // repaint markers
      this.markers.forEach((marker) => {
        marker.remove();
      });
      this._addressToLatLon();
    }
    if (this.props.streetPoint !== prevProps.streetPoint) {
      // repaint location (blue marker)
      this._updateBlueMarkerOnMap(
        this.props.streetPoint[0],
        this.props.streetPoint[1],
      );
      this._updateRadiusOnMap(
        this.props.streetPoint[0],
        this.props.streetPoint[1],
        this.props.radius,
      );
    }
    if (this.props.radius !== prevProps.radius) {
      // repaint location (blue marker)
      this._updateRadiusOnMap(
        this.props.streetPoint[0],
        this.props.streetPoint[1],
        this.props.radius,
      );
    }
  }

  _arePointsNear(checkPoint, centerPoint, km) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  _addressToLatLon = () => {
    this.props.markers
      .filter((marker) => {
        if (!marker || !marker.ZipLat || !marker.ZipLon) {
          return false;
        }
        if (!this.props.radius || !this.props.streetPoint) {
          return true;
        }
        const mLat = parseFloat(marker.ZipLat.replace(',', '.'));
        const mLon = parseFloat(marker.ZipLon.replace(',', '.'));
        if (
          this._arePointsNear(
            {
              lng: mLon,
              lat: mLat,
            },
            {
              lng: this.props.streetPoint[0],
              lat: this.props.streetPoint[1],
            },
            parseFloat(this.props.radius),
          )
        ) {
          return true;
        }
        return false;
      })
      .forEach((marker) => {
        const mLat = parseFloat(marker.ZipLat.replace(',', '.'));
        const mLon = parseFloat(marker.ZipLon.replace(',', '.'));
        this._addMarkerToMap(
          marker.Guid,
          mLon,
          mLat,
          marker.Lastname,
          marker.Firstname,
          marker.Street,
          marker.ZIP,
          marker.City,
        );
      });
  };

  _updateRadiusOnMap = (lat, lon, radius = 0) => {
    this.map
      .getSource('circle')
      .setData(createGeoJSONCircle([lat, lon], radius).data);
  };

  _updateBlueMarkerOnMap = (lat, lon) => {
    if (this.streetPointMarker) {
      this.streetPointMarker.remove();
    }
    const el = document.createElement('div');
    el.className = 'marker';

    ReactDOM.render(
      <div className="Map-MarkerWrapper">
        <img
          src={markerImgBlue}
          alt="Marker Blau"
          className="Map-MarkerImage"
        />
      </div>,
      el,
    );
    this.streetPointMarker = new mapboxgl.Marker(el)
      .setLngLat([lat, lon])
      .addTo(this.map);
  };

  _addMarkerToMap = (
    guid,
    lat,
    lon,
    lastname,
    firstname,
    street,
    zip,
    city,
  ) => {
    const el = document.createElement('div');
    el.className = 'marker';

    ReactDOM.render(
      <div className="Map-MarkerWrapper">
        <img src={markerImg} alt="Marker" className="Map-MarkerImage" />
      </div>,
      el,
    );

    const ep = document.createElement('div');
    ep.className = 'popup';
    ReactDOM.render(
      <div className="Map-MarkerOverlay">
        <h3>
          {lastname}, {firstname}
        </h3>
        <p>
          {street}
          <br />
          {zip} {city}
        </p>
        <div className="Map-MarkerButtons">
          <a
            className="Map-MarkerButton"
            href={`/supplynetwork/AmbulatoryPsychotherapy/detail/${guid}`}
          >
            zur Detailansicht
          </a>
          {this.streetPointMarker && (
            <button
              className="Map-MarkerButton"
              onClick={() => {
                this._getDirectionsForMarker(lat, lon);
              }}
            >
              Route anzeigen
            </button>
          )}
        </div>
      </div>,
      ep,
    );

    const p = new mapboxgl.Popup().setDOMContent(ep);

    const marker = new mapboxgl.Marker(el)
      .setLngLat([lat, lon])
      .setPopup(p)
      .addTo(this.map);
    this.markers.push(marker);
  };

  _getDirectionsForMarker = (lat, lon) => {
    this.diretionsClient
      .getDirections({
        profile: 'driving',
        geometries: 'geojson',
        waypoints: [
          {
            coordinates: this.props.streetPoint,
          },
          {
            coordinates: [lat, lon],
          },
        ],
      })
      .send()
      .then((response) => {
        const directions = response.body;
        const coords = directions.routes[0].geometry;
        // const routeDistance = directions.routes[0].distance;

        if (this.map.getSource('route')) {
          this.map.removeLayer('route');
          this.map.removeSource('route');
        } else {
          this.map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: coords,
              },
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#ed0028',
              'line-width': 4,
              'line-opacity': 0.8,
            },
          });
        }
      });
  };

  render() {
    if (!mapboxgl.supported()) {
      return (
        <div className="Map-Error">
          Die Karte wird von Ihrem Browser nicht unterstützt.
        </div>
      );
    }

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="Map-Wrapper">
        <div
          ref={(node) => {
            return (this.mapcontainer = node);
          }}
          className="Map-Container"
        />
      </div>
    );
  }
}

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.any),
  streetPoint: PropTypes.arrayOf(PropTypes.number),
  radius: PropTypes.string,
};

Map.defaultProps = {
  markers: [],
  streetPoint: [],
  radius: '',
};

export default Map;
