import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import {
  faArrowLeft,
  faSave,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import getConstants from '../../../../../logic/constants';
import { Link, withRouter } from 'react-router-dom';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import moment from 'moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ConsultationNewEntry extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.state = {
      loading: false,
      KindOfConsultation: [],
      PlaceOfConsultation: [],
      CarePsych: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Consultation', {
      Typ: 'Consultation',
      ClientGuid: this.clientGuid,
      DateOfConsultation: '',
      Duration: '',
      KindOfConsultation: '',
      PlaceOfConsultation: '',
      CarePsych: '',
    });
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      PlaceOfConsultation: await getConstants('PlaceOfConsultation'),
      KindOfConsultation: await getConstants('CounselingContactKind'),
      CarePsych: await getConstants('CarePsych'),
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const oneDataContainer = JSON.stringify({
      ParamName: 'Consultation',
      Params: this.dataContainer.get('Consultation'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        // container: this.dataContainer.getStringified(), // TODO: use this when in middelware fixed! DataContain should always be in Array/List
        action: 'Neue Wiedervorlage angelegt',
      },
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.clientGuid}`,
                );
              }}
            >
              Klient/in
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Dokumentation</Link> -{' '}
            <b>Neue Beratung</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faTimes}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to={this.parentRoute}>
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
              <Form
                ref={(node) => {
                  return (this.form = node);
                }}
              >
                <ContentBox title="Beratungsdokumentation">
                  <Input
                    type="date"
                    validator={[
                      {
                        required: true,
                        error: 'Bitte Datum eingeben',
                      },
                      {
                        date: true,
                        error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                      },
                    ]}
                    onChange={(value) => {
                      this.dataContainer.set(
                        'Consultation',
                        'DateOfConsultation',
                      )(moment(value)?.format('YYYY-MM-DD'));
                    }}
                    defaultValue={moment(
                      this.dataContainer.get(
                        'Consultation',
                        'DateOfConsultation',
                      ),
                    )?.format('YYYY-MM-DD')}
                    label="Datum der Beratung"
                    name="DateOfConsultation"
                  />
                  <Input
                    type="number"
                    validator={[]}
                    onChange={(value) => {
                      this.dataContainer.set('Consultation', 'Duration')(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Consultation',
                      'Duration',
                    )}
                    label="Dauer der Beratung in Minuten"
                    name="Duration"
                  />
                  <Dropdown
                    label="Form der Beratung:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Consultation',
                        'KindOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Consultation',
                      'KindOfConsultation',
                    )}
                    options={this.state.KindOfConsultation}
                    validator={[]}
                    name="KindOfConsultation"
                  />
                  <Dropdown
                    label="Standort"
                    validator={[]}
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Consultation',
                        'PlaceOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Consultation',
                      'PlaceOfConsultation',
                    )}
                    options={this.state.PlaceOfConsultation}
                    name="PlaceOfConsultation"
                  />
                  <Dropdown
                    label="CARE-Psychologe:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Consultation',
                        'CarePsych',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Consultation',
                      'CarePsych',
                    )}
                    options={this.state.CarePsych}
                    validator={[]}
                    name="CarePsych"
                  />
                </ContentBox>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ConsultationNewEntry);
