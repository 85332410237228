import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { faEdit, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import DataContainer from '../../../logic/dataContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import HeaderbarButton from '../../../components/HeaderbarButton';

class OverviewResubmission extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      data: [],
      searchString,
    };
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    const dataColumns = {
      Typ: 'Resubmission',
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const filteredClientData = new DataContainer(res);
        this.setState({
          data: filteredClientData.getMany('Entry'),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader text="Wiedervorlagen werden geladen..." />
        </div>
      );
    }

    let data = [];
    if (this.state.data.length) {
      data = this.state.data;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <b>Wiedervorlagen</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar"></div>
          <ReactTable
            getTrProps={(_, val) => {
              return {
                onClick: () => {
                  return this.props.history.push(
                    `supplynetwork/AmbulatoryPsychotherapy/detail/resubmission/${val?.original?.Guid}`,
                  );
                },
              };
            }}
            defaultSorted={[
              {
                id: 'DateOfResub',
                desc: true,
              },
            ]}
            data={data}
            pageSize={data.length}
            noDataText="Es wurden keine Wiedervorlagen gefunden."
            columns={[
              {
                accessor: 'DateOfResub',
                Header: 'Datum der Wiedervorlage',
                Cell: ({ original }) => {
                  return (
                    <>{moment(original.DateOfResub).format('DD.MM.YYYY')}</>
                  );
                },
              },
              {
                accessor: 'Reason',
                Header: 'Grund',
                Cell: ({ original }) => {
                  return <>{original.Reason}</>;
                },
              },
              {
                accessor: 'UpdateUserName',
                Header: 'Mitarbeiter',
                Cell: ({ original }) => {
                  return <>{original.UpdateUserName}</>;
                },
              },
              {
                accessor: 'Link',
                Header: 'Zur Detailansicht',
                Cell: () => {
                  return (
                    <>
                      <u>Hier klicken</u>&nbsp;
                      <FontAwesomeIcon icon={faEdit} />
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default withRouter(OverviewResubmission);
