import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import reqwest from 'reqwest';
import {
  faPlus,
  faGripLinesVertical,
  faSave,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuid } from 'uuid';
import swal from 'sweetalert';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class ConstantsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.type = props.match.params.key;
    this.parentRoute = this.props.match.url.replace(
      new RegExp(`/${this.type}$`),
      '',
    );

    reqwest({
      method: 'GET',
      url: settings.constants.getList,
      data: {
        Token: localStorage.getItem('token'),
        Typ: this.type,
      },
    }).then((constant) => {
      constant = JSON.parse(constant);

      const fields = [...this.state.fields];
      if (constant[0]?.Title) {
        fields.push({
          label: constant[0].Title,
          value: 'ExtraField',
        });
      }

      constant = constant
        .filter((con) => {
          return con.Guid;
        })
        .sort((a, b) => {
          return parseInt(a.Ord) - parseInt(b.Ord);
        });

      this.setState({
        loading: false,
        constant,
        fields,
      });
    });

    this.state = {
      loading: true,
      hasChanges: false,
      constant: [],
      fields: [
        {
          label: 'Name',
          value: 'Name',
        },
        {
          label: 'Kurzkennzeichen (KKZ)',
          value: 'KKZ',
        },
      ],
    };
  }

  save = () => {
    this.setState({
      loading: true,
    });
    const savePromises = [];
    [...this.state.constant].forEach((constant, index) => {
      constant.Ord = index;
      if (constant.Changed === true) {
        delete constant.Changed;
        if (constant.Guid.startsWith('new:')) {
          constant.Guid = '';
        }
        savePromises.push(
          reqwest({
            method: 'POST',
            url: settings.constants.save,
            data: {
              Token: localStorage.getItem('token'),
              Constant: JSON.stringify(constant),
            },
          }).then((saveResult) => {
            saveResult = JSON.parse(saveResult);
            return saveResult.SaveResult;
          }),
        );
      }
    });

    if (savePromises.length === 0) {
      swal({
        title: 'Keine Änderungen...',
        text: 'Die Konstanten wurden nicht geändert oder sind unvollständig.',
        icon: 'warning',
      });
    }

    Promise.all(savePromises)
      .then((saveResults) => {
        if (saveResults.length) {
          const isValid = saveResults.every((result) => {
            return result !== 'Updated' || result !== 'Saved';
          });
          if (isValid) {
            swal({
              title: 'Erfolgreich!',
              text: 'Die Konstante wurde erfolgreich gespeichert.',
              icon: 'success',
            });
            this.setState({ hasChanges: false, loading: false });
          }
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  delete = (entry) => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: `Möchten Sie die Konstante "${entry.Name}" wirklich löschen? Dies kann nicht Rückgängig gemacht werden!\nAlle Datensätze welche "${this.props.match.params.value}" verwenden könnten beschädigt werden.`,
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (!entry.Guid.startsWith('new:')) {
          reqwest({
            method: 'GET',
            url: settings.constants.delete,
            data: {
              Token: localStorage.getItem('token'),
              Guid: entry.Guid,
            },
          }).then((deleteResult) => {
            deleteResult = JSON.parse(deleteResult);

            if (deleteResult.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                text: `Die Konstante "${entry.Name}" wurde erfolgreich gelöscht.`,
                icon: 'success',
              });

              const constant = [...this.state.constant].filter((con) => {
                return con.Guid !== entry.Guid;
              });
              this.setState({
                constant,
              });
            }
          });
        } else {
          const constant = [...this.state.constant].filter((con) => {
            return con.Guid !== entry.Guid;
          });
          this.setState({
            constant,
          });
        }
      }
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) return;

    let items = reorder(
      this.state.constant,
      result.source.index,
      result.destination.index,
    );
    items = items.map((item, index) => {
      item.Ord = index;
      item.Changed = true;
      return item;
    });

    this.setState({
      constant: items,
      hasChanges: true,
    });
  };

  addConstant = () => {
    const { constant } = this.state;

    let newConstant = {
      Guid: `new:${uuid()}`,
      Typ: this.type,
      Name: '',
      KKZ: '',
      Ord: '',
    };

    if (constant[0]?.Title) {
      newConstant = {
        ...newConstant,
        Title: constant[0].Title,
        FieldType: constant[0].FieldType,
        ExtraField: '',
      };
    }

    this.setState({
      constant: [...constant, newConstant],
      hasChanges: true,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const maxWidth = `calc((100% - 80px) / ${this.state.fields.length})`;
    const minWidth = `calc((100% - 80px) / ${this.state.fields.length})`;

    return (
      <>
        <div className="Page-Content">
          <div className="Buttonbar">
            {this.state.hasChanges && (
              <Button type="primary" icon={faSave} onClick={this.save}>
                Speichern
              </Button>
            )}
            <Button icon={faPlus} onClick={this.addConstant}>
              Konstanten hinzufügen
            </Button>
          </div>
          <ContentBox title={this.props.match.params.value}>
            <div className="Table">
              <div className="Table-head">
                <div
                  className="Table-head-field"
                  style={{
                    maxWidth: '61px',
                    minWidth: '61px',
                  }}
                >
                  {' '}
                </div>
                {this.state.fields.map((field) => {
                  return (
                    <div
                      key={field.value}
                      className="Table-head-field"
                      style={{ maxWidth, minWidth }}
                    >
                      {field.label}
                    </div>
                  );
                })}
                <div
                  className="Table-head-field"
                  style={{
                    maxWidth: '40px',
                    minWidth: '40px',
                  }}
                >
                  {' '}
                </div>
              </div>
              <div className="Table-body">
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="Table-body">
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.state.constant.map((constant, cIndex) => {
                            return (
                              <Draggable
                                key={constant.Guid}
                                draggableId={constant.Guid}
                                index={cIndex}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      className="Table-body-row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className="Table-body-data"
                                        style={{
                                          maxWidth: '40px',
                                          minWidth: '40px',
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className="Table-body-data-icon"
                                          icon={faGripLinesVertical}
                                        />
                                      </div>
                                      {this.state.fields.map((field) => {
                                        return (
                                          <div
                                            key={field.value}
                                            className="Table-body-data"
                                            style={{ maxWidth, minWidth }}
                                          >
                                            <input
                                              type="text"
                                              className="Table-body-data-value"
                                              defaultValue={
                                                constant[field.value]
                                              }
                                              onChange={(event) => {
                                                event.persist();
                                                const constant = [
                                                  ...this.state.constant,
                                                ];
                                                constant[cIndex][field.value] =
                                                  event.target.value;
                                                constant[cIndex].Changed = true;

                                                this.setState({
                                                  constant,
                                                });
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                      <div
                                        className="Table-body-data"
                                        style={{
                                          maxWidth: '40px',
                                          minWidth: '40px',
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className="Table-body-data-icon"
                                          icon={faTrashAlt}
                                          onClick={() => {
                                            this.delete(constant);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </ContentBox>
        </div>
      </>
    );
  }
}

export default ConstantsDetail;
