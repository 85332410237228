import React, { Component } from 'react';
import swal from 'sweetalert';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Navigation from './components/Navigation';
import Usermanagement from './routes/Usermanagement';
import Supplynetwork from './routes/Supplynetwork';
import Systemmanagement from './routes/Systemmanagement';
import Clients from './routes/Clientmanagement/Clients';
import OverviewAppointments from './routes/Clientmanagement/Clients/OverviewAppointments';
import OverviewResubmission from './routes/Clientmanagement/Clients/OverviewResubmission';
import Exportstatistik from './routes/ExportStatistics';
import UserContext from './hooks/UserContext';
class LoggedInArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      httpTesterOpen: false,
    };
  }
  // Assign a contextType to read the current theme context.
  // React will find the closest theme Provider above and use its value.
  static contextType = UserContext;
  render() {
    if (this.state.error) {
      const wrapper = document.createElement('pre');
      if (this.state.errorDetail) {
        wrapper.innerHTML = this.state.errorDetail;
      }
      swal({
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: 'Uh oh!',
        text: this.state.error,
        icon: 'error',
        content: wrapper,
      }).then(() => {
        this.props.history.push('/clientmanagement');
      });
      return null;
    }

    return (
      <div className="LoggedInArea">
        <Navigation />
        <div className="ContentArea">
          <Switch>
            {this.context.isAdmin && (
              <Route path="/systemmanagement" component={Systemmanagement} />
            )}
            {(this.context.isAdmin || this.context.isManagementSpecial) && (
              <Route path="/usermanagement" component={Usermanagement} />
            )}
            {(this.context.isAdmin ||
              this.context.isManagementSpecial ||
              this.context.isManagement) && (
              <Route path="/supplynetwork" component={Supplynetwork} />
            )}
            <Route path="/clientmanagement" component={Clients} />
            <Route path="/appointments" component={OverviewAppointments} />
            <Route path="/resubmissions" component={OverviewResubmission} />
            <Route path="/exportstatistik" component={Exportstatistik} />
            <Route path="/">
              <Redirect to="/clientmanagement" />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(LoggedInArea);
