import React from 'react';
import ReactDOM from 'react-dom';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import history from '../history';
import LoginFormPop from '../components/LoginFormPop';

function renderLoginPopup() {
  const popupContainer = document.createElement('div');
  popupContainer.id = 'LoginFormPop';
  document.body.appendChild(popupContainer);
  // eslint-disable-next-line react/react-in-jsx-scope
  ReactDOM.render(<LoginFormPop />, popupContainer);
}

export function closePopup() {
  const divToDelete = document.getElementById('LoginFormPop');
  if (divToDelete) {
    document.body.removeChild(divToDelete);
  }
}

/**
 * @name useApi
 * @function useApi
 * @param {object} userContext - The context of the current page/component. For calling openLoginForm
 * @param {string} method - HTTP method
 * @param {string} url - API endpoint
 * @param {object} data - Data to send to the API
 * @returns {Promise} Promise
 * @description Function to check API authorization
 * @version 0.1.0
 * @since 0.1.0
 */

export default function api(method = 'get', url, data, redirect = false) {
  return new Promise((resolve, reject) => {
    reqwest({
      method,
      url,
      data: {
        ...data,
        Token: localStorage.getItem('token'),
      },
    })
      .then((res) => {
        resolve(res); // Resolve with response data on success
      })
      .catch((error) => {
        console.log('Error:', error);
        // Handle different error scenarios
        if (error.status === 401) {
          if (redirect) {
            history.go('/login');
          } else {
            renderLoginPopup();
          }
        } else {
          // Other errors
          swal({
            title: 'Fehler',
            text: 'Es ist ein Fehler aufgetreten.',
            icon: 'error',
          });
        }
        reject(error); // Reject promise with error
      });
  });
}
