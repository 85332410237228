import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Inputs/Dropdown';
import getConstants from '../logic/constants';

class Disorders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainFocus: props.mainFocus,
      exclusion: props.exclusion,
      Constants: [],
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      Constants: await getConstants('Disorders'),
    });
  }

  handleChangeMainFocus(values = []) {
    if (this.props.onChange) {
      this.props.onChange({
        mainFocus: values,
        exclusion: this.state.exclusion,
      });
    }
    this.setState({ mainFocus: values });
  }

  handleChangeExclusion(values = []) {
    if (this.props.onChange) {
      this.props.onChange({
        mainFocus: this.state.mainFocus,
        exclusion: values,
      });
    }
    this.setState({ exclusion: values });
  }

  render() {
    return (
      <div name={this.props.name} className="Disorders Input--fullWidth">
        <div className="Disorders-label">{this.props.label}</div>
        <div className="Disorders-Input">
          <Dropdown
            name="MainFocus"
            label="Schwerpunkte:"
            info={this.props.infoFocus}
            isMulti
            isClearable
            isSearchable
            isDisabled={this.props.isDisabled}
            defaultValue={this.props.mainFocus}
            options={this.state.Constants}
            getSelected={(values) => {
              this.handleChangeMainFocus(values);
            }}
          />
          <Dropdown
            name="Exclusion"
            label="Ausschlüsse:"
            info={this.props.infoExclusion}
            isMulti
            isClearable
            isSearchable
            isDisabled={this.props.isDisabled}
            defaultValue={this.props.exclusion}
            options={this.state.Constants}
            getSelected={(values) => {
              this.handleChangeExclusion(values);
            }}
          />
        </div>
      </div>
    );
  }
}

Disorders.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  infoFocus: PropTypes.string,
  infoExclusion: PropTypes.string,
  mainFocus: PropTypes.arrayOf(PropTypes.string),
  exclusion: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Disorders.defaultProps = {
  name: '',
  label: '',
  infoFocus: '',
  infoExclusion: '',
  mainFocus: [],
  exclusion: [],
  isDisabled: false,
  onChange: null,
};

export default Disorders;
