import React from 'react';
import PropTypes from 'prop-types';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.inputTypeNames = ['Input', 'Dropdown', 'Switch'];
    this.inputs = [];
  }

  validate = async () => {
    const inputs = this.inputs.filter(Boolean);
    if (!inputs || inputs.length === 0) {
      return true;
    }

    const promises = inputs.map((input) => {
      return input.validate();
    });
    return Promise.all(promises).then((values) => {
      return values.every((value) => {
        return value;
      });
    });
  };

  componentDidMount() {
    this.validate();
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.validate();
    return false;
  };

  hijackAllInputs = (children) => {
    this.inputs = [];
    return React.Children.map(children, (child, index) => {
      if (
        child &&
        child.type &&
        this.inputTypeNames.includes(child.type.displayName)
      ) {
        // HIJACK THEM
        if (child.props && child.props.children) {
          return React.cloneElement(child, {
            ...child.props,
            ref: (node) => {
              if (node) this.inputs.push(node);
            },
            children: this.hijackAllInputs(child.props.children),
          });
        }
        return React.cloneElement(child, {
          ...child.props,
          ref: (node) => {
            if (node) this.inputs.push(node);
          },
        });
      }
      if (child && child.props && child.props.children) {
        return React.cloneElement(child, {
          ...child.props,
          key: index,
          children: this.hijackAllInputs(child.props.children),
        });
      }
      return child;
    });
  };

  render() {
    return (
      <form
        style={{ width: '100%' }}
        ref={(node) => {
          return (this.form = node);
        }}
        action="/"
        onSubmit={this.handleSubmit}
        method="POST"
      >
        {this.hijackAllInputs(this.props.children)}
      </form>
    );
  }
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Form;
