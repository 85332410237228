import moment from 'moment';

export const checkStatus = (original) => {
  // Grundsätzlich gesperrte Datensätze
  if (!original?.Unlocked) {
    return 'gray';
  }

  // Therapeut/in abwesend
  if (moment(original.AbsentUntil, 'DD.MM.YYYY').isAfter(moment())) {
    return 'red';
  }

  // Keine Aufnahmekapazitäten
  if (
    moment(original.BlockLegallyInsuredUntil, 'DD.MM.YYYY').isAfter(moment()) ||
    moment(original.BlockPrivateInsuredUntil, 'DD.MM.YYYY').isAfter(moment())
  ) {
    return 'yellow';
  }

  // Verfügbare Therapieplätze
  if (Number(original.AmountAvailable) > 0) {
    return 'green';
  }

  // Noch nicht verfügbar
  return 'gray ';
};
