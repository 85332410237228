import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';

class ConstantsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      types: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.constants.getTypes,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then((typesResponse) => {
      const types = JSON.parse(typesResponse);
      this.setState({
        types,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    const data = this.state.types;
    return (
      <>
        <div className="Page-Content">
          <div className="Buttonbar"></div>
          <ReactTable
            getTrProps={(_, { original }) => {
              return {
                onClick: () => {
                  return this.props.history.replace(
                    `${this.props.match.url}/${original.key}/${original.value}`,
                  );
                },
              };
            }}
            data={data}
            pageSize={data.length}
            noDataText="Es liegen keine Typen vor."
            columns={[
              {
                Header: 'Name',
                accessor: 'value',
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default ConstantsOverview;
