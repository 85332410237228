import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ClientDetail from './Detail';
import Appointmentmanagement from './Appointmentmanagement';
import Resubmission from './Resubmission';
import Consultation from './Consultation';
import ClientHistory from './History';
import SelectCounselling from './SelectCounselling';
import CaseFile from './CaseFile';
import CaseStatistics from './CaseStatistics';

const ClientDetails = (props) => {
  const paths = {
    caseFile: `/clientmanagement/detail/caseFile`,
    selectCounselling: `/clientmanagement/detail/selectCounselling`,
    appointments: `/clientmanagement/detail/appointments/:clientGuid?`,
    resubmission: `/clientmanagement/detail/resubmission/:clientGuid?`,
    consultation: `/clientmanagement/detail/consultation/:clientGuid?`,
    caseStatistics: `/clientmanagement/detail/caseStatistics/:clientGuid?`,
    history: `/clientmanagement/detail/history/:clientGuid?`,
    details: `${props.match.path}`,
  };

  return (
    <Switch>
      <Route path={paths.caseFile} component={CaseFile} />
      <Route path={paths.selectCounselling} component={SelectCounselling} />
      <Route path={paths.appointments} component={Appointmentmanagement} />
      <Route path={paths.resubmission} component={Resubmission} />
      <Route path={paths.consultation} component={Consultation} />
      <Route path={paths.caseStatistics} component={CaseStatistics} />
      <Route path={paths.history} component={ClientHistory} />
      <Route path={paths.details} component={ClientDetail} />
    </Switch>
  );
};

export default withRouter(ClientDetails);
