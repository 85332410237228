import {
  faArrowLeft,
  faArrowRight,
  faPrint,
  faSave,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from '@sweetalert/with-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import settings from '../../../../../../settings';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Input from '../../../../../components/Inputs/Input';
import Loader from '../../../../../components/Loader';
import getConstants from '../../../../../logic/constants';
import DataContainer from '../../../../../logic/dataContainer';
import moment from 'moment';
class CaseStatisticsNewEntry extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/caseStatistics'),
      ``,
    );

    this.state = {
      loading: false,
      PreviousUseOfCareOption: [],
      RecommendedInterventionOption: [],
      ArrangementOfPsychotherapyThroughCare: [],
      CarePsychologistLocation: [],
      RegionalAssignmentOfTheCase: [],
      NoAssistanceFromCareOption: [],
      PlaceOfConsultation: [],
      CarePsych: [],
      selectedOption: {
        PreviousUseOfCareOption: [],
        RecommendedInterventionOption: [],
        ArrangementOfPsychotherapyThroughCare: [],
        CarePsychologistLocation: [],
        RegionalAssignmentOfTheCase: [],
        NoAssistanceFromCareOption: [],
        PlaceOfConsultation: [],
        CarePsych: [],
        DateOfAssistance: '',
      },
      firstStep: true,
      secondStep: false,
      documentBlob: null,
    };

    this.dataContainer = new DataContainer();
    this.initConstants();
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.statistic.get,
      data: {
        ClientGuid: this.clientGuid,
      },
    })
      .then(async (res) => {
        this.dataContainer.add('Statistic', res);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getPreviousUseOfCareOption = [
    { value: true, label: 'Ja' },
    { value: false, label: 'Nein' },
  ];

  async initConstants() {
    this.setState({
      PreviousUseOfCareOption: this.getPreviousUseOfCareOption,
      RecommendedInterventionOption: await getConstants(
        'RecommendedInterventionOption',
      ),
      NoAssistanceFromCareOption: await getConstants(
        'NoAssistanceFromCareOption',
      ),
      ArrangementOfPsychotherapyThroughCare: await getConstants(
        'ArrangementOfPsychotherapyThroughCare',
      ),
      CarePsychologistLocation: await getConstants('Location'),
      RegionalAssignmentOfTheCase: await getConstants('Location'),
    });
  }

  handleSelectedOption = (name, value) => {
    this.setState({
      selectedOption: {
        ...this.state.selectedOption,
        [name]: value,
      },
    });
  };

  save = async () => {
    this.setState({
      loading: true,
    });
    await reqwest({
      url: settings.statistic.save,
      method: 'POST',
      data: {
        PreviousUseOfCareOption:
          this.state.selectedOption.PreviousUseOfCareOption,

        RecommendedInterventionOption: JSON.stringify(
          this.state.selectedOption.RecommendedInterventionOption,
        ),
        NoAssistanceFromCareOption:
          this.state.selectedOption.NoAssistanceFromCareOption,

        ArrangementOfPsychotherapyThroughCare:
          this.state.selectedOption.ArrangementOfPsychotherapyThroughCare,
        CarePsychologistLocation:
          this.state.selectedOption.CarePsychologistLocation,
        RegionalAssignmentOfTheCase:
          this.state.selectedOption.RegionalAssignmentOfTheCase,
        DateOfAssistance: this.state.selectedOption.DateOfAssistance,
        ClientGuid: this.clientGuid,
      },
    })
      .then((_) => {
        const url = window.URL.createObjectURL(this.state.documentBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Statistik_${moment().format('YYYY-MM-DD-hh:mm')}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          loading: false,
        });
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: 'error',
        });
      });
  };

  showPreview = async (actionType) =>
    new Promise((resolve, reject) => {
      try {
        this.setState({
          loading: true,
        });
        const xhr = new XMLHttpRequest();
        xhr.open('POST', settings.statistic.getArchivePrintout, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
          if (xhr.status === 200) {
            const blob = new File([xhr.response], 'Statistik.pdf', {
              type: 'application/pdf',
            });
            this.setState({
              documentBlob: blob,
              firstStep: false,
              secondStep: true,
              loading: false,
            });
            resolve();
          } else {
            this.showError(
              'Es ist ein Fehler beim Erstellen der Vorschau aufgetreten.',
            );
            this.setState({
              loading: false,
            });
            reject(new Error('Erstellung der Vorschau fehlgeschlagen'));
          }
        };

        xhr.onerror = () => {
          this.setState({
            loading: false,
          });
          this.showError(
            'Es ist ein Fehler beim Erstellen der Vorschau aufgetreten.',
          );
          reject(new Error('Netzwerkfehler'));
        };

        const formData = this.createFormData(actionType);
        xhr.send(formData);
      } catch (error) {
        this.setState({
          loading: false,
        });
        this.showError(
          'Es ist ein Fehler beim Erstellen der Vorschau aufgetreten.',
        );
        reject(error);
      }
    });

  createFormData = () => {
    const formData = new FormData();
    const { selectedOption } = this.state;

    formData.append(
      'PreviousUseOfCareOption',
      selectedOption.PreviousUseOfCareOption,
    );
    formData.append(
      'RecommendedInterventionOption',
      JSON.stringify(selectedOption.RecommendedInterventionOption),
    );
    formData.append(
      'NoAssistanceFromCareOption',
      selectedOption.NoAssistanceFromCareOption,
    );
    formData.append(
      'ArrangementOfPsychotherapyThroughCare',
      selectedOption.ArrangementOfPsychotherapyThroughCare,
    );
    formData.append(
      'CarePsychologistLocation',
      selectedOption.CarePsychologistLocation,
    );
    formData.append(
      'RegionalAssignmentOfTheCase',
      selectedOption.RegionalAssignmentOfTheCase,
    );
    formData.append('DateOfAssistance', selectedOption.DateOfAssistance);
    formData.append('ClientGuid', this.clientGuid);

    return formData;
  };

  showError = (message) => {
    swal({
      title: 'Fehler!',
      text: message,
      icon: 'error',
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.clientGuid}`,
                );
              }}
            >
              Klient/in
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Statistik</Link> - <b>Neue Statistik</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faTimes}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <Button onClick={() => this.props.history.goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} /> &ensp; Zurück
              </Button>
            </div>
          </div>

          <div className="Split-Pane-Main">
            <div className="Page-Content">
              {this.state.firstStep && (
                <ContentBox title="Statistikdaten">
                  <Dropdown
                    label="Vorherige Inanspruchnahme von CARE:"
                    options={this.state.PreviousUseOfCareOption}
                    getSelected={(value) =>
                      this.handleSelectedOption(
                        'PreviousUseOfCareOption',
                        value,
                      )
                    }
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                    name="PreviousUseOfCAREOption"
                  />
                  <Input
                    type="date"
                    onChange={(value) => {
                      this.handleSelectedOption(
                        'DateOfAssistance',
                        moment(value).format('YYYY-MM-DD'),
                      );
                    }}
                    label="Datum der Hilfeleistung"
                    name="DateOfAssistance"
                  />
                  <Dropdown
                    name="RecommendedIntervention"
                    label="Empfohlene Intervention (Eingabefunktion) NEU"
                    info="Mehrfachauswahl"
                    isMulti
                    isClearable
                    isSearchable
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                    options={this.state.RecommendedInterventionOption}
                    getSelected={(values) =>
                      this.handleSelectedOption(
                        'RecommendedInterventionOption',
                        values,
                      )
                    }
                  />
                  <Dropdown
                    name="ArrangementOfPsychotherapyThroughCare"
                    label="Vermittlung einer Psychotherapie durch CARE"
                    options={this.state.ArrangementOfPsychotherapyThroughCare}
                    getSelected={(values) =>
                      this.handleSelectedOption(
                        'ArrangementOfPsychotherapyThroughCare',
                        values,
                      )
                    }
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                  />
                  <Dropdown
                    name="NoAssistanceFromCare"
                    label="Keine Hilfe durch CARE"
                    options={this.state.NoAssistanceFromCareOption}
                    getSelected={(values) =>
                      this.handleSelectedOption(
                        'NoAssistanceFromCareOption',
                        values,
                      )
                    }
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                  />
                  <Dropdown
                    name="CarePsychologistLocation"
                    label="Standort der CARE-Psychologin"
                    options={this.state.CarePsychologistLocation}
                    getSelected={(values) =>
                      this.handleSelectedOption(
                        'CarePsychologistLocation',
                        values,
                      )
                    }
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                  />
                  <Dropdown
                    name="RegionalAssignmentOfTheCase"
                    label="Regionale Zuordnung des Falles"
                    options={this.state.RegionalAssignmentOfTheCase}
                    getSelected={(values) =>
                      this.handleSelectedOption(
                        'RegionalAssignmentOfTheCase',
                        values,
                      )
                    }
                    validator={[
                      {
                        required: true,
                        error: 'Pflichtfeld ist nicht ausgefüllt.',
                      },
                    ]}
                  />
                  <div className="exportStatistic-button-area">
                    <Button
                      icon={faArrowRight}
                      onClick={this.showPreview}
                      type="primary"
                    >
                      Vorschau
                    </Button>
                  </div>
                </ContentBox>
              )}
              {this.state.secondStep && (
                <ContentBox title="Vorschau">
                  <div className="exportStatistic-button-area">
                    <Button icon={faPrint} onClick={this.save} type="primary">
                      Drucken
                    </Button>
                    <Button
                      icon={faArrowLeft}
                      onClick={() =>
                        this.setState({
                          firstStep: true,
                          secondStep: false,
                        })
                      }
                      type="primary"
                    >
                      Zurück zur Eingabe
                    </Button>
                  </div>

                  <object
                    data={window.URL.createObjectURL(this.state.documentBlob)}
                    type="application/pdf"
                    width="100%"
                    height="800"
                  >
                    <p>
                      Alternative text - include a link{' '}
                      <a
                        href={window.URL.createObjectURL(
                          this.state.documentBlob,
                        )}
                      >
                        to the PDF!
                      </a>
                    </p>
                  </object>
                </ContentBox>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CaseStatisticsNewEntry);
