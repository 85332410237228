import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import swal from '@sweetalert/with-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import settings from '../../../../settings';
import Button from '../../../components/Button';
import HeaderbarButton from '../../../components/HeaderbarButton';
import api from '../../../logic/api';
import SpecialistForm from './SpecialistForm';

class SpecialistsNew extends Component {
  constructor(props) {
    super(props);
    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      saving: false,
    };
  }

  save = async () => {
    this.setState({ saving: true });
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      this.setState({ saving: false });
      return;
    }
    const SpecialistData = this.form.dataContainer.getStringified();
    api('POST', settings.supplynetwork.specialists.save, {
      specialistAddress: SpecialistData,
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Die Adresse wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.setState({ saving: false });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
        this.setState({ saving: false });
      });
  };

  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/Specialists">Fachärzte</Link> - <b>Neu</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button
              type="primary"
              icon={faSave}
              onClick={this.save}
              loading={this.state.saving}
            >
              Speichern
            </Button>
          </div>
          <SpecialistForm
            ref={(node) => {
              return (this.form = node);
            }}
          />
          <div className="Buttonbar-bottom">
            <Button
              className="Button-bigger"
              loading={this.state.saving}
              type="primary"
              icon={faSave}
              onClick={this.save}
              outline
            >
              Speichern
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SpecialistsNew);
