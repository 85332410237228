import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail/Detail';
import Overview from './Overview';
import New from './New';

const Supplynetwork = (props) => {
  const paths = {
    new: `${props.match.path}/new`,
    details: `${props.match.path}/detail/:guid`,
    search: `${props.match.path}/search/:search?`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={New} />
      <Route path={paths.search} component={Overview} />
      <Route path={paths.details} component={Details} />
      <Route component={Overview} />
    </Switch>
  );
};

export default withRouter(Supplynetwork);
