import React, { Component } from 'react';

class Popup extends Component {
  handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onClose();
    }
  };

  render() {
    const { children, onClose } = this.props;

    return (
      <div
        className="popup-overlay"
        onClick={onClose}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex={0}
        aria-label="Close popup"
      >
        <div
          className="popup-content"
          // onClick={(e) => e.stopPropagation()}
          // role="dialog"
          // aria-modal="true"
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Popup;
