import React, { Component } from 'react';

class ContentBox extends Component {
  render() {
    return (
      <div className="FormArea-ContentBox">
        {this.props.title && (
          <p className="FormArea-ContentBox-title">{this.props.title}</p>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default ContentBox;
