import React, { Component } from 'react';
import Loader from '../components/Loader';

const UserContext = React.createContext();

class UserProvider extends Component {
  // Context state
  state = {
    userdata: null,
    loadingUser: true,
    isAdmin: false,
    isManagementSpecial: false,
    isManagement: false,
  };
  // Method to update state
  setUser = (userdata) => {
    this.setState({
      userdata,
    });
  };

  // Run functions
  componentDidMount() {
    if (this.state.userdata === null) {
      try {
        const userdata = JSON.parse(localStorage.getItem('userdata'));
        this.setUser(userdata);
      } catch (e) {
        // no userdata in local storage
        console.warn('no userdata in local storage!');
      }
    }
    this.setState({ loadingUser: false });
  }

  componentDidUpdate(_, prevState) {
    if (this.state.userdata !== prevState.userdata) {
      this.checkRights(this.state.userdata);
    }
  }

  async checkRights(userdata) {
    const { role } = userdata;
    if (role) {
      this.setState({
        isAdmin: role === '0a158da1-d60d-4a56-8e22-b7d0d48e2d75',
        isManagementSpecial: role === '7debbd12-eb9c-47bc-9856-fc06f5fd984a',
        isManagement: role === '1682882d-1880-4562-aa22-7ff87664153e',
        loadingUser: false,
      });
    } else {
      this.setState({
        isAdmin: true, // Todo: set to false after
        isManagementSpecial: false,
        isManagement: false,
        loadingUser: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const {
      userdata,
      loadingUser,
      isAdmin,
      isManagementSpecial,
      isManagement,
    } = this.state;
    const { setUser } = this;

    if (this.state.isAdmin && this.state.loadingUser) {
      return <Loader />;
    }
    return (
      <UserContext.Provider
        value={{
          userdata,
          setUser,
          loadingUser,
          isAdmin,
          isManagementSpecial,
          isManagement,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserContext;

export { UserProvider };
