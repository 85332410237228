import React from 'react';
import reqwest from 'reqwest';
import { Redirect } from 'react-router-dom';

import swal from 'sweetalert';
import sha512 from 'sha512';
import PasswordValidator from '../../components/PasswordValidator';
import logo from '../../../images/logo.png';

import settings from '../../../settings';

class ChangePasswordDetail extends React.Component {
  constructor(props) {
    super(props);

    let hasChangePasswordToken = true;

    if (localStorage.getItem('tokenChangePassword')) {
      hasChangePasswordToken = false;
    }

    this.state = {
      hasChangePasswordToken,
      passwordFocus: false,
      passwordsMatch: false,
      isValidPassword: false,
    };
  }

  _submit = () => {
    reqwest({
      method: 'post',
      url: settings.usermanagement.general.saveNewUserPassword,
      data: {
        Token: localStorage.getItem('tokenChangePassword'),
        Password: sha512(this.state.password).toString('hex'),
      },
    }).then((passwordResult) => {
      try {
        passwordResult = JSON.parse(passwordResult);
        if (passwordResult.Params.SaveResult === 'Updated') {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Passwort wurde erfolgreich geändert.',
            icon: 'success',
          }).then(() => {
            reqwest({
              method: 'get',
              url: settings.general.logout,
              data: {
                Token: localStorage.getItem('tokenChangePassword'),
              },
            }).then(() => {
              localStorage.clear();
              this.props.history.push('/Login');
            });
          });
        }
      } catch (e) {
        swal({
          title: 'Fehlgeschlagen!',
          text: 'Fehler:' + passwordResult,
          icon: 'error',
        });
      }
    });
  };

  _change = () => {
    // Needed for Password Validator, needs State to Change
    this.setState({
      password: this.password?.value,
    });
  };

  _isPasswordValid = (isValid) => {
    this.setState({
      isValidPassword: isValid,
    });
  };

  _passwordFocus = () => {
    this.setState({
      passwordFocus: !this.state.passwordFocus,
    });
  };

  _changePasswordRepeat = () => {
    const originalPasswordValue = this.password.value;
    const { value } = this.password_repeat;

    this.setState({
      passwordsMatch: originalPasswordValue === value,
    });
  };

  render() {
    if (this.state.hasChangePasswordToken) {
      return <Redirect to="/Login" />;
    }

    return (
      <div className="Login-Form-box">
        <img src={logo} alt="nCare" className="Login-Form-logo" />
        <div className="Login-Form-title Login-Form-title--changePassword">
          Neues Passwort eingeben
        </div>
        <div className="Login-Form-description">
          Sie melden sich das erste mal bei der Care-DB an.
          <br />
          Bitte wählen Sie ein neues Passwort.
        </div>
        <div className="Login-Form radius">
          <input
            name="password"
            type="password"
            autoComplete="new-password"
            placeholder="Neues Passwort"
            className="field field-border-bottom radius-top"
            onKeyUp={this._change}
            onFocus={this._passwordFocus}
            onBlur={this._passwordFocus}
            ref={(node) => {
              this.password = node;
            }}
          />
          {this.state.passwordFocus && (
            <PasswordValidator
              value={this.state.password}
              onChangeValid={this._isPasswordValid}
            />
          )}
          <input
            name="password"
            type="password"
            autoComplete="new-password"
            placeholder="Neues Passwort wiederholen"
            className="field field-border-bottom"
            onKeyUp={this._changePasswordRepeat}
            ref={(node) => {
              this.password_repeat = node;
            }}
          />
          <input
            name="forgot"
            disabled={!this.state.isValidPassword && !this.state.passwordsMatch}
            type="submit"
            value="Abschicken"
            onClick={this._submit}
            className="Login-submit"
          />
        </div>
        <div className="Login-Form-actions">
          <button
            type="button"
            className="Link Link--underlined"
            onClick={() => {
              this.props.history.push('/Login');
            }}
          >
            Zurück zum Login
          </button>
        </div>
      </div>
    );
  }
}

export default ChangePasswordDetail;
