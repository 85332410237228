import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PrintCaseFile from './PrintCaseFile';

const CaseFile = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/:clientGuid`}
        component={PrintCaseFile}
      />
    </Switch>
  );
};

export default withRouter(CaseFile);
