import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Inputs/Dropdown';
import getConstants from '../logic/constants';

class Treatments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      Constants: [],
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      Constants: await getConstants('Treatments'),
    });
  }

  handleChangeMainFocus(values = []) {
    if (this.props.onChange) {
      this.props.onChange(values);
    }
    this.setState({ values: values });
  }

  render() {
    return (
      <Dropdown
        name={this.props.name}
        label={this.props.label}
        info={this.props.info}
        fullWidth
        isMulti
        isClearable
        isSearchable
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.values}
        options={this.state.Constants}
        getSelected={(values) => {
          this.handleChangeMainFocus(values);
        }}
      />
    );
  }
}

Treatments.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Treatments.defaultProps = {
  name: '',
  label: '',
  info: '',
  values: [],
  isDisabled: false,
  onChange: null,
};

export default Treatments;
