import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Overview from './tabs/index';

const Systemmangement = () => {
  return (
    <Switch>
      <Route component={Overview} />
    </Switch>
  );
};

export default withRouter(Systemmangement);
