import React from 'react';
import PropTypes from 'prop-types';
import TimeRange from './Inputs/TimeRange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

class OfficeHours extends React.Component {
  emptyOfficeHours = [
    [
      { id: 'mon', from: '', to: '' },
      { id: 'tue', from: '', to: '' },
      { id: 'wed', from: '', to: '' },
      { id: 'thr', from: '', to: '' },
      { id: 'fri', from: '', to: '' },
    ],
    [
      { id: 'mon', from: '', to: '' },
      { id: 'tue', from: '', to: '' },
      { id: 'wed', from: '', to: '' },
      { id: 'thr', from: '', to: '' },
      { id: 'fri', from: '', to: '' },
    ],
    [
      { id: 'mon', from: '', to: '' },
      { id: 'tue', from: '', to: '' },
      { id: 'wed', from: '', to: '' },
      { id: 'thr', from: '', to: '' },
      { id: 'fri', from: '', to: '' },
    ],
  ];
  constructor(props) {
    super(props);
    this.state = {
      officeHours: this.parse(this.props.officeHours),
      showPicker: false,
    };
  }

  handleChange(rowIndex, colIndex, from, to) {
    const newOfficeHours = this.state.officeHours;
    newOfficeHours[rowIndex][colIndex].from = from;
    newOfficeHours[rowIndex][colIndex].to = to;
    this.setState({ officeHours: newOfficeHours });
    if (this.props.onChange) {
      this.props.onChange(this.stringify(newOfficeHours));
    }
  }

  stringify(slots) {
    const rows = slots.map((row) => {
      const pairs = row.map((slot) => {
        return `${slot.from},${slot.to}`;
      });
      return pairs.join(';');
    });
    return rows.join(';');
  }

  parse(s) {
    if (s && s.length > 11) {
      const datePairs = s.split(';');
      const officeHours = this.emptyOfficeHours;
      datePairs.forEach((datePair, index) => {
        const dates = datePair.split(',');
        const rowIndex = Math.floor(index / 5);
        const slotIndex = index % 5;
        officeHours[rowIndex][slotIndex].from = dates[0];
        officeHours[rowIndex][slotIndex].to = dates[1];
      });
      return officeHours;
    } else {
      return this.emptyOfficeHours;
    }
  }

  render() {
    return (
      <div name={this.props.name} className="OfficeHours">
        <div className="OfficeHours-label">
          {this.props.label}
          {this.props.info && (
            <div className="Input-info">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="tooltiptext">{this.props.info}</span>
            </div>
          )}
        </div>
        <div className="OH-Input">
          <div className="OH-Table">
            <div className="OH-Table-head">
              <div className="OH-Table-body-label">{''}</div>
              <div className="OH-Table-head-field">Mo</div>
              <div className="OH-Table-head-field">Di</div>
              <div className="OH-Table-head-field">Mi</div>
              <div className="OH-Table-head-field">Do</div>
              <div className="OH-Table-head-field">Fr</div>
            </div>
            {this.state.officeHours && (
              <div className="OH-Table-body">
                {this.state.officeHours.map((row, rowIndex) => {
                  return (
                    <div key={'row' + rowIndex} className="OH-Table-body-row">
                      <div className="OH-Table-body-label">Zeit von/bis</div>
                      {row.map((col, i) => {
                        return (
                          <div
                            key={col.id}
                            className={`OH-Table-body-data ${col.from ? 'filled' : ''
                              }`}
                          >
                            <TimeRange
                              isDisabled={this.props.isDisabled}
                              from={col.from}
                              to={col.to}
                              onChange={({ from, to }) => {
                                this.handleChange(rowIndex, i, from, to);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

OfficeHours.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  /**
   * #### Format:
   * Stringified date pair list. List contains a max of 3 * 5 date pairs, formatted DD.MM.YYYY.
   * Two dates are split by ',' and form a date pair. First date in pair is 'from' and second 'to'.
   * Date pairs are split by ';'. 5 date pairs form a week form Mo. - Fr.
   * Empty Dates are represented semicolon ';;' with no content.
   */
  officeHours: PropTypes.string,
  isDisabled: PropTypes.bool,
};

OfficeHours.defaultProps = {
  name: '',
  label: '',
  info: '',
  officeHours: '',
  isDisabled: false,
};

export default OfficeHours;
