import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import moment from 'moment';

class ResubmissionNewEntry extends Component {
  constructor(props) {
    super(props);
    this.guid = this.props.match.params.guid;
    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.state = {
      loading: false,
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Resubmission', {
      Typ: 'Resubmission',
      AmbPsyGuid: this.guid,
      DateOfResub: '',
      Reason: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    // TODO: send DC in List/Array after middelware changed it!
    const oneDataContainer = JSON.stringify({
      ParamName: 'Resubmission',
      Params: this.dataContainer.get('Resubmission'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        // container: this.dataContainer.getStringified(), // TODO: use this when in middelware fixed! DataContain should always be in Array/List
        action: 'Neue Wiedervorlage angelegt',
      },
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/AmbulatoryPsychotherapy">
              Ambulante Psychotherapie
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Wiedervorlagen</Link>-{' '}
            <b>Neue Wiedervorlage</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faTimes}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button icon={faSave} onClick={this.save}>
              Speichern
            </Button>
          </div>
          <Form
            ref={(node) => {
              return (this.form = node);
            }}
          >
            <ContentBox title="Wiedervorlage">
              <Input
                type="date"
                validator={[
                  {
                    required: true,
                    error: 'Bitte Datum eingeben',
                  },
                  {
                    date: true,
                    error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set(
                    'Resubmission',
                    'DateOfResub',
                  )(moment(value).format('YYYY-MM-DD'));
                }}
                defaultValue={this.dataContainer.get(
                  'Resubmission',
                  'DateOfResub',
                )}
                label="Datum der Wiedervorlage"
                name="DateOfResub"
              />
              <Input
                type="textarea"
                validator={[]}
                onChange={(value) => {
                  this.dataContainer.set('Resubmission', 'Reason')(value);
                }}
                defaultValue={this.dataContainer.get('Resubmission', 'Reason')}
                label="Grund"
                name="Reason"
              />
            </ContentBox>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(ResubmissionNewEntry);
