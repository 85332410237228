import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import api from '../../../logic/api';
import {
  faPlus,
  faMap,
  faList,
  faMapMarker,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import history from '../../../history';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Searchbar from '../../../components/Searchbar';
import Button from '../../../components/Button';

import Map from '../../../components/Map';
import HeaderbarButton from '../../../components/HeaderbarButton';

class SpecialistsOverview extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: false,
      listView: true,
      data: [],
      searchString,
      searchLatLon: null,
      radius: '',
    };
  }

  loadData = () => {
    this.setState({
      data: [],
      loading: true,
    });
    api('get', settings.supplynetwork.specialists.getList)
      .then((res) => {
        this.setState({
          data: JSON.parse(res).Entries,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  _search = (value) => {
    const val = value.toLowerCase();

    history.replace(
      val
        ? `/supplynetwork/Specialists/search/${val}`
        : '/supplynetwork/Specialists',
    );

    this.setState({
      searchString: val,
    });
  };

  _streetSearch = (value) => {
    this.geocodingClient
      .forwardGeocode({
        query: value,
      })
      .send()
      .then((response) => {
        const match = response.body;
        if (!match.features.length) return;

        this.setState({
          searchLatLon: match.features[0].center,
        });
      });
  };

  _changeRadius = (value) => {
    this.setState({
      radius: value,
    });
  };

  _changeView = () => {
    this.setState({
      listView: !this.state.listView,
    });
  };

  _changeFilter = (filterName, filterValue) => {
    this.setState((prevState) => {
      return {
        filters: {
          ...prevState.filters,
          [filterName]: filterValue || '',
        },
      };
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader text="Fachärzte werden geladen..." />;
    }

    let data = [];
    if (this.state.data.length) {
      data = this.state.data.filter((rowData) => {
        return [
          rowData.Lastname,
          rowData.Firstname,
          rowData.Street,
          rowData.ZIP,
          rowData.City,
          rowData.PhoneNumber,
          rowData.Mail,
        ]
          .map((e) => {
            return e ? e.toLowerCase() : '';
          })
          .some((v) => {
            return v ? v.indexOf(this.state.searchString) >= 0 : false;
          });
      });
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk - <b>Fachärzte</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
            {this.state.listView ? (
              <HeaderbarButton
                icon={faMap}
                type="primary"
                onClick={this._changeView}
              >
                Kartenansicht
              </HeaderbarButton>
            ) : (
              <HeaderbarButton
                icon={faList}
                type="primary"
                onClick={this._changeView}
              >
                Listenansicht
              </HeaderbarButton>
            )}
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink
                to="/supplynetwork/AmbulatoryPsychotherapy"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Ambulante Psychotherapie
              </NavLink>
              <NavLink
                to="/supplynetwork/Clinics"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Kliniken
              </NavLink>
              <NavLink
                to="/supplynetwork/Specialists"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Fachärzte
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button icon={faPlus} to="/supplynetwork/Specialists/new">
                  Neue Adresse anlegen
                </Button>
              </div>
              <div className="Toolbar">
                {this.state.listView ? (
                  <Searchbar
                    key="searchString"
                    placeholder="Volltextsuche"
                    defaultValue={this.state.searchString}
                    onChange={this._search}
                  />
                ) : (
                  <Searchbar
                    key="searchLatLon"
                    icon={faMapMarker}
                    placeholder="Startadresse oder PLZ eingeben"
                    onChange={() => {}}
                    onBlur={this._streetSearch}
                  />
                )}
                {!this.state.listView && (
                  <Searchbar
                    disabled={!this.state.searchLatLon}
                    placeholder="Umkreis in km"
                    size="small"
                    onChange={this._changeRadius}
                  />
                )}
                <div className="Toolbar-buttons"></div>
              </div>
              {this.state.listView ? (
                <ReactTable
                  getTrProps={(_, val) => {
                    return {
                      onClick: () => {
                        return this.props.history.push(
                          `/supplynetwork/Specialists/detail/${val?.original?.Guid}`,
                        );
                      },
                    };
                  }}
                  defaultSorted={[
                    {
                      id: 'City',
                      desc: false,
                    },
                  ]}
                  data={data}
                  pageSize={data.length}
                  noDataText="Es wurden keine Adressen gefunden."
                  columns={[
                    {
                      accessor: 'Lastname',
                      Header: 'Name',
                    },
                    {
                      accessor: 'Firstname',
                      Header: 'Vorname',
                    },
                    {
                      accessor: 'Street',
                      Header: 'Straße',
                    },
                    {
                      accessor: 'ZIP',
                      Header: 'PLZ',
                    },
                    {
                      accessor: 'City',
                      Header: 'Ort',
                    },
                    {
                      accessor: 'PhoneNumber',
                      Header: 'Telefon',
                    },
                    {
                      accessor: 'Mail',
                      Header: 'E-Mail Adresse',
                    },
                  ]}
                />
              ) : (
                <Map
                  markers={data}
                  streetPoint={this.state.searchLatLon}
                  radius={this.state.radius}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SpecialistsOverview;
