const settings = {};

const URL = `${window.location.origin}/api`;

settings.general = {
  login: `${URL}/Home/Login`,
  checkToken: `${URL}/Home/CheckToken`,
  logout: `${URL}/Home/Logout`,
  sendFeedback: `${URL}/Home/SendFeedback`,
};

settings.validators = {
  email: `${URL}/Validate/ValidateEmail`,
  username: `${URL}/Validate/ValidateUsername`,
  expnr: `${URL}/Validate/ValidateExpnr`,
  ccr: `${URL}/Validate/ValidateCcr`,
  agencynumber: `${URL}/Validate/ValidateAgencynumber`,
};

settings.constants = {
  getList: `${URL}/Constants/GetConstantsList`,
  getTypes: `${URL}/Constants/GetConstantsTypes`,
  save: `${URL}/Constants/SaveConstantsItem`,
  delete: `${URL}/Constants/RemoveConstant`,
};

settings.systemmanagement = {
  role: {
    get: `${URL}/Systemmanagement/GetRole`,
    save: `${URL}/Systemmanagement/SaveRole`,
    delete: `${URL}/Systemmanagement/RemoveRole`,
    getList: `${URL}/Systemmanagement/GetRoles`,
  },
  emailtemplate: {
    get: `${URL}/Systemmanagement/GetEmailtemplate`,
    save: `${URL}/Systemmanagement/SaveEmailtemplate`,
    delete: `${URL}/Systemmanagement/RemoveEmailtemplate`,
    getList: `${URL}/Systemmanagement/GetEmailtemplates`,
  },
};

settings.permissions = {
  GetPermission: `${URL}/Permission/GetPermission`,
  GetAllRoles: `${URL}/Permission/GetAllRoles`,
  AddRole: `${URL}/Permission/AddRole`,
};

settings.usermanagement = {
  general: {
    get: `${URL}/User/GetUser`,
    save: `${URL}/User/SaveUser`,
    delete: `${URL}/User/DeleteUser`,
    getList: `${URL}/User/GetAllUserList`,
    getUserForPosition: `${URL}/User/GetUserForPosition`,
    saveNewUserPassword: `${URL}/User/SaveNewUserPassword`,
    getUsersInSameDepartment: `${URL}/User/GetUsersInSameDepartment`,
  },
};

settings.excel = {
  getExcel: `${URL}/excel/WriteToExcel`,
  getExportStatistics: `${URL}/excel/GenerateStatisticExport`,
};

settings.supplynetwork = {
  getCoordsToZip: `${URL}/supplynetwork/GetCoordinates`,
  ambulatoryPsychotherapy: {
    get: `${URL}/supplynetwork/GetAmbulatoryPsychotherapy`,
    save: `${URL}/supplynetwork/SaveAmbulatoryPsychotherapyAdress`,
    remove: `${URL}/supplynetwork/DeleteAmbulatoryPsychotherapy`,
    getList: `${URL}/supplynetwork/GetListAmbulatoryPsychotherapy`,
    lockAllAmbulatoryEntries: `${URL}/supplynetwork/LockAllAmbulatoryEntries`,
    unlockAllAmbulatoryEntries: `${URL}/supplynetwork/UnlockAllAmbulatoryEntries`,
    // getShortList: `${URL}/supplynetwork/GetShortListAmbulatoryPsychotherapy`,
  },
  clinics: {
    get: `${URL}/supplynetwork/GetClinics`,
    save: `${URL}/supplynetwork/SaveClinicsAdress`,
    remove: `${URL}/supplynetwork/DeleteClinic`,
    getList: `${URL}/supplynetwork/GetClinicsList`,
  },
  specialists: {
    get: `${URL}/supplynetwork/GetSpecialist`,
    save: `${URL}/supplynetwork/SaveSpecialistAdress`,
    remove: `${URL}/supplynetwork/DeleteSpecialist`,
    getList: `${URL}/supplynetwork/GetListSpecialist`,
  },
};

settings.statistic = {
  getArchivePrintout: `${URL}/Statistic/GetArchivePrintout`,
  save: `${URL}/Statistic/SaveNew`,
  get: `${URL}/Statistic/Get`,
};

settings.clientmanagement = {
  client: {
    /**
     * GET
     * GetFilteredClientList (string columns)
     * – bekommt ein Dictionary<string, string> mit den Feldern übergeben, die im Ergebnis enthalten sein sollen.
     * Es wird nicht nach bestimmten Werten in den Feldern gesucht.
     * Gibt eine Liste mit Klienten in Form einer Liste mit DataContainern als JSON-String zurück,
     * wo nur die übergebenen Felder enthalten sind, die vorher übergeben wurden. */
    getFilteredClientList: `${URL}/clientdata/GetFilteredClientList`,
    /**
     * POST
     * - bekommt die Daten in Form eines DataContainers als JSON-String und die action als String übergeben.
     * Es ist noch unbekannt, was alles an Daten übergeben wird. Versendet eine E-Mail mit einem Termin (iCal) als Anhang.
     * Gibt ein Ergebnis zurück, ob die Daten angelegt wurden.
     */
    askForAppointment: `${URL}/clientdata/AskForAppointment`,

    /**
     * POST
     * – bekommt die clientGuid, die action als String übergeben und den format als String.
     * Je nach Format wird die Datei (Stammblatt) im .pdf oder .docx Format als FileStreamResult zurückgegeben.
     */
    createCaseFile: `${URL}/clientdata/CreateCaseFile`,

    /**
     * POST
     * – bekommt die Daten in Form eines DataContainers als JSON-String, die action als String, den format als String und document als String übergeben.
     */
    assignTherapyPlaceTexts: `${URL}/clientdata/AssingTherapyPlaceTexts`,

    /**
     * POST
     */
    assignTherapyPlaceFile: `${URL}/clientdata/AssignTherapyPlaceFile`,

    /**
     * POST
     */
    assignTherapyPlaceFileReview: `${URL}/clientdata/AssignTherapyPlaceFilePreview`,

    /**
     * legacy code!
     * POST
     *  – bekommt die Daten in Form eines DataContainers als JSON-String, die action als String, den format als String und document als String übergeben.
     * Die benötigten Daten sind in dem Dokument Objektstrukturen deklariert.
     * Mit document kann man ein einzelnes oder alle (alle Dokumente werden hintereinander zusammengefügt und zu einem Dokument) Dokumente als eine Datei erstellt,
     * die je nach Format im .pdf oder .docx Format als FileStreamResult zurückgegeben wird.
     */
    assignTherapyPlace: `${URL}/clientdata/AssignTherapyPlace`,

    /**
     * POST
     * – bekommt die Guid des Klienten und die action als String übergeben.
     * Gibt zurück, ob die Daten gespeichert/gelöscht/archiviert wurden.
     */
    setCaseDone: `${URL}/clientdata/SetCaseDone`,

    reopenCase: `${URL}/clientdata/ReopenCase`,
  },
  dataContainer: {
    /**
     * GET
     * GetSingleContainer(string guid)
     * – bekommt die Guid des Datensatzes übergeben.
     * Gibt den entsprechenden Datensatz in Form eines DataContainers als JSON-String zurück.
     */
    get: `${URL}/clientdata/GetSingleContainer`,
    /**
     * POST
     * SaveSingleContainer([FromForm] string container)
     * – bekommt die Daten in Form eines DataContainers als JSON-String.
     * Es muss unbedingt der Typ (z-B. „Client“) enthalten sein! Gibt ein Ergebnis zurück, ob die Daten angelegt wurden.
     */
    save: `${URL}/clientdata/SaveSingleContainer`,
    /**
     * POST
     * DeleteSingleContainer(string guid)
     * – bekommt die Guid des zu löschenden Datensatzes.
     */
    remove: `${URL}/clientdata/DeleteSingleContainer`,
    /**
     * GET
     * GetSingleContainerList(string parameter)
     * – bekommt ein Dictionary<string, string> mit den Feldern und den entsprechenden Werten übergeben,
     * nach denen gefiltert/gesucht werden soll (es besteht eine UND-Verknüpfung zwischen den Werten).
     * Gibt eine Liste mit Datensätzen von einem bestimmten Typen in Form einer Liste
     * mit DataContainern als JSON-String zurück.
     */
    getList: `${URL}/clientdata/GetSingleContainerList`,
  },
  history: {
    getList: `${URL}/HistoryData/GetHistoryDataForClient`,
  },
};

settings.forgot = {
  new: `${URL}/Password/ForgotPassword`,
  check: `${URL}/Password/CheckPasswordToken`,
  save: `${URL}/Password/SaveNewPassword`,
};

settings.external = {
  weather:
    'https://api.openweathermap.org/data/2.5/weather?APPID=0688238fdcb01cd3eb3fb2ed63081027&units=metric&q=',
  zip: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=postleitzahlen-deutschland&rows=1&q=',
  iban: 'https://fintechtoolbox.com/validate/iban.json',
  blz: 'https://fintechtoolbox.com/bankcodes/',
  iban_apikey:
    'cO5zWWoaz4K0AgH0Y0bZ75XuCdItc1Mb8I0dgxscTFNrE_9nRJo1ebuLExwnXIvi',
  froala_key:
    process.env.NODE_ENV === 'production'
      ? 'yDC5hH4C2A6C5C5A5F5g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4H3F2H3A7A4E5C4=='
      : 'yDC5hH4C2A6C5C5A5F5g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4H3F2H3A7A4E5C4==',
  mapbox_key:
    'pk.eyJ1IjoibWFya3RwbGF0emdtYmgiLCJhIjoiY2s4b2o1cTZuMDMyNzNtc2I5bzFuaDFkYiJ9.0q2FtDlcfhAzDMz6kHOKWQ',
};

settings.FILEPOND_LABELS = {
  labelIdle:
    'Klicken Sie <u><strong>hier</strong></u> um eine Datei hochzuladen oder ziehen Sie diese auf dieses Feld.',
  labelFileProcessing: 'Hochladen ...',
  labelFileProcessingComplete: 'Datei wurde hochgeladen!',
  labelFileProcessingAborted: 'Upload abgebrochen.',
  labelFileProcessingError: 'Upload fehlgeschlagen.',
  labelTapToCancel: 'Abbrechen',
  labelTapToRetry: 'Erneut versuchen?',
  labelTapToUndo: 'Rückgänig machen?',
  labelButtonRemoveItem: 'Löschen',
  labelButtonRetryItemLoad: 'Erneut versuchen?',
  labelButtonAbortItemLoad: 'Abbrechen',
  labelButtonAbortItemProcessing: 'Abbrechen',
  labelButtonUndoItemProcessing: 'Rückgänig machen?',
  labelButtonRetryItemProcessing: 'Erneut versuchen?',
  labelButtonProcessItem: 'Hochladen',
};

export default settings;
