import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import DataContainer from '../../../../logic/dataContainer';

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);

    this.roleGuid = props.match.params.id;
    this.parentRoute = this.props.match.url.replace(/\/Roles\/.*/, '/Roles');

    this.state = {
      loading: true,
      saving: false,
    };
  }

  componentDidMount() {
    reqwest({
      method: 'GET',
      url: settings.permissions.GetPermission,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.roleGuid,
      },
    }).then((roleResult) => {
      this.dataContainer = new DataContainer(roleResult);
      this.setState({ loading: false });
    });
  }

  save = () => {
    swal({
      title: 'Sind Sie sicher?',
      text: 'Das ändern dieser Einstellungen ändert die Rechte für die Komplette Anwendung. Eventuell haben Sie danach keinen Zugriff auf gewisse Bereiche mehr.',
      icon: 'warning',
      buttons: ['Abbrechen', 'Speichern'],
      dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        this.setState({ saving: true });
        reqwest({
          method: 'POST',
          url: settings.permissions.AddRole,
          data: {
            Token: localStorage.getItem('token'),
            Role: this.dataContainer.getStringified(),
          },
        }).then((_) => {
          swal({
            title: 'Erfolgreich!',
            text: 'Die Rechte wurden erfolgreich gespeichert!',
            icon: 'success',
          });
          this.setState({ saving: false });
        });
      }
    });
  };

  render() {
    if (this.state.loading || this.state.saving) {
      return <Loader />;
    }

    return (
      <div className="Page-Content">
        <div className="Buttonbar">
          <Button type="primary" icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </div>
        <br />
        <ContentBox title="Rolle">
          <Input
            type="text"
            label="Name:"
            validator={[
              {
                required: true,
                error: 'Bitte füllen Sie dieses Feld aus',
              },
            ]}
            onChange={(value) => {
              this.dataContainer.set('Entry', 'Name')(value);
            }}
            defaultValue={this.dataContainer.get('Entry', 'Name')}
            isDisabled
            name="Name"
          />
          <Input
            type="text"
            label="Bemerkung:"
            validator={[]}
            onChange={(value) => {
              this.dataContainer.set('Entry', 'Bemerkung')(value);
            }}
            defaultValue={this.dataContainer.get('Entry', 'Bemerkung')}
            name="Bemerkung"
          />
        </ContentBox>
      </div>
    );
  }
}

export default RoleDetail;
