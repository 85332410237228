import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Basedata from './tabs/Basedata/index';

const SupplynetworkClinics = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={() => {
          return <Basedata UserGuid={match.params.userId} />;
        }}
      />
      <Route
        render={() => {
          return <Basedata UserGuid={match.params.userId} />;
        }}
      />
    </Switch>
  );
};

SupplynetworkClinics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default SupplynetworkClinics;
