import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Change from './Change';

const ChangePassword = () => {
  return (
    <Switch>
      <Route component={Change} />
    </Switch>
  );
};

export default withRouter(ChangePassword);
