import React, { Component } from 'react';
import reqwest from 'reqwest';

import swal from 'sweetalert';
import settings from '../../../settings';
import validate from '../../logic/validator';

import logo from '../../../images/logo.png';

class Forgot extends Component {
  constructor(props) {
    super(props);

    this.validators = {
      mail: [
        {
          required: true,
          error: 'Bitte geben Sie Ihre E-Mail ein.',
        },
        {
          type: 'email',
          error: 'Bitte geben Sie eine gültige E-Mail ein.',
        },
      ],
    };
  }

  _forgot = async () => {
    const email = this.mail.value;

    let validator = {
      isValid: true,
      error: '',
    };

    validator = validator.isValid
      ? await validate(email, this.validators.mail)
      : validator;

    if (!validator.isValid) {
      swal({
        title: 'Fehler!',
        text: validator.error,
        icon: 'error',
      });
      return;
    }

    reqwest({
      method: 'GET',
      url: settings.forgot.new,
      data: {
        Email: email,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text:
          'Wir haben eine E-Mail an Ihre E-Mail geschickt um Ihr Passwort zurück zu setzen. Bitte überprüfen Sie auch Ihren Spam-Ordner.',
        icon: 'success',
      }).then(() => {
        this.props.history.push('/Login');
      });
    });
  };

  render() {
    return (
      <div className="Login-Form-box">
        <img
          src={logo}
          alt="alltours Reisecenter"
          className="Login-Form-logo"
        />
        <div className="Login-Form-title">Passwort vergessen</div>
        <div className="Login-Form radius">
          <input
            name="mail"
            autoComplete="email"
            placeholder="Ihre E-Mail Adresse"
            className="field field-border-bottom radius-top"
            ref={(node) => {
              this.mail = node;
            }}
          />
          <input
            name="forgot"
            type="submit"
            value="Abschicken"
            onClick={this._forgot}
            className="Login-submit"
          />
        </div>
        <div className="Login-Form-actions">
          <button
            type="button"
            className="Link Link--underlined"
            onClick={() => {
              this.props.history.push('/Login');
            }}
          >
            Zurück zum Login
          </button>
        </div>
      </div>
    );
  }
}

export default Forgot;
