export function deepParseJson(jsonString) {
  // if not stringified json rather a simple string value then JSON.parse will throw error
  // otherwise continue recursion
  if (typeof jsonString === 'string') {
    if (!isNaN(Number(jsonString))) {
      // if a numeric string is received, return itself
      // otherwise JSON.parse will convert it to a number
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    // if an array is received, map over the array and deepParse each value
    return jsonString.map((val) => {
      return deepParseJson(val);
    });
  } else if (typeof jsonString === 'object' && jsonString !== null) {
    // if an object is received then deepParse each element in the object
    // typeof null returns 'object' too, so we have to eliminate that
    return Object.keys(jsonString).reduce((obj, key) => {
      obj[key] = deepParseJson(jsonString[key]);
      return obj;
    }, {});
  } else {
    // otherwise return whatever was received
    return jsonString;
  }
}

class DataContainer {
  constructor(jsonContainer = '[]') {
    this.containers = deepParseJson(jsonContainer);
    this.initialContainers = deepParseJson(jsonContainer);
    if (typeof this.containers !== 'object') {
      this.containers = [];
      this.initialContainers = [];
    }
  }

  _get(containerToUse, containerName, ...path) {
    if (containerName) {
      const container = containerToUse.find((container) => {
        const PN = container.ParamName || container.paramName;
        return PN === containerName;
      });

      if (!container) return undefined;

      let value = container.Params || container.params;
      path.forEach((segment) => {
        if (value && segment in value) {
          value = value[segment];
        } else {
          value = undefined;
        }
      });
      return value;
    }
    return containerToUse;
  }

  get(containerName, ...path) {
    return this._get(this.containers, containerName, ...path);
  }

  getInitial(containerName, ...path) {
    return this._get(this.initialContainers, containerName, ...path);
  }

  getMany(containerName) {
    if (containerName) {
      const containers = this.containers.filter((container) => {
        const PN = container.ParamName || container.paramName;
        return PN === containerName;
      });

      if (containers && containers.length) {
        return containers.map((container) => {
          return container.Params || container.params;
        });
      }

      return [];
    }
  }

  getStringified() {
    const containers = this.containers.map((container) => {
      const Params = {};
      if (container.Params && Object.keys(container.Params).length) {
        Object.keys(container.Params).forEach((paramKey) => {
          const value = container.Params[paramKey];
          if (typeof value === 'string') {
            Params[paramKey] = value;
          } else {
            Params[paramKey] = JSON.stringify(value);
          }
        });
      }
      return {
        ...container,
        Params,
      };
    });
    return JSON.stringify(containers);
  }

  set(containerName, ...pathArray) {
    if (containerName) {
      let containerIndex = this.containers.findIndex((container) => {
        return container.ParamName === containerName;
      });
      if (containerIndex < 0) {
        this.add(containerName);
        containerIndex = this.containers.length - 1;
      }
      const container = this.containers[containerIndex].Params;

      const setNestedKey = (obj, path, value) => {
        if (path.length === 1) {
          obj[path] = value;
          return;
        }
        return setNestedKey(obj[path[0]], path.slice(1), value);
      };

      return (value) => {
        return setNestedKey(container, pathArray, value);
      };
    }
  }

  add(containerName, params = {}) {
    this.containers.push({
      ParamName: containerName,
      Params: params,
    });
  }
}

export default DataContainer;
