import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';

export default class TimeRange extends React.Component {
  static displayName = 'TimeRange';
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.from,
      to: this.props.to,
      showPicker: false,
    };
  }

  handleClick(_) {
    if (this.props.isDisabled) {
      return;
    }
    this.setState({ showPicker: true });
  }

  handleKeyPress(ev) {
    if (ev.key === 'Enter') {
      this.handleClick(ev);
    }
  }

  confirm() {
    this.setState({ showPicker: false });
    this.props.onChange({ from: this.state.from, to: this.state.to });
  }

  remove() {
    this.setState({ from: '', to: '', showPicker: false });
    this.props.onChange({ from: '', to: '' });
  }

  render() {
    return (
      <div className="TimeRange">
        <div
          className="TimeRange-button"
          role="button"
          tabIndex="0"
          onClick={(ev) => {
            return this.handleClick(ev);
          }}
          onKeyPress={(ev) => {
            return this.handleKeyPress(ev);
          }}
        >
          {this.state.from} - {this.state.to}
        </div>
        {this.state.showPicker && (
          <div className="TimeRange-picker">
            <div className="TR-inputs">
              <input
                type="time"
                defaultValue={this.state.from}
                onChange={(v) => {
                  this.setState({ from: v.target.value });
                }}
              ></input>
              <div> bis </div>
              <input
                type="time"
                min={this.state.from}
                defaultValue={this.state.to}
                onChange={(v) => {
                  this.setState({ to: v.target.value });
                }}
              ></input>
            </div>
            <div>
              <Button
                tabIndex="0"
                onClick={(_) => {
                  this.remove();
                }}
              >
                löschen
              </Button>

              <Button
                tabIndex="0"
                onClick={(_) => {
                  this.confirm();
                }}
              >
                ok
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TimeRange.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

TimeRange.defaultProps = {
  onChange: (_) => {},
  isDisabled: false,
};
