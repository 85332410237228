import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import CaseStatisticsNewEntry from './New';

const CaseStatistics = (props) => {
  const paths = {
    new: `${props.match.path}/new/:clientGuid`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={CaseStatisticsNewEntry} />
    </Switch>
  );
};

export default withRouter(CaseStatistics);
