import React, { useState } from 'react';
import reqwest from 'reqwest';
import sha512 from 'sha512';
import settings from '../../settings';
import validate from '../logic/validator';
import Alert from './Alert';
import { closePopup } from '../logic/api';

const LoginFormPop = () => {
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const [error, setError] = useState('');
  const [userAccessData, setUserAccessData] = useState({
    username: '',
    password: '',
  });

  const login = async (event) => {
    event.preventDefault();
    const validators = {
      username: [
        { required: true, error: 'Bitte geben Sie Ihren Benutzernamen ein.' },
      ],
      password: [
        { required: true, error: 'Bitte geben Sie Ihr Passwort ein.' },
      ],
    };

    let validator = { isValid: true, error: '' };

    validator = await validate(userAccessData.username, validators.username);
    if (!validator.isValid) {
      setError(validator.error);
      return setLoginButtonLoading(false);
    }

    validator = await validate(userAccessData.password, validators.password);
    if (!validator.isValid) {
      setError(validator.error);
      return setLoginButtonLoading(false);
    }

    setLoginButtonLoading(true);

    reqwest({
      url: settings.general.login,
      method: 'get',
      data: {
        username: userAccessData.username,
        password: sha512(userAccessData.password).toString('hex'),
        AppGuid: '4711',
      },
    })
      .then((result) => {
        if (result.firstname === null) {
          setLoginButtonLoading(false);
          setError(
            'Der Login ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
          );
          return;
        }

        if (result) {
          localStorage.setItem('userdata', JSON.stringify(result));
          localStorage.setItem('token', result.token);
          setLoginButtonLoading(false);
          closePopup();
        }
      })
      .catch(() => {
        console.error(error);
        setLoginButtonLoading(false);
        setError(
          'Es ist ein Fehler mit dem Server aufgetreten. Bitte versuchen Sie es später erneut!',
        );
      });
  };

  return (
    <div className="Login-form-pop-room">
      {error && (
        <Alert
          type="error"
          title="Fehler"
          onDismiss={() => {
            return setError('');
          }}
        >
          {error}
        </Alert>
      )}
      <div className="Login-form-pop">
        <h2>Ihre Sitzung ist abgelaufen.</h2>
        <p>
          Bitte melden Sie sich erneut an, um Ihre Arbeit fortsetzen zu können.
        </p>
        <form>
          <div className="Login-Form radius">
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => {
                return setUserAccessData({
                  ...userAccessData,
                  username: e.target.value,
                });
              }}
              className="field field-border-bottom radius-top"
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => {
                return setUserAccessData({
                  ...userAccessData,
                  password: e.target.value,
                });
              }}
              className="field field-border-bottom"
            />
            <button
              className="Login-Form-Pop-Submit"
              onClick={login}
              disabled={!!loginButtonLoading}
              name="login"
              type="submit"
            >
              {loginButtonLoading ? (
                <div className="LoginLoader">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              ) : (
                <>Einloggen</>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginFormPop;
