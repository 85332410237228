import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import AppointmentOverview from './Overview';
import AppointmentNewEntry from './New';
import AppointmentDetail from './Detail';

const Appointmentmanagement = (props) => {
  const paths = {
    new: `${props.match.path}/new/:clientGuid`,
    details: `${props.match.path}/detail/:guid/:clientGuid`,
    overview: `${props.match.path}`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={AppointmentNewEntry} />
      <Route path={paths.details} component={AppointmentDetail} />
      <Route path={paths.overview} component={AppointmentOverview} />
    </Switch>
  );
};

export default withRouter(Appointmentmanagement);
