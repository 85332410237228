import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import New from './New';
import Detail from './Detail';
import Overview from './Overview';

const EMailTemplates = (props) => {
  const paths = {
    details: `${props.match.path}/:id`,
    new: `${props.match.path}/new`,
  };

  return (
    <Switch>
      <Route
        exact
        path={paths.new}
        render={(routerProps) => {
          return <New {...props} {...routerProps} />;
        }}
      />
      <Route
        exact
        path={paths.details}
        render={(routerProps) => {
          return <Detail {...props} {...routerProps} />;
        }}
      />
      <Route
        render={(routerProps) => {
          return <Overview {...props} {...routerProps} />;
        }}
      />
    </Switch>
  );
};

export default withRouter(EMailTemplates);
