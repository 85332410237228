import React from 'react';
import PropTypes from 'prop-types';

class PasswordValidator extends React.Component {
  // Base State
  state = {
    hasMoreThanEightChars: false,
    hasLowerChar: false,
    hasUpperChar: false,
    hasDigit: false,
    hasSpecialChar: false,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (!value) {
      return;
    }

    // Check if Value has Updated
    if (prevProps.value === value) {
      return;
    }

    let hasMoreThanEightChars = false;
    let hasLowerChar = false;
    let hasUpperChar = false;
    let hasDigit = false;
    let hasSpecialChar = false;

    const lowerChar = new RegExp('.*[a-z]');
    const upperChar = new RegExp('.*[A-Z]');
    const oneDigit = new RegExp('.*\\d');
    const specialChars = [
      '@',
      '$',
      '*',
      '&',
      '!',
      '*',
      '#',
      ',',
      ';',
      '?',
      '+',
      '-',
      '_',
      '.',
      '=',
      '~',
      '^',
      '%',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '|',
      ':',
      '/',
    ];
    const spllitedVal = value.split('');

    if (value.length >= 8) hasMoreThanEightChars = true;
    if (lowerChar.test(value)) hasLowerChar = true;
    if (upperChar.test(value)) hasUpperChar = true;
    if (oneDigit.test(value)) hasDigit = true;
    if (
      spllitedVal.some((char) => {
        return specialChars.indexOf(char) >= 0;
      })
    )
      hasSpecialChar = true;

    const isValid =
      hasMoreThanEightChars &&
      hasLowerChar &&
      hasUpperChar &&
      hasDigit &&
      hasSpecialChar;
    this.props.onChangeValid(isValid);

    this.setState({
      hasMoreThanEightChars,
      hasLowerChar,
      hasUpperChar,
      hasDigit,
      hasSpecialChar,
    });
  }

  render() {
    const { value } = this.props;

    return (
      <div className="Input-passwordRules">
        <div
          className={`Input-passwordRule ${
            value?.length >= 8 ? 'Input-passwordRule-passed' : ''
          }`}
        >
          Noch mindestens{' '}
          <span className="Input-passwordRule-value">
            {value ? (value?.length < 8 ? -(value?.length - 8) : 0) : 8}
          </span>{' '}
          Zeichen.
        </div>
        <div
          className={`Input-passwordRule ${
            this.state.hasUpperChar ? 'Input-passwordRule-passed' : ''
          }`}
        >
          Noch mindestens{' '}
          <span className="Input-passwordRule-value">
            {this.state.hasUpperChar ? 0 : 1}
          </span>{' '}
          Großbuchstaben.
        </div>
        <div
          className={`Input-passwordRule ${
            this.state.hasLowerChar ? 'Input-passwordRule-passed' : ''
          }`}
        >
          Noch mindestens{' '}
          <span className="Input-passwordRule-value">
            {this.state.hasLowerChar ? 0 : 1}
          </span>{' '}
          Kleinbuchstaben.
        </div>
        <div
          className={`Input-passwordRule ${
            this.state.hasDigit ? 'Input-passwordRule-passed' : ''
          }`}
        >
          Noch mindestens{' '}
          <span className="Input-passwordRule-value">
            {this.state.hasDigit ? 0 : 1}
          </span>{' '}
          Zahl.
        </div>
        <div
          className={`Input-passwordRule ${
            this.state.hasSpecialChar ? 'Input-passwordRule-passed' : ''
          }`}
        >
          Noch mindestens{' '}
          <span className="Input-passwordRule-value">
            {this.state.hasSpecialChar ? 0 : 1}
          </span>{' '}
          Sonderzeichen.
        </div>
      </div>
    );
  }
}

PasswordValidator.propTypes = {
  value: PropTypes.string,
  onChangeValid: PropTypes.func.isRequired,
};

PasswordValidator.defaultProps = {
  value: '',
};

export default PasswordValidator;
