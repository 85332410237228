import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import HistoryOverview from './Overview';

const ClientHistory = () => {
  return (
    <Switch>
      <Route component={HistoryOverview} />
    </Switch>
  );
};

export default withRouter(ClientHistory);
