import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SideAlert = ({ title, description }) => (
  <div role="alert" className="SideAlert-box fadeInDown">
    <div className="SideAlert-content">
      <span className="SideAlert-icon">
        <FontAwesomeIcon icon={faCheckCircle} className="icon" />
      </span>

      <div className="SideAlert-message">
        <strong className="SideAlert-title">{title}</strong>
        <p className="SideAlert-text">{description}</p>
      </div>
    </div>
  </div>
);

export default SideAlert;
