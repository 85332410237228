import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
class EMailTemplateOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eMailTemplateList: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.systemmanagement.emailtemplate.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then((emailtemplateListResponse) => {
      const eMailTemplateList = JSON.parse(emailtemplateListResponse).filter(
        (emailclient) => {
          return emailclient?.Params?.Guid !== '';
        },
      );

      this.setState({
        eMailTemplateList,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button icon={faPlus} to={`${this.props.match.url}/new`} replace>
              Neues Formular Template
            </Button>
          </div>
          <ReactTable
            getTrProps={(_, { original }) => {
              return {
                onClick: () => {
                  return this.props.history.replace(
                    `${this.props.match.url}/${original.Params.Guid}`,
                  );
                },
              };
            }}
            data={this.state.eMailTemplateList}
            pageSize={this.state.eMailTemplateList.length}
            noDataText="Es liegen keine Templates vor."
            columns={[
              {
                Header: 'Template',
                accessor: 'Params.Title',
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default EMailTemplateOverview;
