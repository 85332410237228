import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

class AvailabilityChecker extends React.Component {
  emptySlots = [
    [
      // noon
      { id: 'mon', available: true },
      { id: 'tue', available: true },
      { id: 'wed', available: true },
      { id: 'thr', available: true },
      { id: 'fri', available: true },
      { id: 'sat', available: true },
    ],
    [
      // afternoon
      { id: 'mon', available: true },
      { id: 'tue', available: true },
      { id: 'wed', available: true },
      { id: 'thr', available: true },
      { id: 'fri', available: true },
      { id: 'sat', available: true },
    ],
    [
      // eve
      { id: 'mon', available: true },
      { id: 'tue', available: true },
      { id: 'wed', available: true },
      { id: 'thr', available: true },
      { id: 'fri', available: true },
      { id: 'sat', available: true },
    ],
  ];
  constructor(props) {
    super(props);
    this.state = {
      slots: this.parse(this.props.availableSlots),
    };
  }

  handleClick(rowIndex, colIndex) {
    const newSlots = this.state.slots;
    newSlots[rowIndex][colIndex].available =
      !newSlots[rowIndex][colIndex].available;
    this.setState({ slots: newSlots });
    this.props.onChange({
      slots: this.stringify(newSlots),
    });
  }

  handleKeyPress(rowIndex, colIndex, ev) {
    if (ev.key === 'Enter') {
      this.handleClick(rowIndex, colIndex);
    }
  }

  stringify(slots) {
    return slots.reduce((res, row) => {
      return (
        res +
        row.reduce((s, slot) => {
          return s + (slot.available ? '1' : '0');
        }, '')
      );
    }, '');
  }

  parse(s) {
    if (s && s.length === 18) {
      const slots = this.emptySlots;
      [...s].forEach((element, index) => {
        const rowIndex = Math.floor(index / 6);
        const slotIndex = index % 6;
        slots[rowIndex][slotIndex].available = +element ? true : false;
      });
      return slots;
    } else {
      return this.emptySlots;
    }
  }

  render() {
    return (
      <div className="AvailabilityChecker">
        <div className="AvailabilityChecker-label">
          {this.props.label}
          {this.props.info && (
            <div className="Input-info">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="tooltiptext">{this.props.info}</span>
            </div>
          )}
        </div>
        <div className="AC-Input">
          <div className="AC-Table">
            <div className="AC-Table-head">
              <div className="AC-Table-body-label">{''}</div>
              <div className="AC-Table-head-field">Mo</div>
              <div className="AC-Table-head-field">Di</div>
              <div className="AC-Table-head-field">Mi</div>
              <div className="AC-Table-head-field">Do</div>
              <div className="AC-Table-head-field">Fr</div>
              <div className="AC-Table-head-field">Sa</div>
            </div>
            {this.state.slots && (
              <div className="AC-Table-body">
                <div className="AC-Table-body-row">
                  <div className="AC-Table-body-label">Vormittag</div>
                  {this.state.slots[0].map((noon, i) => {
                    return (
                      <div key={noon.id} className="AC-Table-body-data">
                        <input
                          disabled={this.props.isDisabled}
                          type="checkbox"
                          defaultChecked={noon.available}
                          onClick={(ev) => {
                            return this.handleClick(0, i, ev);
                          }}
                          onKeyPress={(ev) => {
                            return this.handleKeyPress(0, i, ev);
                          }}
                        ></input>
                      </div>
                    );
                  })}
                </div>
                <div className="AC-Table-body-row">
                  <div className="AC-Table-body-label">Nachmittag</div>
                  {this.state.slots[1].map((afternoon, i) => {
                    return (
                      <div key={afternoon.id} className="AC-Table-body-data">
                        <input
                          disabled={this.props.isDisabled}
                          type="checkbox"
                          defaultChecked={afternoon.available}
                          onClick={(ev) => {
                            return this.handleClick(1, i, ev);
                          }}
                          onKeyPress={(ev) => {
                            return this.handleKeyPress(1, i, ev);
                          }}
                        ></input>
                      </div>
                    );
                  })}
                </div>
                <div className="AC-Table-body-row">
                  <div className="AC-Table-body-label">Abend</div>
                  {this.state.slots[2].map((eve, i) => {
                    return (
                      <div key={eve.id} className="AC-Table-body-data">
                        <input
                          disabled={this.props.isDisabled}
                          type="checkbox"
                          defaultChecked={eve.available}
                          onClick={(ev) => {
                            return this.handleClick(2, i, ev);
                          }}
                          onKeyPress={(ev) => {
                            return this.handleKeyPress(2, i, ev);
                          }}
                        ></input>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AvailabilityChecker.propTypes = {
  label: PropTypes.string,
  availableSlots: PropTypes.string,
  isDisabled: PropTypes.bool,
};

AvailabilityChecker.defaultProps = {
  label: '',
  availableSlots: '111111111111111111',
  isDisabled: false,
};

export default AvailabilityChecker;
