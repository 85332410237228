import React, { Component } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import {
  faSave,
  faTimes,
  faArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../settings';
import DataContainer from '../../../logic/dataContainer';
import Button from '../../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderbarButton from '../../../components/HeaderbarButton';
import ClientForm from './ClientForm';
import api from '../../../logic/api';

class ClientNewEntry extends Component {
  constructor(props) {
    super(props);

    this.userGuid = JSON.parse(localStorage.getItem('userdata')).userGuid;

    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.state = {
      saving: false,
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Client', {
      // Guid: '', leave empty for new Clients
      Typ: 'Client',
      Chiffre: '',
      FirstName: '',
      LastName: '',
      DateOfBirth: '',
      Gender: '',
      TelPrivate: '',
      TelMobile: '',
      TelBusiness: '',
      TelFavorite: '',
      MailToPatient: '',
      TelAvailabilityNotes: '',
      TelNotesOnAnsweringMachine: '',
      TelMayUserShowItself: '',
      EmailPrivate: '',
      MayUseEmail: '',
      AddrStreetAndNumber: '',
      AddrPostalCode: '',
      AddrCity: '',
      DeptName: '',
      DeptStreetAndNumber: '',
      DeptPostalCode: '',
      DutyStation: '',
      TypeOfBranchOrSchool: '',
      IsShift: 'n',
      IsManagementPosition: 'n',
      IsTemporaryDisability: 'n',
      DateOfTemporaryDisability: '',
      LevelOfDisability: '',
      EmploymentState: '',
      DateOfCivilServant: '',
      DateOfCollectiveBargainingAgreement: '',
      EmploymentScope: '',
      Supply: '',
      HealthInsurance: '',
      IsServiceUsedBefore: 'n',
      /** Beratungsanliegen */
      CounselingConcern: '',
      /** Sicherheitsfrage */
      Question: '',
      /** Sicherheitsantwort */
      Answer: '',
      /** Aufbewahrungsort der Fallakte  */
      PlaceOfStorage: '',
      /** Rechte Visible für bestimmte User */
      VisibleFor: '',
      /** Letzter Kontakt (might be redundant)*/
      LastContact: '',
      CountConsultations: 0,
      IsDone: 'false',
    });
  }

  save = async () => {
    this.setState({ saving: true });
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      this.setState({ saving: false });
      return;
    }
    const oneDataContainer = JSON.stringify({
      ParamName: 'Client',
      Params: this.dataContainer.get('Client'),
    });

    api('POST', settings.clientmanagement.dataContainer.save, {
      container: oneDataContainer,
      action: 'Datensatz angelegt',
    })
      .then((res) => {
        const createdClient = new DataContainer(res);
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(
          this.parentRoute +
            '/detail/' +
            createdClient.get('SaveSingleContainer', 'Guid'),
        );
      })
      .catch((e) => {
        console.error(e);
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: 'error',
        });
        this.setState({ saving: false });
      });
  };

  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <b>Neuer Klient/in</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>

        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to="/">
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={this.props.match.url}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  loading={this.state.saving}
                >
                  Speichern
                </Button>
              </div>
              <ClientForm
                ref={(node) => {
                  return (this.form = node);
                }}
                dataContainer={this.dataContainer}
              />
              <div className="Buttonbar-bottom">
                <Button
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  loading={this.state.saving}
                >
                  Speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ClientNewEntry);
