import {
  faCopy,
  faDownload,
  faExclamationTriangle,
  faFilter,
  faLock,
  faLockOpen,
  faMailBulk,
  faMap,
  faMapMarked,
  faPlus,
  faRepeat,
  faRoute,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import api from '../../../logic/api';
import swal from 'sweetalert';
import settings from '../../../../settings';
import Button from '../../../components/Button';
import DisordersMainFocus from '../../../components/DisordersMainFocus';
import HeaderbarButton from '../../../components/HeaderbarButton';
import Loader from '../../../components/Loader';
import Map from '../../../components/Map';
import SearchDropdown from '../../../components/SearchDropdown';
import Searchbar from '../../../components/Searchbar';
import Treatments from '../../../components/Treatments';
import AmbPsychFilterContext from '../../../hooks/AmbPsychFilterContext';
import getConstants from '../../../logic/constants';
import { deepParseJson } from '../../../logic/dataContainer';
import { checkStatus } from '../../../logic/checkStatus';

class SupplynetworkOverview extends Component {
  static contextType = AmbPsychFilterContext;
  constructor(props) {
    super(props);
    this.city = createRef();
    this.searchLatLonValue = createRef();

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: true,
      loadExcel: false,
      viewMap: false,
      isSearching: false,
      checkedData: [],
      showCheckBoxes: false,
      data: [],
      searchLatLon: null,
      Gender: [],
      Ages: [],
      TherapyProcedures: [],
      BarrierFreeAccess: [],
      JobTitles: [],
      CashRegisterApprovals: [],
      ReimbursementProcedures: [],
      OfferVideoTherapy: [],
      AgilTraining: [],
      ApprovalForGroupPsychotherapy: [],
    };
  }

  loadData = () => {
    this.setState({
      data: [],
      loading: true,
    });
    api('get', settings.supplynetwork.ambulatoryPsychotherapy.getList)
      .then((res) => {
        const parsedData = deepParseJson(res).ambulatoryPsychotherapyList;
        this.migrateOldData(parsedData);

        this.setState({
          data: parsedData,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  toggleLockAllAmbulatoryEntries = (lock) => {
    this.setState({
      loading: true,
    });
    if (lock === 'Lock') {
      api(
        'post',
        settings.supplynetwork.ambulatoryPsychotherapy.lockAllAmbulatoryEntries,
      )
        .then(() => {
          swal({
            title: 'Erfolgreich',
            icon: 'success',
            text: 'Alle Adressen wurden erfolgreich gesperrt',
          });
          this.loadData();
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          swal({
            title: 'Fehler',
            icon: 'error',
            text: 'Fehler beim Sperren der Adressen',
          });
        });
    } else {
      api(
        'post',
        settings.supplynetwork.ambulatoryPsychotherapy
          .unlockAllAmbulatoryEntries,
      )
        .then(() => {
          swal({
            title: 'Erfolgreich',
            icon: 'success',
            text: 'Alle Adressen wurden erfolgreich freigeschaltet',
          });
          this.loadData();
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          swal({
            title: 'Fehler',
            icon: 'error',
            text: 'Fehler beim Freischalten der Adressen',
          });
        });
    }
  };
  componentDidMount() {
    this.loadData();

    this.initConstants();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.searchLatLon !== this.state.searchLatLon) {
      this.setState({ data: this.addDistance(this.state.data) });
    }
  }

  migrateOldData(parsedData) {
    /** TherapyProcedures from dropdown to multiselect => string to array (8.Dec.22) */
    parsedData.forEach((element) => {
      if (typeof element.TherapyProcedures === 'string') {
        element.TherapyProcedures = [element.TherapyProcedures];
      }
    });
  }

  addDistance(data) {
    return data.map((element) => {
      let distance = null;
      if (
        element &&
        element.ZipLat &&
        element.ZipLon &&
        this.state.searchLatLon &&
        this.state.searchLatLon[0] &&
        this.state.searchLatLon[1]
      ) {
        const mLat = parseFloat(element.ZipLat.replace(',', '.'));
        const mLon = parseFloat(element.ZipLon.replace(',', '.'));
        distance = this.calculateDistance(
          {
            lng: mLon,
            lat: mLat,
          },
          {
            lng: this.state.searchLatLon[0],
            lat: this.state.searchLatLon[1],
          },
        );
      }
      return { ...element, Distance: distance };
    });
  }

  calculateDistance(checkPoint, centerPoint) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.round(Math.sqrt(dx * dx + dy * dy));
  }

  getAges() {
    const ages = Array.from(Array(73).keys());
    return ages.map((v) => {
      return { value: v + 18, label: v + 18 };
    });
  }

  getBarrierFreeAccess() {
    return (
      {
        value: 'true',
        label: 'Ja',
      },
      {
        value: 'false',
        label: 'Nein',
      }
    );
  }

  getReimbursementProcedures() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  getOptions() {
    return [
      {
        value: true,
        label: 'Ja',
      },
      {
        value: false,
        label: 'Nein',
      },
    ];
  }

  async initConstants() {
    this.setState({
      Gender: await getConstants('Gender'),
      TherapyProcedures: await getConstants('TherapyProcedures'),
      JobTitles: await getConstants('JobTitle'),
      CashRegisterApprovals: await getConstants('CashRegisterApproval'),
      OfferVideoTherapy: this.getOptions(),
      AgilTraining: this.getOptions(),
      ApprovalForGroupPsychotherapy: this.getOptions(),
      ReimbursementProcedures: this.getReimbursementProcedures(),
      BarrierFreeAccess: this.getBarrierFreeAccess(),
      Ages: this.getAges(),
    });
  }

  cashRegisterApprovalsOptions() {
    return [
      {
        value: true,
        label: 'Ja',
      },
      {
        value: false,
        label: 'Nein',
      },
    ];
  }

  _search = (value) => {
    this.context.saveFilterData('Volltextsuche', value.toLowerCase());
  };

  _changeView = () => {
    this.setState({
      viewMap: !this.state.viewMap,
    });
  };

  _toggleFilter = () => {
    this.context.toggleFilters();
  };

  _changeFilter = (filterName, filterValue) => {
    this.context.saveFilterData(filterName, filterValue);
  };

  _downloadList = () => {
    this.setState({
      loadExcel: true,
    });
    // Get the authentication token from local storage
    const token = localStorage.getItem('token');
    // Construct the API URL for downloading the Excel list
    const url = settings.excel.getExcel;
    // Set the request parameters (in this case, the authentication token)
    const params = { Token: token };

    // Make a GET request to the API to download the Excel list
    fetch(url, {
      method: 'GET',
      data: params,
      responseType: 'blob', // Expect a binary response
    })
      .then((res) => {
        // Read the response blob as a string
        return res.blob();
      })
      .then((blob) => {
        // Create a new Blob object with the decoded string data
        const url = window.URL.createObjectURL(blob);
        // Create a link element to simulate a download link
        const link = document.createElement('a');
        // Set the href attribute to the response URL
        link.href = url;
        // Set the download attribute to the desired file name
        link.setAttribute('download', 'Therapeuten.xlsx');
        // Add the link element to the body of the page
        document.body.appendChild(link);
        // Simulate a click on the link to trigger the download
        link.click();
        // Remove the link element from the body of the page
        link.remove();
      })
      .catch((err) => {
        // Log an error message if the download fails
        console.error('Error downloading list:', err);
      })
      .finally(() => {
        this.setState({
          loadExcel: false,
        });
      });
  };

  isTodayBetween = (from, until) => {
    return moment().isBetween(
      moment(from, 'DD.MM.YYYY'),
      moment(until, 'DD.MM.YYYY'),
      'day',
    );
  };

  /** Returns true if today is between AbsentFrom and AbsentUntil */
  isMarkedAvailable = (original) => {
    if (original?.AbsentFrom && original?.AbsentUntil) {
      return !this.isTodayBetween(original.AbsentFrom, original.AbsentUntil);
    }
    return true;
  };

  /** Returns true if both dates are set and are both today or still in future */
  isFullyBlocked = (legallyFrom, legallyUntil, privateFrom, privateUntil) => {
    return (
      legallyFrom &&
      legallyUntil &&
      privateFrom &&
      privateUntil &&
      this.isTodayBetween(legallyFrom, legallyUntil) &&
      this.isTodayBetween(privateFrom, privateUntil)
    );
  };

  resetFilters = () => {
    this.context.clearFilterData();
    this.initConstants();
  };

  streetAndLatLonSearch = async () => {
    try {
      this.setState({
        isSearching: true,
      });
      await this.geocodingClient
        .forwardGeocode({
          query: this.city.current.value
            ? this.city.current.value
            : this.context.filterData.city,
        })
        .send()
        .then((response) => {
          const match = response.body;
          if (!match.features.length) return;
          this.setState({
            searchLatLon: match.features[0].center,
          });
        });
      this.context.saveFilterData(
        'Distance',
        this.searchLatLonValue.current.value,
      );
      this.context.saveFilterData('city', this.city.current.value);
      this.context.saveFilterData(
        'searchLatLonValue',
        this.searchLatLonValue.current.value,
      );
      this.context.setData(
        'supplyNetworkOverviewData',
        this.addDistance(this.state.data),
      );
      this.setState({
        data: this.addDistance(this.state.data),
        isSearching: false,
      });
      this.context.saveSorting({ id: 'Distance', desc: false });
    } catch (error) {
      this.setState({
        isSearching: false,
      });
      swal({
        title: 'Nicht möglich.',
        icon: 'Straßen- und LatLon-Suche',
        text: error,
      });
    }
  };

  saveCheckedData = (email, isChecked) => {
    if (this.state.checkedData.filter((e) => e.email === email).length > 0) {
      const updatedCheckedData = [...this.state.checkedData];
      const index = this.state.checkedData.findIndex((e) => e.email === email);

      updatedCheckedData[index].isChecked = isChecked;

      this.setState({
        checkedData: updatedCheckedData,
      });
      return;
    }

    this.setState({
      checkedData: [...this.state.checkedData, { email, isChecked }],
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader text="Standorte werden geladen..." />;
    }
    let data = [];
    if (this.state.data.length) {
      const filteredData = JSON.parse(localStorage.getItem('filterData'));

      if (
        filteredData?.city?.length > 0 &&
        filteredData?.searchLatLonValue?.length > 0 &&
        this.context.supplyNetworkOverviewData.length > 0
      ) {
        data = this.context.filterAndSortData(
          this.context.filterRows(this.context.supplyNetworkOverviewData),
        );
      } else {
        data = this.context.filterAndSortData(
          this.context.filterRows(this.state.data),
        );
      }
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk - <b>Ambulante Psychotherapie</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faLockOpen}
              onClick={() => this.toggleLockAllAmbulatoryEntries('UnLock')}
            >
              Alle Adressen Freischalten
            </HeaderbarButton>
            {/* <HeaderbarButton
              icon={faLock}
              onClick={() => this.toggleLockAllAmbulatoryEntries('Lock')}
            >
              Alle Adressen Sperren
            </HeaderbarButton> */}
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
            {!this.state.showCheckBoxes && (
              <HeaderbarButton
                icon={faMailBulk}
                onClick={() =>
                  this.setState({
                    showCheckBoxes: !this.state.showCheckBoxes,
                  })
                }
              >
                E-Mail versenden
              </HeaderbarButton>
            )}
            <HeaderbarButton
              icon={faDownload}
              loading={this.state.loadExcel}
              loadingText="Excel wird erstellt..."
              onClick={this._downloadList}
            >
              Drucken
            </HeaderbarButton>
            <HeaderbarButton icon={faMap} onClick={this._changeView}>
              {this.state.viewMap ? 'Karte verbergen' : ' Karte anzeigen'}
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink
                to="/supplynetwork/AmbulatoryPsychotherapy"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Ambulante Psychotherapie
              </NavLink>
              <NavLink
                to="/supplynetwork/Clinics"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Kliniken
              </NavLink>
              <NavLink
                to="/supplynetwork/Specialists"
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Fachärzte
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  type={'primary'}
                  icon={faPlus}
                  to="/supplynetwork/AmbulatoryPsychotherapy/new"
                >
                  Neue Adresse anlegen
                </Button>
              </div>
              <div className="Toolbar">
                <Searchbar
                  key="searchString"
                  placeholder="Volltextsuche"
                  value={this.context.filterData.Volltextsuche}
                  onChange={this._search}
                />
                <Button icon={faFilter} onClick={this._toggleFilter}>
                  {this.context.showFilters
                    ? 'Filter ausblenden '
                    : 'Filter einblenden'}
                </Button>
                <span className="Searchbar-Results-Amount">
                  {data.length} Einträge
                </span>

                <div className="Toolbar-buttons"></div>
              </div>
              <div className="Toolbar">
                <Searchbar
                  onRef={this.city}
                  key="city"
                  icon={faMapMarked}
                  placeholder="Startadresse oder PLZ eingeben"
                  value={this.context.filterData.city}
                  onChange={(value) => {
                    this._changeFilter('city', value);
                  }}
                />
                <Searchbar
                  onRef={this.searchLatLonValue}
                  key="searchLatLonValue"
                  placeholder="im Umkreis von (km)"
                  icon={faRoute}
                  value={this.context.filterData.searchLatLonValue}
                  onChange={(value) => {
                    this._changeFilter('searchLatLonValue', value);
                  }}
                />
                <Button
                  type={'primary'}
                  icon={faSearch}
                  onClick={this.streetAndLatLonSearch}
                >
                  Suchen
                </Button>
                {this.context.showFilters && (
                  <Button
                    type={'primary'}
                    icon={faFilter}
                    onClick={this.resetFilters}
                  >
                    Filter zurücksetzen
                  </Button>
                )}
                <div className="Toolbar-buttons"></div>
              </div>
              {this.context.showFilters && (
                <>
                  <div className="Toolbar-Filter">
                    <SearchDropdown
                      label="Geschlecht:"
                      options={this.state.Gender}
                      defaultValue={this.context.filterData.Gender}
                      onChange={(value) => {
                        this._changeFilter('Gender', value);
                      }}
                    />
                    <SearchDropdown
                      label="Alter (+/- 5):"
                      options={this.state.Ages}
                      onChange={(value) => {
                        this._changeFilter('BirthYear', value);
                      }}
                    />
                    <SearchDropdown
                      label="Berufsbezeichnung:"
                      options={this.state.JobTitles}
                      defaultValue={this.context.filterData.JobTitle}
                      onChange={(value) => {
                        this._changeFilter('JobTitle', value);
                      }}
                    />
                    <SearchDropdown
                      label="Therapieverfahren:"
                      options={this.state.TherapyProcedures}
                      defaultValue={this.context.filterData.TherapyProcedures}
                      onChange={(value) => {
                        this._changeFilter('TherapyProcedures', value);
                      }}
                    />
                    <SearchDropdown
                      label="Zulassung zur Gruppentherapie:"
                      options={this.state.ApprovalForGroupPsychotherapy}
                      defaultValue={
                        this.context.filterData.ApprovalForGroupPsychotherapy
                      }
                      onChange={(value) => {
                        this._changeFilter(
                          'ApprovalForGroupPsychotherapy',
                          value,
                        );
                      }}
                    />
                    <SearchDropdown
                      label="AGIL:"
                      options={this.state.AgilTraining}
                      defaultValue={this.context.filterData.AgilTraining}
                      onChange={(value) => {
                        this._changeFilter('AgilTraining', value);
                      }}
                    />
                  </div>
                  <div className="Toolbar-Filter">
                    <Treatments
                      name="Treatments"
                      label="Behandlungsschwerpunkte in der praktischen Arbeit:"
                      info="Mehrfachauswahl ist möglich."
                      values={this.context.filterData.Treatments}
                      onChange={(values) => {
                        this._changeFilter('Treatments', values);
                      }}
                    />

                    <DisordersMainFocus
                      name="Disorders"
                      label="Störungsbilder Schwerpunkte:"
                      mainFocus={this.context.filterData.DisordersMainFocus}
                      onChange={(values) => {
                        this._changeFilter(
                          'DisordersMainFocus',
                          values.mainFocus,
                        );
                      }}
                    />
                    <SearchDropdown
                      label="Kassenzulassung:"
                      options={this.cashRegisterApprovalsOptions()}
                      defaultValue={
                        this.context.filterData.CashRegisterApproval
                      }
                      onChange={(value) => {
                        this._changeFilter('CashRegisterApproval', value);
                      }}
                    />
                    <SearchDropdown
                      label="Kostenerstattungsverfahren:"
                      options={this.state.ReimbursementProcedures}
                      defaultValue={
                        this.context.filterData.ReimbursementProcedure
                      }
                      onChange={(value) => {
                        this._changeFilter('ReimbursementProcedure', value);
                      }}
                    />
                    <SearchDropdown
                      label="Videotherapie:"
                      options={this.state.OfferVideoTherapy}
                      defaultValue={this.context.filterData.OfferVideoTherapy}
                      onChange={(value) => {
                        this._changeFilter('OfferVideoTherapy', value);
                      }}
                    />
                  </div>
                </>
              )}
              {this.state.viewMap && (
                <>
                  <Map
                    markers={data}
                    streetPoint={this.state.searchLatLon}
                    radius={this.context.filterData.Distance}
                  />
                  <br />
                </>
              )}
              {this.state.isSearching ? (
                <Loader />
              ) : (
                <>
                  <div className="emailArea">
                    {this.state.showCheckBoxes && (
                      <div className="tooltip-emails">
                        <div className="tooltip-email-title">
                          {' '}
                          Ausgewählte Mails:{' '}
                        </div>
                        <div className="tooltip-email-view">
                          {this.state.checkedData.map((e, idx) => {
                            if (e.isChecked === true)
                              return <div key={idx}>{e.email}&nbsp;</div>;
                          })}
                        </div>
                        <br />
                        <span className="tooltip-email-buttons">
                          <Button
                            type={'primary'}
                            small
                            onClick={() =>
                              this.setState({
                                showCheckBoxes: !this.state.showCheckBoxes,
                              })
                            }
                          >
                            Abbrechen
                          </Button>
                          <Button
                            type="primary"
                            small
                            icon={faCopy}
                            onClick={() => {
                              const emails = this.state.checkedData
                                .map((e) => {
                                  if (e.isChecked === true) return e.email;
                                })
                                .join(';');
                              navigator.clipboard.writeText(emails);
                              return swal({
                                title: 'Erfolgreich kopiert',
                                icon: 'success',
                              });
                            }}
                          >
                            Kopieren
                          </Button>
                        </span>
                        <Button
                          type="primary"
                          small
                          icon={faMailBulk}
                          onClick={() => {
                            const emails = data
                              .map((e) => {
                                if (
                                  e.MailToCare !== '' &&
                                  e.MailToCare !== null &&
                                  e.MailToCare !== undefined
                                ) {
                                  return e.MailToCare.trim();
                                }
                              })
                              .filter((email) => email !== undefined);

                            navigator.clipboard.writeText(emails.join(';'));
                            swal({
                              title: 'Erfolgreich kopiert',
                              icon: 'success',
                            });
                          }}
                        >
                          Alle E-Mails auswählen und kopieren
                        </Button>
                        <div className="tooltip-text-emails"></div>
                      </div>
                    )}
                  </div>
                  <ReactTable
                    getTrProps={(_, val) => {
                      return {
                        className: checkStatus(val?.original),
                        onClick: () => {
                          return this.props.history.push(
                            `/supplynetwork/AmbulatoryPsychotherapy/detail/${val?.original?.Guid}`,
                          );
                        },
                      };
                    }}
                    defaultSorted={[this.context.sorting]}
                    onSortedChange={(sorted) => {
                      this.context.saveSorting(sorted[0]);
                    }}
                    data={data}
                    pageSize={data.length}
                    noDataText="Es wurden keine Adressen gefunden."
                    columns={[
                      {
                        resizable: true,
                        accessor: 'Lastname',
                        Header: 'Name',
                        Cell: ({ original }) => {
                          let checked = false;
                          this.state.checkedData.map((element) => {
                            if (element.email === original.MailToCare) {
                              checked = element.isChecked;
                            }
                          });
                          return (
                            <>
                              {this.state.showCheckBoxes && (
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  checked={checked}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.saveCheckedData(
                                      original.MailToCare.trim(),
                                      e.target.checked,
                                    );
                                  }}
                                />
                              )}
                              {!original?.Unlocked ? (
                                <>
                                  <FontAwesomeIcon
                                    className="locked-icon"
                                    icon={faExclamationTriangle}
                                  />
                                  {original.Lastname}
                                </>
                              ) : (
                                original.Lastname
                              )}
                            </>
                          );
                        },
                      },
                      {
                        resizable: true,
                        accessor: 'Firstname',
                        Header: 'Vorname',
                        Cell: ({ original }) => <>{original.Firstname}</>,
                      },
                      {
                        resizable: true,
                        accessor: 'street',
                        Header: 'Straße',
                        Cell: ({ original }) => <>{original.Street}</>,
                      },
                      {
                        resizable: true,
                        accessor: 'ZIP',
                        Header: 'PLZ',
                      },
                      {
                        resizable: true,
                        accessor: 'City',
                        Header: 'Ort',
                      },
                      {
                        resizable: true,
                        accessor: 'MailToCare',
                        Header: 'E-Mail Adresse',
                      },
                      {
                        resizable: true,
                        accessor: 'TherapyProcedures',
                        Header: 'ThV',
                        Cell: ({ original }) => (
                          <>
                            {this.state.TherapyProcedures.filter((elem) =>
                              original.TherapyProcedures?.includes(elem.value),
                            )
                              .map((tf) => tf?.kkz)
                              .join(', ')}
                          </>
                        ),
                      },
                      {
                        resizable: true,
                        accessor: 'Distance',
                        Header: 'Entfernung',
                        Cell: ({ original }) => (
                          <>
                            {original.Distance ? original.Distance + ' km' : ''}
                          </>
                        ),
                      },
                      {
                        resizable: true,
                        accessor: 'points',
                        Header: this.context.showFilters ? 'Filter' : '',
                        Cell: ({ original }) => (
                          <div className="tooltip-filter">
                            {this.context.showFilters ? (
                              <>
                                {original.points
                                  ? original.points + ' %'
                                  : ' 0 %'}
                              </>
                            ) : (
                              ''
                            )}
                            <div className="tooltip-text-filter">
                              {original.matchedKeys?.length > 0 ? (
                                <>
                                  <h3>Zugetroffen Filter:</h3>
                                  <ul>
                                    {original.matchedKeys.map((filter, idx) => (
                                      <li key={idx} className="filter-items">
                                        <p className="check-filter">&#x2714;</p>
                                        {filter}
                                      </li>
                                    ))}
                                  </ul>
                                  <h3>Nicht zugetroffen Filter:</h3>
                                  <ul>
                                    {original.unmatchedKeys.map(
                                      (filter, idx) => {
                                        if (filter !== '')
                                          return (
                                            <li
                                              key={idx}
                                              className="filter-items"
                                            >
                                              <p className="ex-filter ">
                                                &#215;
                                              </p>
                                              {filter}
                                            </li>
                                          );
                                      },
                                    )}
                                  </ul>
                                </>
                              ) : (
                                <h3>Keine Treffer</h3>
                              )}
                              <i></i>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SupplynetworkOverview;
