import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import {
  faArrowLeft,
  faCheckCircle,
  faEnvelope,
  faHourglass,
  faSave,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import getConstants from '../../../../../logic/constants';
import { Link, withRouter } from 'react-router-dom';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import moment from 'moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons';
import Switch from '../../../../../components/Inputs/Switch';

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.guid = props.match.params.guid;
    this.parentRoute = this.props.match.url.replace(
      /\/appointments\/.*/,
      '/appointments/' + this.clientGuid,
    );
    this.state = {
      loading: true,
      loadingSendEmail: false,
      confirmAppointmentArea: false,
      PlaceOfConsultation: [],
      CarePsych: [],
      AppointmentStatus: [],
      FormOfConsultations: [],
      TypeOfConsultations: [],
    };
    this.initConstants();
  }

  getFormOfConsultationOptions() {
    return [
      { value: 'personal', label: 'Persönlich' },
      { value: 'telephon', label: 'Telefonisch' },
      { value: 'video', label: 'Video' },
    ];
  }

  async initConstants() {
    this.setState({
      PlaceOfConsultation: await getConstants('PlaceOfConsultation'),
      TypeOfConsultations: await getConstants('TypeOfConsultation'),
      CarePsych: await getConstants('CarePsych'),
      AppointmentStatus: await getConstants('AppointmentStatus'),
      FormOfConsultations: this.getFormOfConsultationOptions(),
    });
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.clientmanagement.dataContainer.get,
      data: {
        guid: this.guid,
      },
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.dataContainer.add('Appointment', {
          Typ: 'Appointment',
          ClientGuid: this.clientGuid,
          DateOfConsultation: this.dataContainer.get(
            'Entry',
            'DateOfAppointment',
          ),
          Duration: '',
          TypeOfConsultation: this.dataContainer.get(
            'Entry',
            'TypeOfConsultation',
          ),
          KindOfConsultation: this.dataContainer.get(
            'Entry',
            'FormOfConsultation',
          ),
          PlaceOfConsultation: this.dataContainer.get(
            'Entry',
            'PlaceOfConsultation',
          ),
          Guid: this.dataContainer.get('Entry', 'Guid'),
          CarePsych: this.dataContainer.get('Entry', 'CarePsych'),
          AppointmentStatus: '',
          DeleteNotesOnCaseConsultation: false,
        });
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.warn('catch res:', e);
        this.setState({
          loading: false,
        });
      });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const oneDataContainer = JSON.stringify({
      ParamName: 'Appointment',
      Params: this.dataContainer.get('Entry'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        action: 'Beratungstermin bearbeitet',
      },
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: 'error',
        });
      });
  };

  saveConfirmAppointment = async () => {
    const oneDataContainer = JSON.stringify({
      ParamName: 'Appointment',
      Params: this.dataContainer.get('Appointment'),
    });
    const appointmentStatus = this.dataContainer.get(
      'Appointment',
      'AppointmentStatus',
    );
    const dateOfConsultation = this.dataContainer.get(
      'Appointment',
      'DateOfConsultation',
    );
    const duration = this.dataContainer.get('Appointment', 'Duration');
    const kkz = this.state.AppointmentStatus.find(
      (as) => as.value === appointmentStatus,
    )?.kkz;
    this.setState({ loading: true });
    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        action: appointmentStatus
          ? kkz === 'stattgefunden'
            ? `Beratungstermin hat stattgefunden am ${moment(
                dateOfConsultation,
              )?.format('DD.MM.YYYY')}${
                duration ? ` für ${duration} Minuten` : ''
              }`
            : 'Beratungstermin wurde abgesagt'
          : 'Neue Wiedervorlage angelegt',
      },
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.setState({ loading: false });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  remove = async () => {
    swal({
      title: 'Löschen?',
      buttons: {
        cancle: 'abbrechen',
        delete: 'löschen',
      },
    }).then((value) => {
      if (value === 'delete') {
        reqwest({
          method: 'POST',
          url: settings.clientmanagement.dataContainer.remove,
          data: {
            guid: this.guid,
            action: 'Beratungstermin gelöscht', // needed for history
            clientGuid: this.dataContainer.get('Entry', 'ClientGuid'), // needed for history
          },
        })
          .then(() => {
            swal({
              title: 'Gelöscht!',
              text: 'Erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            swal({
              title: 'Löschen nicht möglich!',
              text: 'Es ist ein Fehler beim löschen aufgetreten',
              icon: 'error',
            });
          });
      }
    });
  };

  /**
   * To Do: only send Appointmend Guid
   */
  requestAppointment = async () => {
    this.setState({ loadingSendEmail: true });

    // Todo: ony send Appointment guide and let middelware get the information it needs for creating a iCall or vCard.
    const appointmentContainer = new DataContainer();
    appointmentContainer.add('Appointment', {
      Typ: 'Appointment',
      ClientGuid: this.dataContainer.get('Entry', 'ClientGuid'),
      DateOfAppointment: this.dataContainer.get('Entry', 'DateOfAppointment'),
      TimeOfAppointment: this.dataContainer.get('Entry', 'TimeOfAppointment'),
      PlaceOfConsultation: this.dataContainer.get(
        'Entry',
        'PlaceOfConsultation',
      ),
      CarePsych: this.dataContainer.get('Entry', 'CarePsych'),
      FormOfConsultation: this.dataContainer.get('Entry', 'FormOfConsultation'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.client.askForAppointment,
      data: {
        appointment: appointmentContainer.getStringified(),
        action: 'Terminanfrage an den CARE-Pychologen per E-Mail gesendet.',
      },
    })
      .then(() => {
        swal({
          title: 'Terminanfrage',
          text: 'Eine Terminanfrage wurde an den CARE-Pychologen per E-Mail gesendet.',
          icon: 'success',
        });
      })
      .catch((error) => {
        console.error('Terminanfrage versendet', error);
        swal({
          title: 'Fehler!',
          text:
            'Es ist ein Fehler beim versenden der Terminanfrage aufgetreten. (' +
            error.response +
            ')',
          icon: 'error',
        });
      })
      .fail(() => {
        this.setState({ loadingSendEmail: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.confirmAppointmentArea) {
      return (
        <>
          <div className="Headerbar sticky">
            <div className="Headerbar-breadcrumbs">
              <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
              <Link
                onClick={() => {
                  this.props.history.push(
                    `/clientmanagement/detail/${this.dataContainer.get(
                      'Entry',
                      'ClientGuid',
                    )}`,
                  );
                }}
              >
                Klient/in
              </Link>
              {' - '}
              <Link to={this.parentRoute}>Beratungstermin</Link>
              {' - '}
              <Link
                to="#"
                onClick={() => {
                  this.setState({ confirmAppointmentArea: false });
                }}
              >
                Bearbeiten
              </Link>
              {' - '}
              <b>Bestätigen</b>
            </div>
          </div>
          <div className="Split-Panes-Menu">
            <div className="Split-Pane-Menu">
              <div className="Split-Pane-Menu-Nav">
                <NavLink
                  className="FormArea-Body-Tab"
                  to="#"
                  onClick={() => {
                    this.setState({ confirmAppointmentArea: false });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Zurück
                </NavLink>
              </div>
            </div>
            <div className="Split-Pane-Main">
              <div className="Page-Content">
                <div className="Buttonbar">
                  <Button
                    icon={this.state.loading ? faHourglass : faCheckCircle}
                    onClick={this.saveConfirmAppointment}
                  >
                    Bestätigen
                  </Button>
                </div>
                <Form>
                  <ContentBox title="Terminbestätigung">
                    <Input
                      type="date"
                      validator={[
                        {
                          required: true,
                          error: 'Bitte Datum eingeben',
                        },
                        {
                          date: true,
                          error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                        },
                      ]}
                      onChange={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'DateOfConsultation',
                        )(moment(value)?.format('YYYY-MM-DD'));
                      }}
                      defaultValue={moment(
                        this.dataContainer.get('Entry', 'DateOfConsultation'),
                      )?.format('YYYY-MM-DD')}
                      label="Datum der Beratung"
                      name="DateOfConsultation"
                    />
                    <Input
                      type="number"
                      validator={[]}
                      onChange={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'Duration',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'Duration',
                      )}
                      label="Dauer der Beratung in Minuten"
                      name="Duration"
                    />
                    <Dropdown
                      label="Form der Beratung:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'KindOfConsultation',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'KindOfConsultation',
                      )}
                      options={this.state.FormOfConsultations}
                      validator={[]}
                      name="KindOfConsultation"
                    />

                    <Dropdown
                      label="CARE-Psych.:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'CarePsych',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'CarePsych',
                      )}
                      options={this.state.CarePsych}
                      validator={[]}
                      name="CarePsych"
                    />
                    <Dropdown
                      label="Standort:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'PlaceOfConsultation',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'PlaceOfConsultation',
                      )}
                      options={this.state.PlaceOfConsultation}
                      validator={[]}
                      name="PlaceOfConsultation"
                    />

                    <Dropdown
                      label="Beratungsanliegen:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'TypeOfConsultation',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Entry',
                        'TypeOfConsultation',
                      )}
                      options={this.state.TypeOfConsultations}
                      validator={[]}
                      name="TypeOfConsultation"
                    />
                    <Dropdown
                      label="Status:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'Appointment',
                          'AppointmentStatus',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'AppointmentStatus',
                      )}
                      options={this.state.AppointmentStatus}
                      validator={[]}
                      name="AppointmentStatus"
                    />
                    <Switch
                      label="‘Hinweise zur Fallberatung' löschen"
                      onChange={(value) => {
                        console.log(value);
                        this.dataContainer.set(
                          'Appointment',
                          'DeleteNotesOnCaseConsultation',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'Appointment',
                        'DeleteNotesOnCaseConsultation',
                      )}
                      labelActive="Ja"
                      labelInactive="Nein"
                      validator={[]}
                      name="„DeleteNotesOnCaseConsultation"
                    />
                  </ContentBox>
                </Form>
                <div className="Buttonbar-bottom">
                  <Button
                    type="primary"
                    icon={this.state.loading ? faHourglass : faCheckCircle}
                    onClick={this.saveConfirmAppointment}
                  >
                    Bestätigen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.dataContainer.get(
                    'Entry',
                    'ClientGuid',
                  )}`,
                );
              }}
            >
              Klient/in
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Beratungstermin</Link> -{' '}
            <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              disabled={this.state.loadingSendEmail}
              icon={this.state.loadingSendEmail ? faHourglass : faCalendarCheck}
              onClick={() => {
                return this.setState({ confirmAppointmentArea: true });
              }}
            >
              Beratungstermin dokumentieren
            </HeaderbarButton>
            <HeaderbarButton
              disabled={this.state.loadingSendEmail}
              icon={this.state.loadingSendEmail ? faHourglass : faEnvelope}
              onClick={this.requestAppointment}
            >
              Terminanfrage
            </HeaderbarButton>
            <HeaderbarButton trash icon={faTrashAlt} onClick={this.remove}>
              löschen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to={this.parentRoute}>
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
              <Form
                ref={(node) => {
                  return (this.form = node);
                }}
              >
                <ContentBox title="Beratungstermin">
                  <Input
                    type="date"
                    validator={[
                      {
                        required: true,
                        error: 'Bitte Datum eingeben',
                      },
                      {
                        date: true,
                        error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                      },
                    ]}
                    onChange={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'DateOfAppointment',
                      )(moment(value)?.format('YYYY-MM-DD'));
                    }}
                    defaultValue={moment(
                      this.dataContainer.get('Entry', 'DateOfAppointment'),
                    )?.format('YYYY-MM-DD')}
                    label="Datum der Beratung"
                    name="DateOfAppointment"
                  />
                  <Input
                    type="time"
                    validator={[]}
                    onChange={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'TimeOfAppointment',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'TimeOfAppointment',
                    )}
                    label="Uhrzeit"
                    name="TimeOfAppointment"
                  />

                  <Dropdown
                    label="Beratungsort:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'PlaceOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'PlaceOfConsultation',
                    )}
                    options={this.state.PlaceOfConsultation}
                    validator={[]}
                    name="PlaceOfConsultation"
                  />
                  <Dropdown
                    label="CARE-Psych.:"
                    getSelected={(value) => {
                      this.dataContainer.set('Entry', 'CarePsych')(value);
                    }}
                    defaultValue={this.dataContainer.get('Entry', 'CarePsych')}
                    options={this.state.CarePsych}
                    validator={[]}
                    name="CarePsych"
                  />
                  <Dropdown
                    label="Form der Beratung:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'FormOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'FormOfConsultation',
                    )}
                    options={this.state.FormOfConsultations}
                    validator={[]}
                    name="FormOfConsultation"
                  />
                  <Dropdown
                    label="Art der Beratung:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'TypeOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'TypeOfConsultation',
                    )}
                    options={this.state.TypeOfConsultations}
                    validator={[]}
                    name="TypeOfConsultation"
                  />
                </ContentBox>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppointmentDetail);
