import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Button extends React.Component {
  handleClick = () => {
    if (typeof this.props.onClick === 'function' && !this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    const classNames = `
      Button
      Button--${this.props.type}
      ${this.props.big ? 'Button--big' : ''}
      ${this.props.small ? 'Button--small' : ''}
      ${this.props.disabled ? 'Button--disabled' : ''}
      ${this.props.outline ? 'Button--outline' : ''}
      ${this.props.loading ? 'Button--loading' : ''}
      ${this.props.className}
    `;

    // Render internal Link
    if (this.props.to) {
      return (
        <Link className={classNames} to={this.props.to}>
          {this.props.icon && (
            <FontAwesomeIcon icon={this.props.icon} className="Button-icon" />
          )}
          {this.props.children}
        </Link>
      );
    }

    // Render functional Button (default)
    return (
      <button
        type="button"
        className={classNames}
        onClick={
          !this.props.loading
            ? this.handleClick
            : () => {
                return null;
              }
        }
      >
        {this.props.loading ? (
          <>
            <svg key="spinner" className="Button-spinner" viewBox="0 0 50 50">
              <circle
                className="Button-spinnerPath"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="3"
              />
            </svg>
            ,
            <span key="children" className="Button-text">
              Wird geladen...
            </span>
            ,
          </>
        ) : (
          <>
            {this.props.icon && (
              <FontAwesomeIcon icon={this.props.icon} className="Button-icon" />
            )}{' '}
            {this.props.children}
          </>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.any),
  }),
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  big: PropTypes.bool,
  small: PropTypes.bool,
  outline: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'primary', 'danger', 'warning']),
  loading: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  className: '',
  type: 'default',
  disabled: false,
  big: false,
  small: false,
  outline: false,
  loading: false,
};

export default Button;
