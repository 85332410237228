import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import sha512 from 'sha512';
import reqwest from 'reqwest';

import validate from '../logic/validator';

import Alert from './Alert';

import settings from '../../settings';

import logo from '../../images/logo.png';
import appInfo from '../../../package';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      image: '',
      loginButtonLoading: false,
      changelogOpen: false,
    };

    this.validators = {
      username: [
        {
          required: true,
          error: 'Bitte geben Sie Ihren Benutzernamen ein.',
        },
      ],
      password: [
        {
          required: true,
          error: 'Bitte geben Sie Ihr Passwort ein.',
        },
      ],
    };
  }

  _login = async (event) => {
    event.preventDefault();
    const username = this.username.value;
    const password = this.password.value;

    let validator = {
      isValid: true,
      error: '',
    };

    validator = validator.isValid
      ? await validate(username, this.validators.username)
      : validator;
    validator = validator.isValid
      ? await validate(password, this.validators.password)
      : validator;

    if (!validator.isValid) {
      this.setState({
        error: validator.error,
      });
      return;
    }

    this.setState({
      loginButtonLoading: true,
    });

    reqwest({
      url: settings.general.login,
      method: 'get',
      data: {
        username,
        password: sha512(password).toString('hex'),
        AppGuid: '4711',
      },
    })
      .then((result) => {
        if (result.firstname === null) {
          this.setState({
            loginButtonLoading: false,
            error:
              'Der Login ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
          });
          return;
        }
        return result;
      })
      .then((result) => {
        if (result.isNew === 'true') {
          this.props.history.push('ChangePassword');
          localStorage.setItem('tokenChangePassword', result.token);
          return;
        }
        if (result) {
          localStorage.setItem('userdata', JSON.stringify(result));
          localStorage.setItem('token', result.token);
          const { from } = this.props.location.state || {
            from: { pathname: '/clientmanagement' },
          };
          this.props.history.push(from);
        }
      })
      .fail(() => {
        this.setState({
          error:
            'Es ist ein Fehler mit dem Server aufgetreten. Bitte versuchen Sie es später erneut!',
          loginButtonLoading: false,
        });
      });
  };

  _toggleChangeLog = () => {
    this.setState({ changelogOpen: !this.state.changelogOpen });
  };

  render() {
    return (
      <div className="Login">
        {this.state.error && (
          <Alert
            type="error"
            title="Fehler"
            onDismiss={() => {
              return this.setState({ error: '' });
            }}
          >
            {this.state.error}
          </Alert>
        )}
        <div className="Login-Form-box">
          <img
            src={logo}
            alt="alltours Reisecenter"
            className="Login-Form-logo"
          />
          <div className="Login-Form-title">Intranet-Login</div>
          <form>
            <div className="Login-Form radius">
              <input
                name="username"
                autoComplete="username"
                placeholder="Benutzername"
                className="field field-border-bottom radius-top"
                ref={(node) => {
                  this.username = node;
                }}
              />
              <input
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Passwort"
                className="field field-border-bottom"
                ref={(node) => {
                  this.password = node;
                }}
              />
              <button
                className="Login-submit"
                onClick={this._login}
                disabled={!!this.state.loginButtonLoading}
                name="login"
                type="submit"
              >
                {this.state.loginButtonLoading ? (
                  <div className="LoginLoader">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  <p>Einloggen</p>
                )}
              </button>
            </div>
          </form>
          <div className="Login-Form-actions">
            <Link type="button" className="Link Link--underlined" to="/forgot">
              Passwort vergessen
            </Link>
          </div>
        </div>

        <div className="Login-footer">
          <div className="Login-footer-left">
            <Link
              to="/Datenschutz"
              className="Link Link--underlined Footer-link"
            >
              Datenschutz
            </Link>
            <Link to="/Impressum" className="Link Link--underlined Footer-link">
              Impressum
            </Link>
          </div>
          <div className="Login-footer-right">
            <button onClick={this._toggleChangeLog} className="Footer-version">
              Version {appInfo.version}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
