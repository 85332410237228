import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import ResubmissionOverview from './Overview';
import ResubmissionNewEntry from './New';
import ResubmissionDetail from './Detail';

const Resubmission = (props) => {
  const paths = {
    new: `${props.match.path}/new/:clientGuid`,
    details: `${props.match.path}/detail/:guid/:clientGuid`,
    overview: `${props.match.path}`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={ResubmissionNewEntry} />
      <Route path={paths.details} component={ResubmissionDetail} />
      <Route path={paths.overview} component={ResubmissionOverview} />
    </Switch>
  );
};

export default withRouter(Resubmission);
