import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Details';
import Overview from './Overview';

const Constants = (props) => {
  const paths = {
    details: `${props.match.path}/:key/:value`,
  };

  return (
    <Switch>
      <Route
        exact
        path={paths.details}
        render={(routerProps) => {
          return <Details {...routerProps} />;
        }}
      />
      <Route
        render={(routerProps) => {
          return <Overview {...routerProps} />;
        }}
      />
    </Switch>
  );
};

export default withRouter(Constants);
