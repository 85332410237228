import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import validate from '../../logic/validator';

export default class Switch extends React.Component {
  static displayName = 'Switch';
  constructor(props) {
    super(props);
    let defaultChecked = false;
    if (props.defaultChecked === 'true' || props.defaultChecked === true) {
      defaultChecked = true;
    }

    this.value = defaultChecked;
    this.state = {
      labelState: defaultChecked ? props.labelActive : props.labelInactive,
    };
  }

  componentDidUpdate(_, prevProp) {
    if (
      prevProp.checked !== this.props.checked &&
      this.input.checked !== this.props.checked
    ) {
      this.input.checked = this.props.checked;
    }
  }

  /**
   * This function exists for "Form"
   * Do not delete unless you delete "Form"!
   */
  validate = async () => {
    const validator = await validate(this.value, this.props.validator);
    this.setState({
      error: validator.error,
    });
    return validator.isValid;
  };

  handleChange = () => {
    if (this.props.isDisabled) {
      return;
    }

    const { checked } = this.input;
    this.value = checked;

    this.validate();

    if (this.props.onChange) {
      this.props.onChange(checked);
    }

    this.setState({
      labelState: checked ? this.props.labelActive : this.props.labelInactive,
    });
  };

  render() {
    return (
      <div
        className={`Switch ${this.props.inline ? 'Switch--inline' : ''} ${
          this.props.isDisabled ? 'Switch--disabled' : ''
        } ${this.props.fullWidth ? 'Input--fullWidth' : ''}`}
      >
        <label htmlFor={this.props.name} className="Switch-title">
          {this.props.label}
          {this.props.info && (
            <div className="Dropdown-info">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="tooltiptext">{this.props.info}</span>
            </div>
          )}
          <div className="Switch-description">{this.props.description}</div>
        </label>
        <label
          className="Switch-label"
          htmlFor={this.props.name}
          style={{ marginTop: this.props.description && '10px' }}
        >
          <input
            ref={(node) => {
              this.input = node;
            }}
            type="checkbox"
            id={this.props.name}
            name={this.props.name}
            disabled={this.props.isDisabled}
            defaultChecked={this.props.defaultChecked}
            onChange={this.handleChange}
            className={
              this.props.style === 'switch' ? 'Switch-input' : 'Checkbox-input'
            }
          />
          {this.props.style === 'switch' && <span className="Switch-switch" />}
          <span className="Switch-label-state">{this.state.labelState}</span>
        </label>
      </div>
    );
  }
}

Switch.propTypes = {
  defaultChecked: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  labelActive: PropTypes.string,
  labelInactive: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string.isRequired,
    }),
  ),
  style: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Switch.defaultProps = {
  info: '',
  labelActive: '',
  labelInactive: '',
  isDisabled: false,
  defaultChecked: false,
  description: '',
  validator: [],
  onChange: () => {
    return null;
  },
  style: 'switch',
  fullWidth: false,
};
