import {
  faArrowLeft,
  faSave,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import swal from '@sweetalert/with-react';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import settings from '../../../../../settings';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../logic/api';
import DataContainer from '../../../../logic/dataContainer';
import SpecialistForm from '../SpecialistForm';
import HeaderbarButton from '../../../../components/HeaderbarButton';

class SpecialistDetail extends Component {
  constructor(props) {
    super(props);
    this.guid = this.props.match.params.guid;
    console.log(this.props.match.params.guid);
    this.parentRoute = '/supplynetwork/Specialists';

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: true,
      saving: false,
      positions: [],
      Gender: [],
      dateOfResub: '',
    };
  }

  componentDidMount() {
    api('GET', settings.supplynetwork.specialists.get, {
      Guid: this.guid,
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        swal({
          title: 'Fehler beim laden des Datensatz.',
          text: 'Es ist ein Fehler beim laden aufgetreten. Probieren sie es später nochmal.',
          icon: 'error',
        });
      });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie wirklich diese Adresse löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        api('POST', settings.supplynetwork.specialists.remove, {
          Guid: this.guid,
        })
          .then(() => {
            swal({
              title: 'Erfolg!',
              text: 'Die Adresse wurde gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            swal({
              title: 'Fehler beim löschen des Datensatz.',
              text: 'Es ist ein Fehler beim löschen aufgetreten. Probieren sie es später nochmal.',
              icon: 'error',
            });
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  save = async () => {
    this.setState({ saving: true });
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      this.setState({ saving: false });
      return;
    }
    const data = this.form.dataContainer.getStringified();
    return api('POST', settings.supplynetwork.specialists.save, {
      specialistAddress: data,
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Die Adresse wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.setState({ saving: false });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
        this.setState({ saving: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/Specialists">Fachärzte</Link> -{' '}
            <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton trash icon={faTrashAlt} onClick={this.delete}>
              löschen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink
                className="FormArea-Body-Tab"
                to={`/supplynetwork/Specialists`}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  loading={this.state.saving}
                >
                  Speichern
                </Button>
              </div>
              <SpecialistForm
                ref={(node) => {
                  return (this.form = node);
                }}
                dataContainer={this.dataContainer}
              />
              <div className="Buttonbar-bottom">
                <Button
                  className="Button-bigger"
                  loading={this.state.saving}
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  outline
                >
                  Speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SpecialistDetail;
