import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import ConsultationOverview from './Overview';
import ConsultationNewEntry from './New';
import ConsultationDetail from './Detail';
import ClientDetail from '../Detail';

/**
 * * Important: Don't remove there Routes now, may we will use it in future.
 */
const Consultation = (props) => {
  const paths = {
    new: `${props.match.path}/new/:clientGuid`,
    details: `${props.match.path}/detail/:guid/:clientGuid`,
    overview: `${props.match.path}`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={ClientDetail} />
      <Route path={paths.details} component={ClientDetail} />
      <Route path={paths.overview} component={ClientDetail} />
    </Switch>
  );
};

export default withRouter(Consultation);
