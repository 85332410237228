import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import New from './New';
import Details from './Details';
import Overview from './Overview';
import OverviewResubmission from './OverviewResubmission';
import { AmbPsychFilterProvider } from '../../../hooks/AmbPsychFilterContext';

const Supplynetwork = (props) => {
  const paths = {
    new: `${props.match.path}/new`,
    details: `${props.match.path}/detail/:guid`,
    search: `${props.match.path}/search/:search?`,
    overviewResubmissions: `${props.match.path}/resubmissions`,
  };

  return (
    <AmbPsychFilterProvider>
      <Switch>
        <Route path={paths.new} component={New} />
        <Route path={paths.search} component={Overview} />
        <Route path={paths.details} component={Details} />
        <Route
          path={paths.overviewResubmissions}
          component={OverviewResubmission}
        />
        <Route component={Overview} />
      </Switch>
    </AmbPsychFilterProvider>
  );
};

export default withRouter(Supplynetwork);
