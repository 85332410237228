import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';

class RolesOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.permissions.GetAllRoles,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then((getAllRolesResponse) => {
      const roles = JSON.parse(getAllRolesResponse);

      this.setState({
        roles,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Page-Content">
          <ReactTable
            getTrProps={(_, { original }) => {
              return {
                onClick: () => {
                  return this.props.history.push(
                    `${this.props.match.url}/${original.Params.Guid}`,
                  );
                },
              };
            }}
            data={this.state.roles}
            pageSize={this.state.roles.length}
            noDataText="Es liegen keine Rollen vor."
            columns={[
              {
                Header: 'Name',
                accessor: 'Params.Name',
              },
              {
                Header: 'Bemerkung',
                accessor: 'Params.Bemerkung',
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default RolesOverview;
