import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Resubmission from './Resubmission/index';
import Mediation from './Mediation/index';
import Detail from './Detail';

const SupplynetworkAmbulatoryPsychotherapyDetails = () => {
  const paths = {
    resubmission: `/supplynetwork/AmbulatoryPsychotherapy/detail/resubmission/:guid?`,
    mediation: `/supplynetwork/AmbulatoryPsychotherapy/detail/mediation/:guid?`,
    details: `/supplynetwork/AmbulatoryPsychotherapy/detail/:guid?`,
  };

  return (
    <Switch>
      <Route path={paths.mediation} component={Mediation} />
      <Route path={paths.resubmission} component={Resubmission} />
      <Route path={paths.details} component={Detail} />
    </Switch>
  );
};

export default withRouter(SupplynetworkAmbulatoryPsychotherapyDetails);
