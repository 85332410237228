import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import DataContainer from '../../../../logic/dataContainer';

class EMailClientNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('PermissionEntry', {
      Name: '',
      Bemerkung: '',
    });
  }

  save = () => {
    const Role = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.permissions.AddRole,
      data: {
        Token: localStorage.getItem('token'),
        Role,
      },
    }).then((saveResult) => {
      saveResult = JSON.parse(saveResult);

      if (saveResult[0].Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolg!',
          text: 'Die Rolle wurde erfolgreich angelegt.',
          icon: 'success',
        });
        this.props.history.replace(
          this.props.match.url.replace(
            /\/new$/,
            `/${saveResult[0].Params.Guid}`,
          ),
        );
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </Button>
            <Button icon={faSave} onClick={this.save}>
              Speichern
            </Button>
          </div>
          <ContentBox title="Rolle">
            <Input
              type="text"
              label="Name:"
              validator={[]}
              onChange={(value) => {
                this.dataContainer.set('PermissionEntry', 'Name')(value);
              }}
              defaultValue={this.dataContainer.get('PermissionEntry', 'Name')}
              name="Name"
            />
            <Input
              type="text"
              label="Bemerkung:"
              validator={[]}
              onChange={(value) => {
                this.dataContainer.set('PermissionEntry', 'Bemerkung')(value);
              }}
              defaultValue={this.dataContainer.get(
                'PermissionEntry',
                'Bemerkung',
              )}
              name="Bemerkung"
            />
          </ContentBox>
        </div>
      </>
    );
  }
}

export default EMailClientNew;
