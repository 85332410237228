import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import settings from '../../settings';

class PrivateRouter extends React.Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('token');

    if (token) {
      reqwest({
        method: 'get',
        url: settings.general.checkToken,
        data: {
          Token: token,
        },
      })
        .then((result) => {
          if (result) {
            this.setState({
              result: true,
            });
          } else {
            this.setState({
              result: false,
            });
          }
        })
        .fail(() => {
          localStorage.clear();
          this.setState({
            result: false,
          });
        });
    } else {
      this.state = {
        result: false,
      };
    }
  }

  render() {
    const { component: Component, ...filteredProps } = this.props;
    return (
      this.state && (
        <Route
          {...filteredProps}
          render={(renderProps) => {
            if (this.state.result === true) {
              return <Component {...renderProps} />;
            }
            return (
              <Redirect
                to={{
                  pathname: '/Login',
                  state: { from: renderProps.location },
                }}
              />
            );
          }}
        />
      )
    );
  }
}

PrivateRouter.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
};

export default withRouter(PrivateRouter);
