import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HeaderbarButton extends React.Component {
  handleClick = () => {
    if (
      typeof this.props.onClick === 'function' &&
      !this.props.disabled &&
      !this.props.loading
    ) {
      this.props.onClick();
    }
  };

  render() {
    const classNames = `Button HeaderbarButton ${this.props.className} ${
      this.props.trash ? 'trash' : ''
    }`;

    // Render internal Link
    if (this.props.to) {
      return (
        <Link className={classNames} to={this.props.to}>
          {this.props.loading ? (
            <>
              <svg key="spinner" className="Button-spinner" viewBox="0 0 50 50">
                <circle
                  className="Button-spinnerPath"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="3"
                />
              </svg>
              <span key="children" className="Button-text">
                {this.props.loadingText}
              </span>
            </>
          ) : (
            <>
              {this.props.icon && (
                <FontAwesomeIcon
                  icon={this.props.icon}
                  className="Button-icon"
                />
              )}
              {!this.props.trash && (
                <span className="button-text">{this.props.children}</span>
              )}
              <span className="tooltiptext"> {this.props.children}</span>
            </>
          )}
        </Link>
      );
    }

    // Render functional Button (default)
    return (
      <button type="button" className={classNames} onClick={this.handleClick}>
        {this.props.loading ? (
          <>
            <svg key="spinner" className="Button-spinner" viewBox="0 0 50 50">
              <circle
                className="Button-spinnerPath"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="3"
              />
            </svg>
            <span key="children" className="Button-text">
              {this.props.loadingText}
            </span>
          </>
        ) : (
          <>
            {this.props.icon && (
              <FontAwesomeIcon icon={this.props.icon} className="Button-icon" />
            )}
            {!this.props.trash && (
              <span className="button-text">{this.props.children}</span>
            )}
            <span className="tooltiptext"> {this.props.children}</span>
          </>
        )}
      </button>
    );
  }
}

HeaderbarButton.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.any),
  }),
  trash: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

HeaderbarButton.defaultProps = {
  trash: false,
  children: '',
  className: '',
  disabled: false,
  loading: false,
  loadingText: 'Wird geladen...',
};

export default HeaderbarButton;
