import React, { Component } from 'react';
import { Switch, NavLink, Route } from 'react-router-dom';
import Constants from './Constants/index';
import Roles from './Roles/index';
import EMailTemplates from './EMailTemplates/index';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

class Systemmangement extends Component {
  routes = [
    {
      path: '/Constants',
      pageName: 'Konstanten',
    },
    {
      path: '/Roles',
      pageName: 'Rollen',
    },
    {
      path: '/EMailTemplates',
      pageName: 'Formulare',
    },
  ];

  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Systemverwaltung -{' '}
            <b>
              {
                this.routes.find((navLink) => {
                  return this.props.location.pathname.includes(navLink.path);
                })?.pageName
              }
            </b>
          </div>
          <div className="Headerbar-buttons"></div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              {this.routes.map((navLink) => {
                return (
                  <NavLink
                    key={navLink.pageName}
                    to={this.props.match.path + navLink.path}
                    className="FormArea-Body-Tab"
                    activeClassName="is-Active"
                  >
                    {navLink.pageName}
                  </NavLink>
                );
              })}
            </div>
          </div>
          <div className="Split-Pane-Main">
            <Switch>
              <Route
                path={`${this.props.match.path}/Constants`}
                render={() => {
                  return <Constants />;
                }}
              />
              <Route
                path={`${this.props.match.path}/Roles`}
                render={() => {
                  return <Roles />;
                }}
              />
              <Route
                path={`${this.props.match.path}/EMailTemplates`}
                render={() => {
                  return <EMailTemplates />;
                }}
              />
              <Route path={this.props.match.path}>
                <Redirect to={`${this.props.match.path}/Constants`} />
              </Route>
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default Systemmangement;
