import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import { faArchive, faSave } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';

import DataContainer from '../../../../../logic/dataContainer';
import checkAvailable from '../../../../../logic/checkAvailable';

import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Switch from '../../../../../components/Inputs/Switch';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import Input from '../../../../../components/Inputs/Input';
import getConstants from '../../../../../logic/constants';

class BasedataDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(
      new RegExp(`/${props.match.params.userId}$`),
      '',
    );

    this.state = {
      loading: true,
      positions: [],
      Salutation: [],
      Department: [],
      Designation: [],
      UserRoles: [],
      POBox: [],
    };

    const dataPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.UserGuid,
      },
    }).then((res) => {
      this.dataContainer = new DataContainer(res);
    });

    Promise.all([dataPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      Salutation: await getConstants('Salutation'),
      Department: await getConstants('Department'),
      Designation: await getConstants('Designation'),
      UserRoles: await getConstants('Roles'),
      POBox: await getConstants('POBox'),
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.usermanagement.general.save,
      data: {
        Token: localStorage.getItem('token'),
        User: data,
      },
    }).then(() => {
      swal({
        title: 'Gespeichert!',
        text: 'Der Nutzer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      history.back();
    });
  };

  archive() {
    const guid = this.dataContainer.get('Workdata', 'Guid');
    reqwest({
      method: 'POST',
      url: settings.usermanagement.general.delete,
      data: {
        Guid: guid,
        Token: localStorage.getItem('token'),
      },
    }).then(() => {
      swal({
        title: 'Archiviert!',
        text: 'Der Nutzer wurde erfolgreich archiviert.',
        icon: 'success',
      });
      history.back();
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/usermanagement">Benutzerverwaltung</Link> -{' '}
            <b>
              Benutzer {this.dataContainer.get('Workdata', 'Firstname')}{' '}
              {this.dataContainer.get('Workdata', 'Lastname')} bearbeiten
            </b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faArchive}
              type="primary"
              onClick={() => {
                this.archive();
              }}
            >
              Archivieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button type="primary" icon={faSave} onClick={this.save}>
              Speichern
            </Button>
          </div>
          <Form
            ref={(node) => {
              return (this.form = node);
            }}
          >
            <ContentBox title="Stammdaten">
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Firstname')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Firstname')}
                label="Vorname:"
                name="firstname"
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Lastname')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Lastname')}
                label="Nachname:"
                name="lastname"
              />
              <Dropdown
                label="Dienststelle:"
                isSearchable
                getSelected={(value) => {
                  this.dataContainer.set('Workdata', 'Department')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Department')}
                options={this.state.Department}
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                name="Department"
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                  {
                    type: 'email',
                    error: 'Eingabe im Feld stimmt nicht mit dem Typ überein.',
                  },
                  {
                    custom: async (value) => {
                      if (
                        this.dataContainer.getInitial('Workdata', 'EMail') !==
                        value
                      ) {
                        return await checkAvailable('email', value);
                      }
                      return true;
                    },
                    error: 'Diese E-Mail Adresse wird bereits verwendet',
                  },
                  {
                    custom: (value) => {
                      if (
                        value &&
                        !value.endsWith('@mi.niedersachsen.de') &&
                        !value.endsWith('@mk.niedersachsen.de') &&
                        !value.endsWith('@rlsb-bs.niedersachsen.de') &&
                        !value.endsWith('@rlsb-h.niedersachsen.de') &&
                        !value.endsWith('@rlsb-lg.niedersachsen.de') &&
                        !value.endsWith('@rlsb-os.niedersachsen.de') &&
                        !value.endsWith('@marktplatz-agentur.de')
                      ) {
                        return false;
                      }
                      return true;
                    },
                    error: 'Die E-Mail Endung ist nicht erlaubt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'EMail')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'EMail')}
                label="E-Mail Adresse:"
                name="EMail"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Title')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Title')}
                label="Titel:"
                name="Title"
              />

              <Dropdown
                label="Anrede:"
                isSearchable
                getSelected={(value) => {
                  this.dataContainer.set('Workdata', 'Salutation')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Salutation')}
                options={this.state.Salutation}
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                name="Salutation"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Building')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Building')}
                label="Gebäude:"
                name="Building"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Room')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Room')}
                label="Raumnummer:"
                name="Room"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Street')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Street')}
                label="Straße:"
                name="Street"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'PLZ')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'PLZ')}
                label="PLZ:"
                name="PLZ"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Place')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Place')}
                label="Ort:"
                name="Place"
              />
              <Dropdown
                label="Postfach:"
                isSearchable
                getSelected={(value) => {
                  this.dataContainer.set('Workdata', 'POBox')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'POBox')}
                options={this.state.POBox}
                validator={[]}
                name="POBox"
              />
              <Dropdown
                label="Bezeichnung:"
                isSearchable
                getSelected={(value) => {
                  this.dataContainer.set('Workdata', 'Designation')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Designation')}
                options={this.state.Designation}
                validator={[]}
                name="Designation"
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                  {
                    type: 'phone',
                    error: 'Eingabe im Feld stimmt nicht mit dem Typ überein.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Phone')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Phone')}
                label="Telefon"
                name="Phone"
              />
              <Switch
                label="Benutzer:"
                labelActive="Gesperrt"
                labelInactive="Aktiv"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'Banned')(value);
                  console.log('value', value);
                }}
                name="Gesperrt"
                defaultChecked={
                  this.dataContainer.get('Workdata', 'Banned') === ''
                    ? false
                    : this.dataContainer.get('Workdata', 'Banned')
                }
                validator={[]}
              />
              <Dropdown
                label="Benutzerrechte:"
                isSearchable
                getSelected={(value) => {
                  this.dataContainer.set('Workdata', 'Role')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Role')}
                options={this.state.UserRoles}
                validator={[
                  {
                    required: true,
                    error: 'Bitte Rolle wählen.',
                  },
                ]}
                name="Role"
              />
              <Switch
                label="Kennzeichnung als Psychologinnen oder Psychologe:"
                onChange={(value) => {
                  this.dataContainer.set('Workdata', 'MarkingAs')(value);
                }}
                name="Kennzeichnung"
                defaultChecked={
                  !!this.dataContainer.get('Workdata', 'MarkingAs')
                }
                validator={[]}
              />
            </ContentBox>
          </Form>
        </div>
      </>
    );
  }
}

export default BasedataDetail;
