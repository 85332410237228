import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import {
  faSave,
  faArrowLeft,
  faUserCheck,
  faLockOpen,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import settings from '../../../../../settings';
import DataContainer from '../../../../logic/dataContainer';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import HeaderbarButton from '../../../../components/HeaderbarButton';
import AmbPsychForm from '../AmbPsychForm';
import moment from 'moment';
import UserContext from '../../../../hooks/UserContext';
import api from '../../../../logic/api';

class SupplynetworkAmbulatoryPsychotherpyDetail extends Component {
  constructor(props) {
    super(props);
    this.guid = this.props.match.params.guid;
    this.parentRoute = '/supplynetwork/AmbulatoryPsychotherapy';

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: true,
      saving: false,
      positions: [],
      Gender: [],
      dateOfResub: '',
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    api('get', settings.supplynetwork.ambulatoryPsychotherapy.get, {
      Guid: this.guid,
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.migrateOldData();
      })
      .catch(() => {
        swal({
          title: 'Fehler beim laden des Datensatz.',
          text: 'Es ist ein Fehler beim laden aufgetreten. Probieren sie es später nochmal.',
          icon: 'error',
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
    // reqwest({
    //   method: 'get',
    //   url: settings.supplynetwork.ambulatoryPsychotherapy.get,
    //   data: {
    //     Token: localStorage.getItem('token'),
    //     Guid: this.guid,
    //   },
    // })
    //   .then((res) => {
    //     this.dataContainer = new DataContainer(res);
    //     this.migrateOldData();
    //   })
    //   .catch(() => {
    //     swal({
    //       title: 'Fehler beim laden des Datensatz.',
    //       text: 'Es ist ein Fehler beim laden aufgetreten. Probieren sie es später nochmal.',
    //       icon: 'error',
    //     });
    //   })
    //   .always(() => {
    //     this.setState({
    //       loading: false,
    //     });
    //   });
  }

  locationSearch = async (value) => {
    return this.geocodingClient
      .forwardGeocode({
        query: value,
      })
      .send()
      .then((response) => {
        const match = response.body;
        if (!match.features.length) return [null, null];
        return match.features[0].center;
      });
  };

  updateCoordinates = async (office /*'FirstOffice' or 'SecondOffice' */) => {
    const street = this.form.dataContainer.get(office, 'Street');
    const zip = this.form.dataContainer.get(office, 'ZIP');
    const city = this.form.dataContainer.get(office, 'City');
    if (zip || city) {
      const query = (street ? street + ', ' : '') + zip + ' ' + city;
      const location = await this.locationSearch(query);
      this.form.dataContainer.set(office, 'ZipLon')(location[0]);
      this.form.dataContainer.set(office, 'ZipLat')(location[1]);
    }
  };

  migrateOldData() {
    /** TherapyProcedures from dropdown to multiselect => string to array (8.Dec.22) */
    const therapyProcedures = this.dataContainer.get(
      'AmbulantPsychotherapy',
      'TherapyProcedures',
    );
    if (typeof therapyProcedures === 'string') {
      this.dataContainer.set(
        'AmbulantPsychotherapy',
        'TherapyProcedures',
      )([therapyProcedures]);
    }
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie wirklich diese Adresse löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        api('POST', settings.supplynetwork.ambulatoryPsychotherapy.remove, {
          Guid: this.guid,
        })
          .then((res) => {
            res = JSON.parse(res);

            if (res.Params.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolg!',
                text: 'Die Adresse wurde gelöscht.',
                icon: 'success',
              });
              this.props.history.replace(this.parentRoute);
            }
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
        // reqwest({
        //   method: 'POST',
        //   url: settings.supplynetwork.ambulatoryPsychotherapy.remove,
        //   data: {
        //     Guid: this.guid,
        //   },
        // })
        //   .then((res) => {
        //     res = JSON.parse(res);

        //     if (res.Params.DeleteResult === 'Deleted') {
        //       swal({
        //         title: 'Erfolg!',
        //         text: 'Die Adresse wurde gelöscht.',
        //         icon: 'success',
        //       });
        //       this.props.history.replace(this.parentRoute);
        //     }
        //   })
        //   .catch(() => {
        //     throw new Error('Something went very very wrong...');
        //   });
      }
    });
  };

  save = async () => {
    this.setState({ saving: true });
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      this.setState({ saving: false });
      return;
    }
    this.dataContainer.set('TransferDb', 'Date')(moment().format('DD-MM-YYYY'));
    await this.updateCoordinates('FirstOffice');
    await this.updateCoordinates('SecondOffice');
    const data = this.dataContainer.getStringified();
    return api('POST', settings.supplynetwork.ambulatoryPsychotherapy.save, {
      AmbulatoryPsychotherapy: data,
    })
      .then((res) => {
        swal({
          title: 'Gespeichert!',
          text: 'Die Adresse wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        if (res && this.state.dateOfResub && this.props.match.params.guid) {
          this.addResubmission(
            this.props.match.params.guid,
            this.state.dateOfResub,
          );
        }
        this.setState({ saving: false });
        this.props.history.go(this.parentRoute);
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
        this.setState({ saving: false });
      });

    // return reqwest({
    //   method: 'POST',
    //   url: settings.supplynetwork.ambulatoryPsychotherapy.save,
    //   data: {
    //     Token: localStorage.getItem('token'),
    //     AmbulatoryPsychotherapy: data,
    //   },
    // })
    //   .then((res) => {
    //     swal({
    //       title: 'Gespeichert!',
    //       text: 'Die Adresse wurde erfolgreich gespeichert.',
    //       icon: 'success',
    //     });
    //     if (res && this.state.dateOfResub && this.props.match.params.guid) {
    //       this.addResubmission(
    //         this.props.match.params.guid,
    //         this.state.dateOfResub,
    //       );
    //     }
    //     this.setState({ saving: false });
    //     this.props.history.go(this.parentRoute);
    //   })
    //   .catch(() => {
    //     swal({
    //       title: 'Speichern fehlgeschlagen!',
    //       text: 'Es ist ein Fehler beim speichern aufgetreten',
    //       icon: '',
    //     });
    //     this.setState({ saving: false });
    //   });
  };

  async addResubmission(ambPsyGuid, dateOfResub) {
    const resubmissionDataContainer = JSON.stringify({
      ParamName: 'Resubmission',
      Params: {
        Typ: 'Resubmission',
        AmbPsyGuid: ambPsyGuid,
        DateOfResub: dateOfResub,
        Reason: 'Angaben zur tägliche Verfügbarkeit abgelaufen',
      },
    });
    await api('POST', settings.clientmanagement.dataContainer.save, {
      container: resubmissionDataContainer,
      action: 'Neue Wiedervorlage angelegt',
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        return Promise.resolve();
      })
      .catch(() => {
        swal({
          title: 'Speichern der Wiedervorlage fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern der Wiedervorlage aufgetreten',
          icon: '',
        });
        return Promise.reject();
      });
    // await reqwest({
    //   method: 'POST',
    //   url: settings.clientmanagement.dataContainer.save,
    //   data: {
    //     container: resubmissionDataContainer,
    //     action: 'Neue Wiedervorlage angelegt',
    //   },
    // })
    //   .then((_) => {
    //     swal({
    //       title: 'Gespeichert!',
    //       text: 'Erfolgreich gespeichert.',
    //       icon: 'success',
    //     });
    //     return Promise.resolve();
    //   })
    //   .catch(() => {
    //     swal({
    //       title: 'Speichern der Wiedervorlage fehlgeschlagen!',
    //       text: 'Es ist ein Fehler beim speichern der Wiedervorlage aufgetreten',
    //       icon: '',
    //     });
    //     return Promise.reject();
    //   });
  }

  unlock = () => {
    const tempDatacontainer = new DataContainer();
    tempDatacontainer.add('UnlockedContainer', {
      Guid: this.guid,
      Unlocked: true,
    });
    api('POST', settings.supplynetwork.ambulatoryPsychotherapy.save, {
      AmbulatoryPsychotherapy: tempDatacontainer.getStringified(),
    }).then(() => {
      swal({
        title: 'Adresse freigeschaltet!',
        text: 'Die Adresse wurde erfolgreich freigeschaltet.',
        icon: 'success',
      });
    });

    // reqwest({
    //   method: 'POST',
    //   url: settings.supplynetwork.ambulatoryPsychotherapy.save,
    //   data: {
    //     Token: localStorage.getItem('token'),
    //     AmbulatoryPsychotherapy: tempDatacontainer.getStringified(),
    //   },
    // })
    //   .then(() => {
    //     swal({
    //       title: 'Adresse freigeschaltet!',
    //       text: 'Die Adresse wurde erfolgreich freigeschaltet.',
    //       icon: 'success',
    //     });
    //   })
    //   .catch(() => {
    //     swal({
    //       title: 'Fehlgeschlagen!',
    //       text: 'Die Adresse konnte nicht freigeschaltet werden.',
    //       icon: 'error',
    //     });
    //   });
  };

  mediate = () => {
    this.props.history.push(
      `/supplynetwork/AmbulatoryPsychotherapy/detail/mediation/new/${this.guid}`,
    );
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/AmbulatoryPsychotherapy">
              Ambulante Psychotherapie
            </Link>{' '}
            - <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faUserCheck} onClick={this.mediate}>
              Vermittlungen
            </HeaderbarButton>
            {(this.context.isAdmin || this.context.isManagementSpecial) &&
              !this.dataContainer.get('Workdata', 'Unlocked') && (
                <HeaderbarButton icon={faLockOpen} onClick={this.unlock}>
                  Adresse freischalten
                </HeaderbarButton>
              )}
            <Button
              className="trash"
              type="danger"
              icon={faTrashAlt}
              onClick={this.delete}
            />
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink
                className="FormArea-Body-Tab"
                to={`/supplynetwork/AmbulatoryPsychotherapy`}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={this.props.match.url}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/detail/resubmission/${this.guid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Wiedervorlage
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/detail/mediation/${this.guid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Vermittlungen
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  loading={this.state.saving}
                >
                  Speichern
                </Button>
              </div>
              <AmbPsychForm
                ref={(node) => {
                  return (this.form = node);
                }}
                dataContainer={this.dataContainer}
              />
              <div className="Buttonbar-bottom">
                <Button
                  className="Button-bigger"
                  loading={this.state.saving}
                  type="primary"
                  icon={faSave}
                  onClick={this.save}
                  outline
                >
                  Speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SupplynetworkAmbulatoryPsychotherpyDetail;
