import React from 'react';
import { Link } from 'react-router-dom';

const Impressum = () => {
  return (
    <div className="Impressum">
      <h1>Impressum</h1>
      Dies ist ein cooles Impressum.
      <Link to="/Login">Zurück zum Login</Link>
    </div>
  );
};

export default Impressum;
